import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { ITag, TagsState } from 'store/tags/types';

export const getTagsState = (state: RootState): TagsState => state.tags;

export const getAllTags = createSelector(
  [getTagsState],
  (state: TagsState): ITag[] => state?.data?.tags
);

export const getTopTags = createSelector(
  [getTagsState],
  (state: TagsState): ITag[] => state?.data?.tags?.filter(tag => tag.is_top_tag)
);

export const getDonationAmounts = createSelector([getTagsState], (state: TagsState): number[] =>
  state?.data?.tags?.length > 0 ? state?.data?.tags[0].donation_amounts : [10, 25, 50, 100]
);
