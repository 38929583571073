import { createActions } from 'reduxsauce';

export interface SubscriptionsState {
  data: SubscriptionsData;
  isLoading: boolean;
  error: string;
}

export type SubscriptionsData = {
  subscriptions: TSubscription[];
  summary: TSummary;
};

export type TSubscription = {
  amount: number;
  tag_name: string;
  de_gid: string;
  expires_after: string;
  starts_on: string;
  state: string;
  subscription_id: string;
  last_transaction_date: string;
  next_transaction_date?: string | null;
};

export type TSummary = {
  total_active_amount: number;
  total_active_count: number;
};

export interface SubscriptionsTypes {
  SUBSCRIPTIONS: 'SUBSCRIPTIONS';
  SUBSCRIPTIONS_START: 'SUBSCRIPTIONS_START';
  SUBSCRIPTIONS_SUCCESS: 'SUBSCRIPTIONS_SUCCESS';
  SUBSCRIPTIONS_ERROR: 'SUBSCRIPTIONS_ERROR';
  SUBSCRIPTIONS_CLEAN_UP: 'SUBSCRIPTIONS_CLEAN_UP';
}

const { Types, Creators } = createActions<SubscriptionsTypes>({
  subscriptions: ['data'],
  subscriptionsStart: null,
  subscriptionsSuccess: ['data'],
  subscriptionsError: ['error'],
  subscriptionsCleanUp: null,
});

export { Types };

export default Creators;
