import { ButtonBrand } from 'components/ButtonBrand';
import { TopIssueCard } from 'components/Cards/TopIssueCard';
import { Line } from 'components/Cards/TopIssueCard/styled';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Body, BodySmall, Eyebrow } from 'components/Typography';
import theme from 'config/theme';
import { formatNumberAsCurrency, getPathWithRef } from 'helpers/utils';
import { FC, useEffect, useState } from 'react';
import { getRecipients, getRecipientsAPI } from 'store/recipients/actions';
import { IRecipient } from 'store/recipients/types';
import { ITag } from 'store/tags/types';
import {
  DonateAgainCardContainer,
  DonateAgainCardLearnMore,
  Recipient,
  RecipientsContainer,
} from './styled';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { getCandidates, getCandidatesWithNewAmounts } from 'helpers/donate';
import { clearDonationSelections, getSessionID, setDonationSelections } from 'services/storage';
import { getTags } from 'store/tags/actions';
import { useAppDispatch } from 'helpers/hooks';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { getPayments } from 'store/payments/actions';

interface Props {
  donationAmount: number;
  handleNewDonationSelections: () => void;
  issue: ITag;
  userId: string;
}

export const DonateAgainCard: FC<Props> = ({
  donationAmount,
  handleNewDonationSelections,
  issue,
  userId,
}) => {
  const dispatch = useAppDispatch();
  const [recipients, setRecipients] = useState([] as IRecipient[]);
  const [candidates, setCandidates] = useState<CandidateType[]>([]);
  const [donationAmountAdjusted, setDonationAmountAdjusted] = useState(donationAmount);

  useEffect(() => {
    setDonationAmountAdjusted(donationAmount);
  }, [donationAmount]);

  useEffect(() => {
    if (!recipients.length) {
      return;
    }
    let amount = donationAmount;
    if (recipients.length > donationAmount) {
      amount = recipients.length;
      setDonationAmountAdjusted(amount);
    }
    setCandidates(getCandidatesWithNewAmounts(amount, getCandidates(recipients)));
  }, [recipients]);

  useEffect(() => {
    if (recipients.length) {
      return;
    }
    getRecipientsAPI({
      amount: 200,
      query_str: issue.query_str,
    }).then(response => {
      setRecipients(response.data.recipients);
    });
  }, [issue.query_str, recipients.length]);

  const handleDonate = () => {
    if (!candidates.length) {
      return;
    }
    dispatch(getTags({ urlPartner: issue.query_str }));
    dispatch(
      getRecipients({
        amount: 100,
        query_str: issue.query_str,
      })
    );
    if (userId) {
      dispatch(getPayments());
    }

    const donationSelections = {
      candidates,
      total: donationAmountAdjusted.toString(),
    };

    // remove sessionStorage item if it was previously set and not removed
    clearDonationSelections();
    // convert donationSelection object to string so it can be stored in sessionStorage
    const donationSelectionsString = JSON.stringify(donationSelections);
    // save donationSelections to sessionStorage after user hits donate button on partner/issue/main page
    // and BEFORE user enters login/signup flow
    setDonationSelections(donationSelectionsString);

    handleNewDonationSelections();

    logFirebaseEvent('checkout_express_donate_again_intent', {
      timestamp: new Date().toString(),
      user_id: userId,
      session_id: getSessionID(),
      total_amount: donationAmountAdjusted,
      page: issue.query_str,
    });
    return;
  };

  return (
    <DonateAgainCardContainer>
      <TopIssueCard issue={issue} fullHeight={true}>
        <>
          <Spacing $size={16} />
          <ButtonBrand
            onClick={handleDonate}
            to={`/checkout?p=${issue.query_str}&donate_again=true`}
          >
            {`Express ${
              issue.query_str.startsWith('monthly') ? 'Monthly ' : ''
            }Donate ${formatNumberAsCurrency(donationAmountAdjusted)}`}
          </ButtonBrand>
          <Spacing $size={16} />
          <Line />
          <Eyebrow $color={theme.colors.inkBlue}>Recipients</Eyebrow>
          <Spacing $size={6} />
          <RecipientsContainer>
            {recipients.map(rec => (
              <Recipient key={rec.recipient_id}>
                <Body key={rec.recipient_id}>{rec.recipient_name}</Body>
              </Recipient>
            ))}
          </RecipientsContainer>
          <Spacing $size={25} />
          <DonateAgainCardLearnMore>
            <Link
              href={getPathWithRef(issue.learn_more_link || `/donate?p=${issue.query_str}`)}
              target="_blank"
            >
              <BodySmall>Learn More</BodySmall>
            </Link>
          </DonateAgainCardLearnMore>
        </>
      </TopIssueCard>
    </DonateAgainCardContainer>
  );
};
