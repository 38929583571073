import Column from 'components/Column';
import styled from 'styled-components/macro';

export const Content = styled(Column)`
  padding: 16px;
`;

export const FloatingWrapper = styled.div`
  overflow: hidden;
  border-radius: 10px;
  height: fit-content;
  box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.shadows.black(0.08)};
  margin-top: -115px;

  @media (max-width: 1000px) {
    margin-top: 32px;
  }
`;
