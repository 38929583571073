export const siteConfigHelper = () => {
  let urlSection = 'sections=SITEWIDE';

  if (window.location.pathname === '/') {
    urlSection += '&sections=LANDING';
  } else if (window.location.href.includes('donate')) {
    urlSection += '&sections=DONATE';
  } else if (window.location.href.includes('profile')) {
    urlSection += '&sections=PROFILE';
  }

  return { urlSection };
};
