import { getPathWithRef } from 'helpers/utils';
import {
  Title,
  HeroContainer,
  HeroContentText,
  HeroContentImage,
  Subtitle,
  HeroText,
  LandingButton,
  StyledImage,
  HeroContentRow,
  StyledVideo,
  HeroContentRight,
} from './styled';
import { FC, ReactNode } from 'react';

interface Props {
  title: string;
  subtitle: string;
  ctaText: string;
  media?: string;
  isMonthly?: boolean;
  cta: string;
  children?: ReactNode;
}

export const Hero: FC<Props> = ({ title, subtitle, ctaText, media, isMonthly, cta, children }) => {
  return (
    <HeroContainer $isMonthly={isMonthly}>
      <HeroContentRow $justifyContent="space-between" $alignItems="center">
        <HeroContentText>
          <HeroText>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
          </HeroText>
          <LandingButton $secondary href={getPathWithRef(cta)}>
            {ctaText}
          </LandingButton>
        </HeroContentText>
        {media && (
          <HeroContentImage>
            {media.includes('.mp4') ? (
              <StyledVideo autoPlay loop muted playsInline id={`${title} Video`} controls>
                <source src={media} type="video/mp4" />
              </StyledVideo>
            ) : (
              <StyledImage alt={`${title} Image`} src={media} />
            )}
          </HeroContentImage>
        )}
        {children && <HeroContentRight>{children}</HeroContentRight>}
      </HeroContentRow>
    </HeroContainer>
  );
};
