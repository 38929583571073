import Column from 'components/Column';
import { H1Text, H4 } from 'components/Typography';
import styled from 'styled-components/macro';

export const OrbsVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 612px;
  object-fit: fill;
  z-index: -1;
`;

export const OrbsContainer = styled(Column)`
  padding: 80px 120px;
  text-align: center;

  @media (min-width: 1001px) and (max-width: 1400px) {
    padding: 80px 60px;
  }
  @media (min-width: 801px) and (max-width: 1000px) {
    padding: 40px 25px;
  }
  @media (max-width: 800px) {
    padding: 60px 25px;
  }
`;

export const TextsContainer = styled.div`
  max-width: 800px;
  justify-self: center;
`;

export const OrbsTitle = styled(H1Text)<{ $slider?: boolean }>`
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 44px;
  }
  ${({ $slider }) =>
    $slider &&
    `
      font-size: 40px;
      line-height: 44px;

      @media (max-width: 1000px) {
        font-size: 28px;
      }
    `}
`;

export const OrbsDescription = styled(H4)<{ $slider?: boolean }>`
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: 1000px) {
    font-size: 16px;
  }

  ${({ $slider }) =>
    $slider &&
    `
      font-size: 16px;

      @media (max-width: 1000px) {
        font-size: 12px;
      }
    `}
`;
