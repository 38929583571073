import Column from 'components/Column';
import styled from 'styled-components/macro';

export const Container = styled(Column)`
  padding: 16px;
  text-align: center;
  max-width: 1000px;
  margin: auto;
`;

export const EllipsesContainer = styled.div`
  margin: 36px 0;
  display: flex;

  @media (max-width: 700px) {
    display: grid;
  }
`;
