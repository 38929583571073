import Column from 'components/Column';
import { Body, BodySmall } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled.div<{ $refWidthValue?: number }>`
  overflow-x: auto;
  display: flex;
  margin: 0 16px;
  padding-bottom: 16px;
  ${({ $refWidthValue }) =>
    $refWidthValue && `max-width: ${window.innerWidth - $refWidthValue - 32}px`};
`;

export const TagContainer = styled.div<{ $isLastOne: boolean; $isSelected: boolean }>`
  background: ${({ theme, $isSelected }) =>
    $isSelected ? theme.colors.oathBlue : theme.colors.softGray};
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.white : theme.colors.inkBlue)};
  ${({ $isLastOne }) => !$isLastOne && 'margin-right: 8px'};
  padding: 4px 12px;
  text-align: center;
  min-width: fit-content;
  border-radius: 8px;
  cursor: pointer;
`;

export const Label = styled(BodySmall)<{ $isFixed?: boolean }>`
  align-self: center;
  ${({ $isFixed }) => $isFixed && 'padding-right: 8px'};
`;

export const Description = styled(Body)`
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.jumbo};
`;

export const DescriptionCTA = styled(Description)<{ $isCta: boolean }>`
  display: ${({ $isCta }) => ($isCta ? 'block' : 'none')};
  margin-bottom: ${({ $isCta }) => ($isCta ? '16px' : '0')};

  @media (max-width: 800px) {
    display: ${({ $isCta }) => ($isCta ? 'none' : 'block')};
    margin-bottom: ${({ $isCta }) => ($isCta ? '0' : '16px')};
  }
`;

export const Title = styled(Body)<{ $isFocus?: boolean; $smallOnly?: boolean }>`
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme, $isFocus }) => ($isFocus ? theme.colors.oathBlue : theme.colors.black)};
  @media (min-width: 800px) {
    ${({ $smallOnly }) => $smallOnly && 'display: none;'}
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.alto};
  margin-top: 16px;
`;

export const HiddenContainer = styled.div<{ $hiddenOnMobile?: boolean }>`
  @media (max-width: 800px) {
    ${({ $hiddenOnMobile }) => $hiddenOnMobile && 'display: none'};
  }
`;

export const DescContainer = styled(Column)`
  padding: 16px 16px 0 16px;
  background: ${({ theme }) => theme.colors.softGray};
`;

export const ShareContainer = styled.span<{ $isSmallOnly?: boolean }>`
  @media (min-width: 800px) {
    ${({ $isSmallOnly }) => $isSmallOnly && 'display: none'};
  }
`;
