import { ENV_CONFIG } from 'config/environment';
import Spacing from 'components/Spacing';
import LottieStory from 'components/LottieElements/Story';
import { Container, MotionText, StyledImage } from './styled';

export const NoImpactReport = ({ isPaused }) => {
  return (
    <LottieStory slide="orbs" isPaused={isPaused}>
      <Container>
        <MotionText $size={15}>
          It looks like we don't have any donations for the 2023-2024 cycle under this account.
        </MotionText>
        <Spacing $size={24} />
        <MotionText $size={15}>
          If you made donations with another email, log in under that email to view your Impact
          Report.
        </MotionText>
        <Spacing $size={24} />
        <MotionText $size={15}>
          If you're new here, please check out Oath's 2024 Community Impact Report. We're excited to
          have you onboard!
        </MotionText>
        <StyledImage
          alt="Oath logo"
          src={`${
            ENV_CONFIG().CLOUD_STORAGE.URL
          }/static_content/oath_logos/Oath_FullLockup_Color_LightText.svg`}
        />
      </Container>
    </LottieStory>
  );
};
