import Column from 'components/Column';
import { Image } from 'components/Image';
import { Row } from 'components/Row';
import styled from 'styled-components/macro';

export const InnerGradientBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  display: grid;
  padding: 30px 20px;
  justify-items: center;
  text-align: center;
`;

export const GetRecommendationsContainer = styled.div`
  position: relative;
`;

export const GetRecommendationsAbsolute = styled(Row)`
  position: absolute;
  top: -57px;
  justify-content: center;
  padding: 0 25px;
  left: 0;
  right: 0;
  justify-self: center;
  @media (max-width: 845px) {
    top: -65px;
  }
  @media (max-width: 600px) {
    top: -85px;
  }
`;

export const CommunityContainer = styled(Column)`
  background: ${({ theme }) => theme.colors.white};
  padding: 150px 24px 24px;
`;

export const MarketingContainer = styled(Column)`
  background: ${({ theme }) => theme.colors.softGray};
  padding: 60px 24px;
`;

export const ContentRow = styled(Row)`
  padding: 24px 0 60px;
  gap: 110px;
  @media (max-width: 1256px) {
    gap: 16px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const ContentImage = styled.div`
  flex: 1 1 0;
  max-width: 658px;
`;

export const ContentText = styled(Column)`
  flex: 2 1 0;
  max-width: 658px;

  @media (max-width: 900px) {
    text-align: center;
  }
`;

export const StyledImage = styled(Image)`
  max-width: 495px;
  width: 100%;

  @media (max-width: 900px) {
    max-width: 900px;
  }
`;

export const StyledVideo = styled.video`
  max-width: 495px;
  width: 100%;
  height: auto;
  border-radius: 10px;

  @media (max-width: 900px) {
    max-width: 900px;
  }
`;

export const ListExperiences = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: -webkit-fill-available;
  gap: 40px;
  justify-content: center;

  @media (max-width: 900px) {
    justify-content: inherit;
  }
`;
