import { createActions } from 'reduxsauce';

export interface TransactionsState {
  data: TransactionsData;
  isLoading: boolean;
  error: string;
}

export type TransactionsData = {
  transactions: TTransactions[];
  summary: TSummary;
  network_summary: TNetworkSummary;
};

export type TTransactions = {
  donation_id: string;
  transaction_date: string;
  amount: number;
  recipient: string;
  page_name: string;
};

export type TNetworkSummary = {
  referred_count: number;
  total_amount_donated: number;
  total_candidates: number;
};

export type TSummary = {
  total_donations: number;
  total_candidates: number;
  total_amount_donated: number;
  total_donations_ytd: number;
  total_candidates_ytd: number;
  total_amount_donated_ytd: number;
  min_donation_date: string;
};

export interface TransactionsTypes {
  TRANSACTIONS: 'TRANSACTIONS';
  TRANSACTIONS_START: 'TRANSACTIONS_START';
  TRANSACTIONS_SUCCESS: 'TRANSACTIONS_SUCCESS';
  TRANSACTIONS_ERROR: 'TRANSACTIONS_ERROR';
  TRANSACTIONS_CLEAN_UP: 'TRANSACTIONS_CLEAN_UP';
}

const { Types, Creators } = createActions<TransactionsTypes>({
  transactions: ['data'],
  transactionsStart: null,
  transactionsSuccess: ['data'],
  transactionsError: ['error'],
  transactionsCleanUp: null,
});

export { Types };

export default Creators;
