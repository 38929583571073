import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IRecipient, IRecipientDetailed, RecipientsState } from 'store/recipients/types';

export const getRecipientsState = (state: RootState): RecipientsState => state.recipients;

export const getRecipientsData = createSelector(
  [getRecipientsState],
  (state: RecipientsState): IRecipient[] => state.data?.recipients
);

export const getRecipientsDetailedData = createSelector(
  [getRecipientsState],
  (state: RecipientsState): IRecipientDetailed[] => state.detailed?.recipients
);

export const getRecipientDetailedByIdData = createSelector(
  [getRecipientsState],
  (state: RecipientsState): IRecipientDetailed => state.detailedById.data
);
