import { BodySmall } from 'components/Typography';
import styled from 'styled-components/macro';

export const ShareText = styled(BodySmall)<{ $color?: string }>`
  color: ${({ theme, $color }) => $color || theme.colors.white};
  letter-spacing: 0.1em;
  line-height: 14px;
  margin-left: 6px;
  text-decoration: underline;
  text-transform: uppercase;
`;
