import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { ImpactReportData, ImpactReportState } from 'store/impactReport/types';

export const getImpactReportState = (state: RootState): ImpactReportState => state.impactReport;

export const getImpactReportData = createSelector(
  [getImpactReportState],
  (state: ImpactReportState): ImpactReportData => state?.data
);
