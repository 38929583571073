import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import { ProcessingImageSmallWhite, SubmitPaymentRetry } from '../styled';

interface Props {
  hasError: boolean;
  isVerified: boolean;
  onClick: () => void;
  submitting: boolean;
}

export const RetryPaymentButton: FC<Props> = ({ hasError, isVerified, onClick, submitting }) => {
  const [hadError, setHadError] = useState(false);

  useEffect(() => {
    if (hasError) {
      setHadError(true);
    }
  }, [hasError]);

  if (!hadError || isVerified) {
    return null;
  }

  return (
    <Row $justifyContent="space-around">
      <SubmitPaymentRetry
        className={classNames({ 'animated-background': submitting })}
        type="button"
        disabled={submitting}
        onClick={onClick}
        $submitting={submitting}
      >
        <Row $alignItems="center" $justifyContent="center">
          <ProcessingImageSmallWhite
            src={`${
              ENV_CONFIG().CLOUD_STORAGE.URL
            }/static_content/misc_icons/circle_arrows_white.png`}
            alt="Circle formed by two arrows"
          />
          Try Again
        </Row>
      </SubmitPaymentRetry>
    </Row>
  );
};
