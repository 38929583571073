import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { MotionButton } from 'components/Header/styled';
import { Body, BodySmall } from 'components/Typography';

export const ClipboardModal = styled(motion.div)<{ $autoClose: boolean }>`
  background: ${({ theme, $autoClose }) =>
    $autoClose ? theme.shadows.black(0.6) : theme.shadows.black(0.8)};
  padding: 16px;
  width: ${({ $autoClose }) => ($autoClose ? '100px' : '240px')};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  z-index: 100;
  text-align: center;
`;

export const Title = styled(Body)`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.fonts.regular};
`;

export const Description = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.white};
`;

export const MotionButtonPopup = styled(MotionButton)`
  position: absolute;
  right: 0px;
  top: 8px;
`;
