import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Body, BodySmall } from 'components/Typography';

export const Wrapper = styled(motion.button)<{
  $inverted?: boolean;
  $withShadow?: boolean;
  $withRecipients?: boolean;
  $backgroundColor?: string;
  $maxWidth?: number;
  $disabled?: boolean;
}>`
  background: ${({ theme, $disabled, $inverted, $backgroundColor }) =>
    $backgroundColor
      ? $backgroundColor
      : $inverted
      ? theme.colors.transparent
      : $disabled
      ? theme.colors.alto
      : theme.colors.oathBlue};
  border: ${({ theme, $inverted }) =>
    $inverted ? `1px solid ${theme.colors.inkBlue}` : theme.colors.transparent};
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth || 600}px;
  margin: auto;
  border-radius: 60px;
  padding: ${({ $withRecipients }) => ($withRecipients ? '8px 0' : '12px 16px')};
  display: flex;
  justify-content: center;
  cursor: pointer;
  ${({ $withShadow }) => $withShadow && 'box-shadow: 0px 16px 16px 16px rgba(0, 0, 0, 0.25)'};
`;

export const ButtonContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Title = styled(Body)<{ $inverted?: boolean; $textColor?: string }>`
  ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme, $inverted, $textColor }) =>
    $inverted ? theme.colors.inkBlue : $textColor || theme.colors.white};
  text-align: center;
  width: 100%;
`;

export const Description = styled(BodySmall)<{ $inverted?: boolean; $textColor?: string }>`
  padding-top: 4px;
  color: ${({ theme, $inverted, $textColor }) =>
    $inverted ? theme.colors.inkBlue : $textColor || theme.colors.white};
`;
