import axios from 'axios';
import { Dispatch } from 'redux';
import RECOMMENDATIONS_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getSiteAccessToken } from 'services/storage';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getRecommendations =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(RECOMMENDATIONS_TYPES.recommendationsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_RECOMMENDATIONS}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
      });
      dispatch(RECOMMENDATIONS_TYPES.recommendationsSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(RECOMMENDATIONS_TYPES.recommendationsError(error.message));
      return Promise.reject(error);
    }
  };
