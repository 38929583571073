//FIREBASE CREDENTIALS
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAhsS2vtO4d1bb58pcrx4zMwnjUVZm9P_M',
  authDomain: 'oath-444b2.firebaseapp.com',
  projectId: 'oath-444b2',
  storageBucket: 'oath-444b2.appspot.com',
  messagingSenderId: '802887452910',
  appId: '1:802887452910:web:66ff1cf8991560d26c4bff',
  measurementId: 'G-24WM6MX4SL',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const logFirebaseEvent = async (event_name: string, params?: any) => {
  await logEvent(analytics, event_name, params);
};

export default logFirebaseEvent;
