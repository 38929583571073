import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container } from './styled';

export const LoginScreen = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, []);

  return <Container />;
};
