import styled from 'styled-components/macro';

interface ICheckBoxWrapper {
  $value: boolean;
  $disabled?: boolean;
}

export const CheckBoxWrapper = styled.div<ICheckBoxWrapper>`
  border: 2px solid ${({ theme }) => theme.colors.oathBlue};
  background: ${({ theme, $value }) => ($value ? theme.colors.oathBlue : theme.colors.white)};
  border-radius: 20px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  ${({ $disabled }) => $disabled && 'opacity: 25%'};
`;
