import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { BodySmall } from 'components/Typography';
import { Row } from 'components/Row';
import {
  FormPage,
  FormStyled,
  NextPrevButton,
  NextPrevLink,
  ProcessingRow,
  SubmitButton,
} from '../styled';
import Icon, { IconNames } from 'components/Icon';
import theme from 'config/theme';
import classNames from 'classnames';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { scrollToTop } from 'helpers/utils';

const backIconStyles = {
  WebkitTransform: 'scaleX(-1)',
  transform: 'scaleX(-1)',
};

const getSubmitButtonText = (submitting: boolean, isLastPage: boolean) => {
  if (!isLastPage) {
    return 'Continue';
  }
  if (submitting) {
    return 'Donation processing';
  }
  return 'Complete donation';
};

interface Props {
  activePageIndex: number;
  backToDonatePath: string;
  children: React.ReactNode[];
  initialValues: object;
  isSkipToReviewEnabled: boolean;
  onBackToDonatePage: () => void;
  onNavigateToLastPage: () => void;
  onSubmit: (arr: []) => void;
  setActivePageIndex: Dispatch<SetStateAction<number>>;
  validateArray: Array<(values) => object>;
}

export const Wizard: FC<Props> = ({
  activePageIndex,
  backToDonatePath,
  children,
  initialValues,
  isSkipToReviewEnabled,
  onBackToDonatePage,
  onNavigateToLastPage,
  onSubmit,
  setActivePageIndex,
  validateArray,
}) => {
  const [values, setValues] = useState<object>(initialValues);
  const activePage = React.Children.toArray(children)[activePageIndex];
  const isLastPage = activePageIndex === React.Children.count(children) - 1;

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (activePageIndex === children.length - 1) {
      onNavigateToLastPage();
    }
  }, [activePageIndex]);

  const next = values => {
    setActivePageIndex(currentPage => Math.min(currentPage + 1, children.length - 1));
    setValues(values);
    scrollToTop();
  };

  const previous = () => {
    setActivePageIndex(currentPage => Math.max(currentPage - 1, 0));
    scrollToTop();
  };

  const skipToReview = (valid: boolean, values) => {
    if (!valid) {
      scrollToTop();
      return;
    }
    setActivePageIndex(children.length - 1);
    setValues(values);
    scrollToTop();
  };

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  const validate = values => {
    if (validateArray[activePageIndex]) {
      return validateArray[activePageIndex](values);
    }
    return {};
  };

  const handleSubmit = values => {
    if (isLastPage) {
      return onSubmit(values);
    } else {
      next(values);
    }
  };

  const prevButtonContent = (
    <>
      <Icon
        name={IconNames.ChevronRight}
        color={theme.colors.black}
        size={18}
        style={backIconStyles}
      />
      <BodySmall>Previous</BodySmall>
    </>
  );

  return (
    <Form initialValues={values} validate={validate} onSubmit={handleSubmit}>
      {({ handleSubmit, submitting, submitError, valid, values }) => (
        <FormStyled onSubmit={handleSubmit}>
          <div>
            <Row $justifyContent="space-between">
              {activePageIndex > 0 ? (
                <NextPrevButton type="button" onClick={previous}>
                  {prevButtonContent}
                </NextPrevButton>
              ) : (
                <NextPrevLink onClick={onBackToDonatePage} to={backToDonatePath}>
                  {prevButtonContent}
                </NextPrevLink>
              )}
              {!isLastPage && (
                <NextPrevButton type="submit">
                  <BodySmall>Next</BodySmall>
                  <Icon name={IconNames.ChevronRight} color={theme.colors.black} size={18} />
                </NextPrevButton>
              )}
            </Row>
            <FormPage>
              {activePage}
              <Row $justifyContent="space-around">
                <SubmitButton
                  className={classNames({ 'animated-background': submitting })}
                  type="submit"
                  disabled={submitting}
                  $submitting={submitting}
                  $secondary={!valid && !isLastPage}
                  $hasBorder={!valid && !isLastPage}
                >
                  {getSubmitButtonText(submitting, isLastPage)}
                </SubmitButton>
              </Row>
              {isSkipToReviewEnabled && (
                <>
                  <Spacing $size={8} />
                  <Row $justifyContent="space-around">
                    <Link
                      $color={theme.colors.shaft}
                      onClick={() => skipToReview(valid, values)}
                      type="button"
                    >
                      <BodySmall $color={theme.colors.shaft}>Skip to Review Donation</BodySmall>
                    </Link>
                  </Row>
                </>
              )}
              {submitting && (
                <ProcessingRow>
                  <BodySmall>
                    This may take some time. Please do not refresh your browser. Your donation is
                    processing.
                  </BodySmall>
                </ProcessingRow>
              )}
              {submitError && !submitting && (
                <ProcessingRow>
                  <BodySmall $color={theme.colors.errorRed}>
                    Sorry, there was an error processing your donation. Please double check your{' '}
                    information and try again. {submitError}.
                  </BodySmall>
                </ProcessingRow>
              )}
            </FormPage>
          </div>
        </FormStyled>
      )}
    </Form>
  );
};
