import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { SetsData, SetsState } from 'store/sets/types';

export const getSetsState = (state: RootState): SetsState => state.sets;

export const getSetsData = createSelector(
  [getSetsState],
  (state: SetsState): SetsData => state?.data
);
