import axios from 'axios';
import { Dispatch } from 'redux';
import TAGS_TYPES, { getTagsProps } from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getTags =
  ({ urlPartner }: getTagsProps) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(TAGS_TYPES.tagsStart());
    const query_str = urlPartner ? `/${urlPartner}` : '';

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_TAGS(query_str)}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(TAGS_TYPES.tagsSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(TAGS_TYPES.tagsError(error.message));
      return Promise.reject(error);
    }
  };

export const getTagsFullList =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(TAGS_TYPES.tagsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_TAGS('')}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(TAGS_TYPES.tagsFullListSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(TAGS_TYPES.tagsFullListError(error.message));
      return Promise.reject(error);
    }
  };
