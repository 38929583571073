import { FC } from 'react';
import { useCycle, motion } from 'framer-motion';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import Icon, { IconNames } from 'components/Icon';
import { Bar } from './Bar';
import { Container, Content, MotionButton, InfoText, TopContent, SeparatorLine } from './styled';

interface Props {
  urlPartner?: string;
  hasIssues?: boolean;
}

export const Header: FC<Props> = ({ urlPartner, hasIssues }) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const landingInfo = {
    open: () => ({
      clipPath: 'inset(0 0 0 0)',
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    }),
    closed: {
      clipPath: 'inset(0 0 100% 0)',
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  const ToggleContent = () => {
    return (
      <>
        {isOpen && (
          <motion.div initial={false} animate={isOpen ? 'open' : 'closed'} variants={landingInfo}>
            <Spacing $size={12} />
            <Content $isLanding={true}>
              <InfoText color={theme.colors.white}>
                Oath is the smartest way to support the causes you care about.
              </InfoText>
              <Spacing $size={16} />
              <InfoText color={theme.colors.white}>
                <InfoText color={theme.colors.white} $isBold>
                  Maximize Impact:{' '}
                </InfoText>
                Direct your money where it’s needed most.
              </InfoText>
              <InfoText color={theme.colors.white}>
                <InfoText color={theme.colors.white} $isBold>
                  Eliminate Spam:{' '}
                </InfoText>
                Oath never sells or shares your data.
              </InfoText>
            </Content>
          </motion.div>
        )}
        <MotionButton whileTap={{ scale: 0.9 }}>
          <Icon
            onClick={toggleOpen}
            name={isOpen ? IconNames.ChevronUp : IconNames.ChevronDown}
            size={24}
            color={theme.colors.white}
          />
        </MotionButton>
      </>
    );
  };

  return (
    <Container color={theme.colors.white}>
      <Bar />
      {!urlPartner && !hasIssues && (
        <TopContent $isLanding={true}>
          <InfoText color={theme.colors.white}>Your one-stop-shop for political giving.</InfoText>
          <ToggleContent />
        </TopContent>
      )}
      {!urlPartner && !hasIssues && <SeparatorLine />}
    </Container>
  );
};
