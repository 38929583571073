import { FC, useEffect, useState } from 'react';
import { Row } from 'components/Row';
import { DonateButton } from 'components/Slate/styled';
import { CustomSplit } from 'components/Slate/CustomSplit';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { DonationTotal } from 'components/Slate/DonationAmount/DonationTotal';
import { getUrlParams } from 'helpers/utils';
import { trackPixel } from 'helpers/pixel';
import { useAppSelector } from 'helpers/hooks';
import { getSelectedCandidates } from 'helpers/donate';
import { getDonationAmounts } from 'selectors/tags';
import { Title, Content, Subtitle, Description, DonateContainer } from './styled';

interface Props {
  candidates: CandidateType[];
  selectedDonationAmount?: number;
  onDonateClick: (selectedTags?: string[]) => void;
  onUserDonationTotalChange: (value?: number) => void;
  selectedTags: string[];
  setCandidates: React.Dispatch<React.SetStateAction<CandidateType[]>>;
  setSelectedDonationAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  showCustomSplit: boolean;
}

export const Donate: FC<Props> = ({
  candidates,
  selectedTags,
  onDonateClick,
  onUserDonationTotalChange,
  selectedDonationAmount,
  setCandidates,
  setSelectedDonationAmount,
  showCustomSplit,
}) => {
  const { donationAmounts } = useAppSelector(state => ({
    donationAmounts: getDonationAmounts(state),
  }));

  const [arrDonationAmounts, setArrDonationAmounts] = useState(donationAmounts);
  const partnerParams = getUrlParams()?.urlPartner ? `&p=${getUrlParams()?.urlPartner}` : '';

  useEffect(() => {
    if (partnerParams) setArrDonationAmounts(donationAmounts);
  }, []);

  const handleDonate = () => {
    trackPixel('donation');
    onDonateClick(selectedTags);
  };

  return (
    <DonateContainer>
      <Content>
        <Title>DONATE</Title>
        <Subtitle>Select the amount you wish to contribute.</Subtitle>
        {getSelectedCandidates(candidates).length > 1 && (
          <Description>
            Your donation will be split equally among your selected{' '}
            {getSelectedCandidates(candidates).length} recipients.
          </Description>
        )}
        <DonationTotal
          donationAmounts={arrDonationAmounts}
          selectedDonationAmount={selectedDonationAmount}
          setSelectedDonationAmount={onUserDonationTotalChange}
        >
          <>
            <Row $justifyContent="space-around">
              <DonateButton onClick={handleDonate}>DONATE</DonateButton>
            </Row>
            <CustomSplit
              candidates={candidates}
              donationTotal={selectedDonationAmount}
              setCandidates={setCandidates}
              setDonationTotal={setSelectedDonationAmount}
              showCustomSplit={showCustomSplit}
              resetDonationTotal={onUserDonationTotalChange}
            />
          </>
        </DonationTotal>
      </Content>
    </DonateContainer>
  );
};
