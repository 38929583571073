const fonts = {
  regular: () => {
    return {
      fontFamily: 'DMSans-Regular',
    };
  },
  italic: () => {
    return {
      fontFamily: 'DMSans-Regular',
      fontStyle: 'italic',
    };
  },
  light: () => {
    return {
      fontFamily: 'DMSans-Light',
    };
  },
  semiBold: () => {
    return {
      fontFamily: 'DMSans-SemiBold',
    };
  },
  semiBoldItalic: () => {
    return {
      fontFamily: 'DMSans-SemiBold',
      fontStyle: 'italic',
    };
  },
  panelSansMedium: () => {
    return {
      fontFamily: 'Panel_Sans_Medium',
    };
  },
};

export default fonts;
