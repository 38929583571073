import { useAuth0 } from '@auth0/auth0-react';
import { ENV_CONFIG } from 'config/environment';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { getUserData } from 'selectors/user';
import { setSiteAccessToken, setUserName } from 'services/storage';
import { loginUser } from 'store/user/actions';

export const useAuth = (onAuth?: () => any) => {
  const dispatch = useAppDispatch();
  const [auth0Requested, setAuth0Requested] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const userData = useAppSelector(getUserData);

  useEffect(() => {
    if (userData) {
      setUserName(userData.first_name);
    }
  }, [userData]);

  useEffect(() => {
    const getUserMetadata = async () => {
      if (user?.sub) {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `https://${ENV_CONFIG().AUTH.DOMAIN}/api/v2/`,
              scope: 'read:current_user',
            },
          });

          setSiteAccessToken(accessToken);
          if (accessToken) {
            await dispatch(loginUser(accessToken));
            if (onAuth) {
              await onAuth();
            }
          }
          setAuth0Requested(true);
        } catch (e) {
          console.warn(e);
        }
      }
    };

    if (!auth0Requested) {
      getUserMetadata();
    }
  }, [user?.sub]);

  return auth0Requested;
};
