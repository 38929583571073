import { Eyebrow } from 'components/Typography';
import styled, { keyframes } from 'styled-components/macro';

const marqueeAnimation = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const Container = styled.div<{ $background?: string; $hasLink?: boolean }>`
  background: ${({ theme, $background }) => $background || theme.colors.electricBlue};
  padding: 9px 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  ${({ $hasLink }) => $hasLink && 'cursor: pointer'};
`;

export const BannerText = styled(Eyebrow)`
  display: inline-flex;
  animation: ${marqueeAnimation} 40s linear infinite;
  white-space: nowrap;
  width: -webkit-fill-available;

  @media (max-width: 800px) {
    animation: ${marqueeAnimation} 20s linear infinite;
  }

  @-moz-document url-prefix() {
    animation-duration: 350s;
  }
`;

export const TextWrapper = styled.div<{ $color?: string }>`
  margin-right: 40px;
  color: ${({ theme, $color }) => $color || theme.colors.oathBlue};
`;
