import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { PayResponseData, PayState } from 'store/pay/types';

export const getPayState = (state: RootState): PayState => state.pay;

export const getPayData = createSelector(
  [getPayState],
  (state: PayState): PayResponseData => state.data
);
