import Column from 'components/Column';
import { Image } from 'components/Image';
import { BodySmall } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.inkBlue};
  padding: 16px;
`;

export const FooterDivider = styled.div`
  height: 5px;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${theme.colors.electricBlue}, ${theme.colors.darkGreen}, ${theme.colors.oathBlue})`};
`;

export const FooterColumn = styled(Column)`
  gap: 10px;
  max-width: 20%;
  align-content: flex-start;

  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

export const PolicyContainer = styled.div`
  text-align: center;
  padding: 16px 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.white};
  margin: 32px 60px 0 60px;

  @media (max-width: 800px) {
    margin: 0;
    margin-top: 32px;
  }
`;

export const Logo = styled(Image)`
  height: 60px;
  padding-bottom: 16px;
  cursor: pointer;
`;

export const LabelContainer = styled.div`
  display: flex;
  justify-content: space-around;

  @media (max-width: 800px) {
    display: grid;
  }
`;

export const Title = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Label = styled(Title)<{ $isBold?: boolean; onClick?: () => void }>`
  ${({ theme, $isBold }) => ($isBold ? theme.fonts.semiBold : theme.fonts.light)};
  letter-spacing: 0.5px;
  align-self: flex-start;
  padding-bottom: 8px;
  ${({ onClick }) => !!onClick && 'cursor: pointer'};
`;
