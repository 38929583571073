import { FC } from 'react';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { Body, H4 } from 'components/Typography';
import { Container } from './styled';
interface Props {
  title: string;
  description: string;
  author: string;
  location: string;
}

export const QuoteCard: FC<Props> = ({ title, description, author, location }) => {
  return (
    <Container>
      <H4 $fontType="semiBold">{title}</H4>
      <Spacing $size={15} />
      <H4>{description}</H4>
      <Spacing $size={60} />
      <Body $color={theme.colors.gray}>
        {author}, {location}
      </Body>
    </Container>
  );
};
