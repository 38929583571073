import { FC } from 'react';
import Column from 'components/Column';
import { Link } from 'components/Link';
import { Body } from 'components/Typography';
import { StyledImage } from './styled';

interface Props {
  text: string;
  image?: string;
  onPress?: () => void;
}

export const EllipseText: FC<Props> = ({ text, onPress, image }) => {
  return (
    <Column $width="100%" $textAlign="center">
      <StyledImage alt="ellipse" src={image || require('../../assets/images/ellipse.png')} />
      {onPress ? (
        <Link onClick={onPress} $textAlign="center" $fontSize="15px">
          {text}
        </Link>
      ) : (
        <Body $fontType="regular">{text}</Body>
      )}
    </Column>
  );
};
