import { createActions } from 'reduxsauce';

export interface ImpactReportState {
  data: ImpactReportData;
  isLoading: boolean;
  error: string;
}

export type ImpactReportData = {
  donation_amount_total: string;
  donation_amount_multiplier: string;
  donation_amount_total_adjusted: string;
  recipient_count: number;
  recipient_counts: object;
  recipient_count_federal: number;
  recipient_count_statewide: number;
  recipient_count_local: number;
  recipient_count_ballot_measures: number;
  recipient_count_organizations: number;
  recipient_count_stateleg: number;
  recipient_impact_score_average: string;
  recipient_ids_winners: string[];
  election_margin_count_average: number;
  election_margin_percentage_average: string;
  election_margin_min: IElectionMargin;
  spam_avoided_count: number;
  top_issue_donation_percentage: string;
  issues: IIssue[];
  candidates: ICandidate[];
};

export interface IElectionMargin {
  count: number;
  percentage: string;
  election_id: string;
  recipient_name: string;
  election_name: string;
  democrat_election_result: string;
  impact_score: string;
}

export interface IIssue {
  tag_id: string;
  tag_name: string;
  query_string: string;
  donation_amount: string;
}

export interface ICandidate {
  recipient_id: string;
  recipient_name: string;
  office_type: string;
  office_level: string;
  election_result: string;
  election_margin_count: number;
  election_margin_percentage: string;
  tag_id: string;
  tag_name: string;
  transaction_date_first: string;
  donation_count_total: number;
  donation_amount_total: string;
  donation_amount_multiplier: string;
  impact_score: string;
  type: string;
  type_display: string;
}

export interface ImpactReportTypes {
  IMPACT_REPORT: 'IMPACT_REPORT';
  IMPACT_REPORT_START: 'IMPACT_REPORT_START';
  IMPACT_REPORT_SUCCESS: 'IMPACT_REPORT_SUCCESS';
  IMPACT_REPORT_ERROR: 'IMPACT_REPORT_ERROR';
  IMPACT_REPORT_CLEAN_UP: 'IMPACT_REPORT_CLEAN_UP';
}

const { Types, Creators } = createActions<ImpactReportTypes>({
  impactReport: ['data'],
  impactReportStart: null,
  impactReportSuccess: ['data'],
  impactReportError: ['error'],
  impactReportCleanUp: null,
});

export { Types };

export default Creators;
