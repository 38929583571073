import { Dispatch } from 'redux';
import FAQS_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getFaqs =
  (type: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(FAQS_TYPES.faqsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_FAQS(type)}`;
    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(FAQS_TYPES.faqsSuccess(response.data));
    } catch (error: any) {
      dispatch(FAQS_TYPES.faqsError(error.message));
    }
  };
