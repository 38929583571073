import { Row } from 'components/Row';
import styled from 'styled-components/macro';

export const Input = styled.input`
  ${({ theme }) => theme.fonts.regular};
  border: ${({ theme }) => `1px solid ${theme.colors.inkBlue}`};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.inkBlue};
  padding: 8px 10px;
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.colors.oathBlue};
  }
  &.error {
    background: ${({ theme }) => theme.shadows.errorRed(0.2)};
    border: ${({ theme }) => `1px solid ${theme.colors.errorRed}`};
    color: ${({ theme }) => theme.colors.errorRed};
  }
`;

export const Recipients = styled(Row)`
  flex-direction: column;
  gap: 4px;
  margin-top: 22px;
`;

export const NumberInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const TotalRow = styled(Row)`
  gap: 5px;
  margin: 9px 0 6px;
`;

export const HR = styled.hr`
  background: ${({ theme }) => theme.shadows.black(0.2)};
  border: 0;
  height: 1px;
  margin-bottom: 16px;
  margin-top: 27px;
  width: 100%;
`;

export const CustomSplitRow = styled(Row)`
  gap: 8px;
  justify-content: flex-end;
  margin-top: 6px;
  @media (max-width: 800px) {
    justify-content: center;
  }
`;

export const RecipientInputRow = styled(Row)`
  gap: 20px;
`;
