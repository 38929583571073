import { ButtonBrand } from 'components/ButtonBrand';
import Column from 'components/Column';
import { H3 } from 'components/Typography';
import styled from 'styled-components/macro';

export const InnerGradientBox = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  margin: 2px;
`;

export const HiddenContainer = styled.div<{ $showInDesktop: boolean }>`
  display: ${({ $showInDesktop }) => ($showInDesktop ? 'block' : 'none')};

  @media (max-width: 800px) {
    display: ${({ $showInDesktop }) => ($showInDesktop ? 'none' : 'block')};
  }
`;

export const TabIssueSelectContainer = styled.div<{ $isActive: boolean }>`
  border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
  border-radius: 5px;
  padding: 8px 7px 5px 12px;
  ${({ $isActive, theme }) =>
    $isActive &&
    `
      border-color: ${theme.colors.oathBlue};
    `}
  label {
    cursor: pointer;
    ${({ theme }) => theme.fonts.light};
    font-size: 13px;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.inkBlue};
    text-align: left;

    &:hover {
      color: ${({ theme }) => theme.colors.electricBlue};
    }
  }
`;

export const ItemTitle = styled(H3)`
  @media (max-width: 1000px) {
    font-size: 24px;
    line-height: 27px;
  }
`;

export const StyledButton = styled(ButtonBrand)`
  width: 100%;
  max-width: 300px;

  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

export const Content = styled(Column)`
  padding: 30px 110px;

  @media (max-width: 1400px) {
    padding: 16px;
  }
`;
