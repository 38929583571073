import Slider from 'rc-slider';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Button from 'components/Button';
import Spacing from 'components/Spacing';
import { formatTime } from 'helpers/date';
import { useAppSelector } from 'helpers/hooks';
import { formatNumber, abbreviateNum, getUrlParams, getPathWithRef } from 'helpers/utils';
import { getSetsData } from 'selectors/sets';
import { OrbsVideo, OrbsTitle, OrbsContainer, OrbsDescription, TextsContainer } from './styled';

export const OrbsBackground = () => {
  const { urlPartner } = getUrlParams();
  return (
    <OrbsVideo autoPlay loop muted playsInline id="orbs-video">
      <source
        src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/sets/${urlPartner}/header.mp4`}
        type="video/mp4"
      />
    </OrbsVideo>
  );
};

export const OrbsHeader = ({ tagsLength }) => {
  const setsData = useAppSelector(getSetsData);

  const sliderStyles = {
    rail: {
      height: 20,
      borderRadius: 20,
    },
    track: {
      background: theme.colors.inkBlue,
      height: 20,
      borderRadius: 20,
    },
    handle: {
      display: 'none',
    },
  };

  return (
    <>
      <OrbsBackground />
      {setsData && (
        <OrbsContainer>
          <TextsContainer>
            <OrbsTitle>{setsData.set_title}</OrbsTitle>
            <Spacing $size={16} />
            <OrbsDescription>{setsData.set_desc}</OrbsDescription>
          </TextsContainer>
          <Spacing $size={16} />
          <Row $justifyContent="space-between" $alignItems="flex-end">
            <OrbsTitle $slider>
              ${formatNumber(parseInt(setsData.fundraising_raised))}
              <OrbsDescription $slider>
                {' '}
                of ${formatNumber(parseInt(setsData.fundraising_goal))} goal
              </OrbsDescription>
            </OrbsTitle>
            <OrbsDescription $slider>
              {abbreviateNum(setsData.fundraising_donation_count)} donations
            </OrbsDescription>
          </Row>
          <Slider
            min={0}
            max={100}
            value={
              (parseInt(setsData.fundraising_raised) * 100) / parseInt(setsData.fundraising_goal)
            }
            styles={sliderStyles}
          />
          <Spacing $size={16} />
          <Row $justifyContent="space-between">
            <OrbsDescription $slider>Updated at {formatTime(new Date())}</OrbsDescription>
            {setsData.latest_fundraising_location && (
              <OrbsDescription $slider>
                Just donated from {setsData.latest_fundraising_location}
              </OrbsDescription>
            )}
          </Row>
          <Spacing $size={16} />
          {tagsLength > 0 && (
            <>
              <Button
                text={setsData?.set_misc?.donate_custom_1 || `DONATE TO ALL ${tagsLength} ISSUES`}
                onPress={() =>
                  window.open(
                    getPathWithRef(`${ENV_CONFIG().SITE_URL}/donate?p=${setsData.querystring}`),
                    '_blank'
                  )
                }
                backgroundColor={theme.colors.white}
                textColor={theme.colors.inkBlue}
                maxWidth={360}
              />
              <Spacing $size={8} />
              <OrbsDescription $slider>Or donate to an issue of your choice below</OrbsDescription>
              <Spacing $size={60} />
            </>
          )}
        </OrbsContainer>
      )}
    </>
  );
};
