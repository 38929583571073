import styled from 'styled-components/macro';
import { Row } from 'components/Row';
import { BaseText } from 'components/Typography';

export const CardPadding = styled.div`
  padding: 0 40px;
  @media (max-width: 540px) {
    padding: 0 12.5px;
  }
`;

export const CountdownHR = styled.hr`
  background: ${({ theme }) => theme.shadows.black(0.3)};
  border: 0;
  height: 1px;
  margin: 17px 0;
  width: 100%;
`;

export const CountdownLinkRow = styled(Row)`
  gap: 6px;
  @media (max-width: 540px) {
    flex-direction: column;
  }
  a {
    flex-grow: 1;
  }
`;

export const CounterNumber = styled.div`
  border: ${({ theme }) => `0.7px solid ${theme.colors.inkBlue}`};
  border-radius: 5px;
  box-shadow: ${({ theme }) => `0px 0px 8px 0px ${theme.shadows.black(0.15)}`};
  font-size: 24px;
  line-height: 30px;
  padding: 2px 8px;
`;

export const CounterRow = styled(Row)`
  gap: 3px;
`;

export const CounterCharacter = styled(BaseText)`
  font-family: monospace;
  font-size: 40px;
  letter-spacing: 0.1em;
  line-height: 48px;
  @media (max-width: 1063px) {
    font-size: 55px;
    line-height: 60px;
  }
  @media (max-width: 700px) {
    font-size: 40px;
    line-height: 48px;
  }
  @media (max-width: 540px) {
    font-size: 24px;
    line-height: 30px;
  }
`;
