import { Link } from 'components/Link';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { UtilityText } from 'components/Typography';
import { useOnCheckoutLoginClick } from 'hooks/donate';
import { FC } from 'react';

interface Props {
  candidates: CandidateType[];
  donationAmount: number;
}

export const LoginCTA: FC<Props> = ({ candidates, donationAmount }) => {
  const onCheckoutLoginClick = useOnCheckoutLoginClick();

  return (
    <UtilityText>
      Have an Oath account?{' '}
      <Link
        $fontSize="inherit"
        onClick={() => {
          onCheckoutLoginClick(candidates, donationAmount);
        }}
      >
        Sign in to give faster.
      </Link>
    </UtilityText>
  );
};
