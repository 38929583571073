import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const transactionsStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const transactionsSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const transactionsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const transactionsCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.TRANSACTIONS_START]: transactionsStart,
  [Types.TRANSACTIONS_SUCCESS]: transactionsSuccess,
  [Types.TRANSACTIONS_ERROR]: transactionsError,
  [Types.TRANSACTIONS_CLEAN_UP]: transactionsCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
