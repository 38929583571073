import axios from 'axios';
import { Dispatch } from 'redux';
import SETS_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getSets =
  (query_str: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(SETS_TYPES.setsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_SETS(query_str)}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(SETS_TYPES.setsSuccess(response.data));
    } catch (error: any) {
      dispatch(SETS_TYPES.setsError(error.message));
    }
  };
