import { useEffect } from 'react';
import { Hero } from 'components/Hero';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { DaysCountCard } from 'components/Cards/DaysCountCard';
import { DollarCountCard } from 'components/Cards/DollarCountCard';
import { trackPixel } from 'helpers/pixel';
import { useAppSelector } from 'helpers/hooks';
import { getLandingConfig } from 'selectors/siteConfig';
import { PressSection } from './PressSection';
import { TrendingSection } from './TrendingSection';
import { LearnMoreSection } from './LearnMoreSection';
import { PoweredBySection } from './PoweredBySection';
import { GetStartedSection } from './GetStartedSection';
import { GetRecommendations } from './GetRecommendations';
import { ImpactReportSection } from './ImpactReportSection';
import { Container, Content } from './styled';

const redirectToDonateIfNeeded = (currentUrl: string) => {
  const url = new URL(currentUrl);
  const searchParams = url.searchParams;

  if (searchParams.has('tags') || searchParams.has('p')) {
    window.location.href = `/donate?${searchParams.toString()}`;
  }
  return;
};

export const LandingScreen = () => {
  redirectToDonateIfNeeded(window.location.href);
  const { landingTopCardConfig, landingImpactConfig } = useAppSelector(state => ({
    landingTopCardConfig: getLandingConfig(state, 'top_card'),
    landingImpactConfig: getLandingConfig(state, 'impact_card'),
  }));

  useEffect(() => {
    trackPixel('universalPixel');
  }, []);

  return (
    <Container>
      <Bar />
      <Content>
        <Hero
          title="The smartest way to do your political giving."
          subtitle="Oath empowers donors to give more strategically by making data-driven recommendations about where your dollars have maximum impact."
          ctaText="Sign up now"
          cta="/login"
        >
          {landingTopCardConfig?.top_card_config === 'countdown-close-the-gap' ? (
            <DollarCountCard />
          ) : (
            landingTopCardConfig?.top_card_config === 'countdown-days-until-election' && (
              <DaysCountCard date={landingTopCardConfig?.election_date} />
            )
          )}
        </Hero>
        <PressSection />
        {landingImpactConfig?.impact_card_config === 'on' && (
          <ImpactReportSection period={landingImpactConfig?.period} />
        )}
        <GetStartedSection />
        {landingImpactConfig?.impact_card_config !== 'on' && <TrendingSection />}
        <GetRecommendations />
        <LearnMoreSection />
        <PoweredBySection />
      </Content>
      <Footer />
    </Container>
  );
};
