import Column from 'components/Column';
import { Body } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled.div`
  margin: auto;
  text-align: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};
  position: relative;

  @media (min-width: 1000px) {
    max-width: 375px;
  }
`;

export const Content = styled(Column)`
  padding: 16px;
`;

export const Header = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.oathBlue};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 8px 0;
`;

export const Description = styled(Body)`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.fonts.light};
  text-align: center;
`;

export const TopIssueWrapper = styled.div<{ $width?: number; $isFloating?: boolean }>`
  overflow: hidden;
  border-radius: 20px;
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
  margin-top: ${({ $isFloating }) => ($isFloating ? '-140px' : '0px')};
  height: fit-content;
  box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.shadows.black(0.08)};

  @media (max-width: 800px) {
    margin-top: ${({ $isFloating }) => ($isFloating ? '-110px' : '0px')};
    width: ${({ $width }) => ($width ? '100%' : 'auto')};
  }
`;

export const FloatingWrapper = styled(TopIssueWrapper)`
  margin-top: -115px;

  @media (max-width: 1000px) {
    margin-top: 32px;
  }
`;

export const FadeBottom = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(181.81deg, rgba(255, 255, 255, 0) 1.54%, ${theme.colors.white} 88.78%)`};
  bottom: 0;
  height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
`;
