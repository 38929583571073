export const getTagColor = (key: string) => {
  if (!Object.hasOwn(colorMap, key)) {
    return '#E4E1DC';
  }
  return colorMap[key];
};

export const colorMap = {
  'D+1': '#CBF4F5',
  'D+2': '#BDF1F3',
  'D+3': '#A3EBEE',
  'D+4': '#A3EBEE',
  'D+5': '#96E9EC',
  'D+6': '#7CE3E7',
  Even: '#E4E1DC',
  'R+1': '#F3DBCE',
  'R+2': '#ECC9B6',
  'R+3': '#E6B79D',
  'R+4': '#E0A585',
  'R+5': '#DA936D',
  'R+6': '#D48154',
  'Lean D': '#CBF4F5',
  'Likely D': '#AAEDEF',
  'Solid D': '#7CE3E7',
  'Uncontested D': '#7CE3E7',
  'Toss Up': '#E4E1DC',
  'Lean R': '#EFD2C2',
  'Likely R': '#E3AE91',
  'Solid R': '#D48154',
  'Uncontested R': '#D48154',
};
