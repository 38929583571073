import Column from 'components/Column';
import { Row } from 'components/Row';
import { BodySmall } from 'components/Typography';
import styled from 'styled-components/macro';

export const EditProfileContainer = styled.div`
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.inkBlue};
  font-size: 13px;
  line-height: 18px;
  input,
  select {
    border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
    border-radius: 5px;
    box-sizing: border-box;
    margin: 3px 0;
    padding: 13px 12px;
    width: 80%;
    height: 45px;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .input,
  .filled .input {
    border-color: ${({ theme }) => `${theme.colors.platinum}`} !important;
  }
  .active .input {
    border-color: ${({ theme }) => `${theme.colors.oathBlue}`} !important;
  }
  label {
    display: block;
  }
`;

export const SectionGrid = styled(Column)`
  grid-template-columns: 50% 50%;
  row-gap: 12px;
  @media (max-width: 800px) {
    grid-template-columns: 100%;
  }
`;

const Content = styled.div`
  @media (max-width: 800px) {
    width: 100% !important;
  }
`;

export const HeaderSection = styled(Content)<{ $width?: string }>`
  ${({ $width }) => $width && `width: ${$width}`};
  margin-right: 50px;
  @media (max-width: 800px) {
    margin-bottom: 20px;
    margin-right: 0px;
  }
`;

export const BackText = styled(BodySmall)`
  text-decoration: none;
`;

export const HR = styled.hr`
  background: ${({ theme }) => theme.shadows.black(0.3)};
  border: 0;
  height: 1px;
  margin-bottom: 15px;
  margin-top: 10px;
  width: 100%;
`;

export const IssueTagsContainer = styled(Row)`
  flex-wrap: wrap;
`;

export const CloseIcon = styled.span`
  font-size: 8px;
  margin-left: 8px;
`;

export const CadenceSelectContainer = styled.div<{ $isActive: boolean }>`
  border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
  border-radius: 5px;
  padding: 8px 7px 5px 12px;
  width: 80%;
  ${({ $isActive, theme }) =>
    $isActive &&
    `
      border-color: ${theme.colors.oathBlue};
    `}
  label {
    cursor: pointer;
    margin-bottom: 10px;
    &:hover {
      color: ${({ theme }) => theme.colors.electricBlue};
    }
  }
`;

export const Checkbox = styled(Row)<{ $isChecked: boolean }>`
  ${({ theme, $isChecked }) => $isChecked && `background: ${theme.colors.oathBlue}`};
  border: ${({ theme }) => `1px solid ${theme.colors.black}`};
  border-radius: 2px;
  height: 15px;
  margin-right: 8px;
  width: 15px;
`;

export const RetiredCheckboxRow = styled(Row)`
  color: ${({ theme }) => theme.colors.shaft};
  cursor: pointer;
`;

export const SelectLabel = styled.label`
  margin-bottom: 3px;
`;

export const SelectContainer = styled.div`
  width: 80%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
