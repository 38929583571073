import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import Button from 'components/Button';
import Column from 'components/Column';
import Loader from 'components/Loader';
import Spacing from 'components/Spacing';
import { Bar } from 'components/Header/Bar';
import { trackPixel } from 'helpers/pixel';
import { getPathWithRef, wait } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getQuestionsData } from 'selectors/questions';
import { getIsUserOnboarded, setIsUserOnboarded, setUserName } from 'services/storage';
import { answerQuestion, getQuestions } from 'store/questions/actions';
import { StepFieldsDictionary } from './StepFieldsDictionary';
import { Container, ProgressBar, BarContainer, Label, Title, Subtitle } from './styled';

export const StepsScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [answer, saveAnswer] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const { isLoading, isAuthenticated } = useAuth0();

  const { questionsData } = useAppSelector(state => ({
    questionsData: getQuestionsData(state),
  }));

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) navigate(getPathWithRef('/login'));
      // TIP: Commented line until profile screen is ready
      // if (getIsUserOnboarded() === 'true') navigate(getPathWithRef('/profile'));
      if (getIsUserOnboarded() === 'true') navigate(getPathWithRef('/recommendations'));
    }
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    dispatch(getQuestions());
  }, []);

  const handleContinue = () => {
    if (answer) {
      const key = questionsData[currentStep - 1].key_question;
      if (key === 'first_name') {
        trackPixel('signup');
        trackPixel('universalPixel');
        trackPixel('questions');
        setUserName(answer);
        setIsUserOnboarded('true');
      }
      dispatch(answerQuestion(key, answer));
      saveAnswer(undefined);
    }
    if (currentStep === questionsData.length) {
      setOnboardingComplete(true);
      wait(1000).then(() => navigate(getPathWithRef('/recommendations')));
    } else setCurrentStep(currentStep + 1);
  };

  if (!questionsData || questionsData?.length === 0 || onboardingComplete) {
    return (
      <div className="loader-center">
        <Loader width={60} height={60} />
      </div>
    );
  }
  return (
    <>
      <Bar />
      <Container>
        <BarContainer>
          <ProgressBar bar value={(currentStep * 100) / questionsData.length} />
        </BarContainer>
        <Column>
          <Label>
            STEP {currentStep} OF {questionsData.length}
          </Label>
          <Title>{questionsData[currentStep - 1].title}</Title>
          <Subtitle>{questionsData[currentStep - 1].subtitle}</Subtitle>
        </Column>
        <Spacing $size={32} />
        <StepFieldsDictionary question={questionsData[currentStep - 1]} saveAnswer={saveAnswer} />
        <Spacing $size={32} />
        <Button
          text="NEXT"
          onPress={handleContinue}
          backgroundColor={theme.colors.inkBlue}
          linkText="Skip question"
          onLinkPress={handleContinue}
        />
      </Container>
    </>
  );
};
