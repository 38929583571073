import Column from 'components/Column';
import { Image } from 'components/Image';
import { H3 } from 'components/Typography';
import styled from 'styled-components/macro';

export const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  @media (max-width: 800px) {
    height: 210px;
    width: 100%;
    object-fit: cover;
  }
`;

export const TextContainer = styled(Column)<{ $marginLeft?: string; $verticalPadding: string }>`
  padding: ${({ $verticalPadding }) => $verticalPadding} 20px;
  width: 55%;
  margin-left: ${({ $marginLeft }) => $marginLeft || '45%'};

  @media (max-width: 800px) {
    padding: 0;
    text-align: center;
    margin: 210px 0 20px;
    width: 100%;
  }
`;

export const Title = styled(H3)`
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 33px;
  }
`;
