import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { SiteConfigData, SiteConfigState } from 'store/siteConfig/types';

export const getSiteConfigState = (state: RootState): SiteConfigState => state.siteConfig;

export const getSiteConfigData = createSelector(
  [getSiteConfigState],
  (state: SiteConfigState): SiteConfigData => state?.data
);

//Components configuration

const getConfigBySectionAndComponent = createSelector(
  [
    (state: RootState) => state.siteConfig.data,
    (_: RootState, sectionName: string, componentName: string) => ({ sectionName, componentName }),
  ],
  (data: SiteConfigData[], { sectionName, componentName }) =>
    data?.filter(item => item.section === sectionName && item.component === componentName)[0]
      ?.details
);

export const getSitewideConfig = (state: RootState, componentName: string) =>
  getConfigBySectionAndComponent(state, 'SITEWIDE', componentName);

export const getLandingConfig = (state: RootState, componentName: string) =>
  getConfigBySectionAndComponent(state, 'LANDING', componentName);

export const getDonateConfig = (state: RootState, componentName: string) =>
  getConfigBySectionAndComponent(state, 'DONATE', componentName);

export const getProfileConfig = (state: RootState, componentName: string) =>
  getConfigBySectionAndComponent(state, 'PROFILE', componentName);
