import { FC } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { RadioFieldLabel } from './styled';

interface Props extends FieldProps<string, FieldRenderProps<any>> {
  children: React.ReactNode;
}

export const RadioField: FC<Props> = ({ children, ...fieldProps }) => {
  return (
    <RadioFieldLabel>
      {children}
      <Field component="input" type="radio" {...fieldProps} />
      <span className="checkmark"></span>
    </RadioFieldLabel>
  );
};
