import { TextField } from 'components/FinalForm/TextField';
import { CountrySelectField, StateSelectField } from 'components/FinalForm/AddressSelectFields';
import { SelectContainer, SelectLabel } from '../styled';

const INPUT_MAX_LENGTH = 100;

type PersonalDetailsProps = {
  selectedCountry: string;
};

export const PersonalDetails = ({ selectedCountry }: PersonalDetailsProps) => {
  return (
    <>
      <TextField name="first_name" label="First Name" maxLength={INPUT_MAX_LENGTH} />
      <TextField name="last_name" label="Last Name" maxLength={INPUT_MAX_LENGTH} />
      <TextField name="address1" label="Address 1" maxLength={INPUT_MAX_LENGTH} />
      <TextField name="address2" label="Address 2" maxLength={INPUT_MAX_LENGTH} />
      <TextField name="city" label="City" maxLength={INPUT_MAX_LENGTH} />
      <SelectContainer>
        <SelectLabel>State</SelectLabel>
        <StateSelectField country={selectedCountry} name="state" maxLength={INPUT_MAX_LENGTH} />
      </SelectContainer>
      <TextField name="zipcode" label="Zip" maxLength={INPUT_MAX_LENGTH} />
      <SelectContainer>
        <SelectLabel>Country</SelectLabel>
        <CountrySelectField name="country" maxLength={INPUT_MAX_LENGTH} />
      </SelectContainer>
    </>
  );
};
