export const INITIAL_STATE = {
  data: {
    existingPayments: null,
    newPaymentsById: {},
  },
  isLoading: false,
  error: null,
  saveNewPaymentIsLoading: false,
  saveNewPaymentError: null,
};
