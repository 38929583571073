import { FC } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { InputWithFloatingLabel } from 'components/Form/InputWithFloatingLabel';

interface Props extends FieldProps<HTMLInputElement, FieldRenderProps<any>> {
  label?: string;
}

export const TextFieldWithFloatingLabel: FC<Props> = ({ label, name, ...fieldProps }) => {
  return (
    <Field name={name} allowNull {...fieldProps}>
      {({ input, meta }) => (
        <InputWithFloatingLabel
          className={(meta.error || meta.submitError) && meta.touched && 'error'}
          error={meta.touched && (meta.error || meta.submitError)}
          label={label}
          {...input}
        />
      )}
    </Field>
  );
};
