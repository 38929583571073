import Column from 'components/Column';
import { Row } from 'components/Row';
import { H1Text, H4 } from 'components/Typography';
import styled from 'styled-components/macro';

export const TopContainer = styled(Column)`
  background: ${({ theme }) => theme.colors.inkBlue};
  padding: 80px 20px;
`;

export const Title = styled(H1Text)`
  color: ${({ theme }) => theme.colors.white};

  @media (max-width: 1000px) {
    font-size: 40px;
    line-height: 43px;
  }
`;

export const Description = styled(H4)`
  color: ${({ theme }) => theme.colors.white};
  max-width: 658px;
`;

export const MediaCardsContainer = styled(Row)`
  padding: 66px;
  gap: 50px;
  flex-wrap: wrap;
`;
