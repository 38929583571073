import { FC } from 'react';
import theme from 'config/theme';
import { noop } from 'helpers/utils';
import Icon, { IconNames } from '../Icon';
import { CheckBoxWrapper } from './styled';

export interface ICheckBox {
  onChecked: () => void;
  value: boolean;
  disabled?: boolean;
}

const CheckBox: FC<ICheckBox> = ({ onChecked, value, disabled }) => {
  return (
    <CheckBoxWrapper $value={value} onClick={disabled ? noop : onChecked} $disabled={disabled}>
      {value && <Icon name={IconNames.Checkmark} color={theme.colors.white} size={14} />}
    </CheckBoxWrapper>
  );
};

export default CheckBox;
