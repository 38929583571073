import { FC } from 'react';
import { FieldProps, FieldRenderProps } from 'react-final-form';
import { SelectField } from '../SelectField';
import { TextFieldWithFloatingLabel } from '../TextFieldWithFloatingLabel';
import { STATE_OPTIONS, STATE_OPTIONS_CA } from './constants';

interface Props extends FieldProps<HTMLInputElement, FieldRenderProps<any>> {
  country?: string;
  label?: string;
  labelRegion?: string;
  name: string;
}

export const getIsCountryUS = (country?: string) =>
  !country || country === 'US' || country === 'United States';

export const getIsCountryCanada = (country?: string) => country === 'CA' || country === 'Canada';

export const StateSelectField: FC<Props> = ({
  country,
  label,
  labelRegion,
  name,
  ...fieldProps
}) => {
  if (getIsCountryUS(country)) {
    return <SelectField label={label} name={name} options={STATE_OPTIONS} {...fieldProps} />;
  }
  if (getIsCountryCanada(country)) {
    return <SelectField label={label} name={name} options={STATE_OPTIONS_CA} {...fieldProps} />;
  }
  return <TextFieldWithFloatingLabel name={name} label={labelRegion} {...fieldProps} />;
};
