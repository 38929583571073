import { useState } from 'react';
import { getUrlParams } from 'helpers/utils';

export const useShareUrl = (
  userRefCode?: string,
  shareCurrentUrl?: boolean,
  selectedTags?: Array<string>
) => {
  const { urlPartner } = getUrlParams();
  const currentUrl = new URL(window.location.href);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const ref = userRefCode ? `?ref=${userRefCode}` : '';
  const partner = urlPartner ? `${ref ? '&' : '?'}p=${urlPartner}` : '';

  let tagParams = '';
  if (selectedTags) {
    selectedTags.forEach((item: string, i: number) => {
      tagParams += `${i === 0 && !ref && !partner ? '?' : '&'}tags=${item.replace(/ /g, '%20')}`;
    });
  }

  const urlWithRefCode = `${currentUrl.origin}${currentUrl.pathname}${ref}${partner}${tagParams}`;
  const baseUrlWithRefCode = `${currentUrl.origin}${ref}${partner}${tagParams}`;

  const handleShare = () => {
    navigator.clipboard.writeText(shareCurrentUrl ? urlWithRefCode : baseUrlWithRefCode);
    setIsPopupOpen(true);
    setTimeout(() => setIsPopupOpen(false), 2000);
  };

  return {
    handleShare,
    isPopupOpen,
    urlWithRefCode,
    setIsPopupOpen,
    baseUrlWithRefCode,
  };
};
