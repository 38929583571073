import styled from 'styled-components/macro';

export const TableContainer = styled.div<{ $hideForLarge?: boolean; $hideForSmall?: boolean }>`
  ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.inkBlue};
  font-size: 13px;
  line-height: 18px;
  padding: 2px;
  width: 100%;
  @media (min-width: 800px) {
    display: ${({ $hideForLarge }) => ($hideForLarge ? 'none' : 'block')};
  }
  @media (max-width: 800px) {
    display: ${({ $hideForSmall }) => ($hideForSmall ? 'none' : 'block')};
  }
`;

export const DataTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    padding: 5px 12px;
    text-align: left;
  }
  th:last-child,
  th.align-right {
    text-align: right;
  }
  thead {
    background: ${({ theme }) => theme.colors.softGray};
  }
  td {
    height: 1px;
  }
`;

export const Tbody = styled.tbody`
  tr:nth-child(even) {
    background: ${({ theme }) => theme.shadows.electricBlue(0.15)};
  }
  tr.disabled {
    background: ${({ theme }) => theme.colors.softGray};
    border-top: ${({ theme }) => `1px solid ${theme.colors.white}`};
    color: ${({ theme }) => theme.shadows.black(0.4)};
  }
  td:last-child,
  td.align-right {
    text-align: right;
  }
`;

export const Th = styled.th``;
