import Column from 'components/Column';
import { Image } from 'components/Image';
import { UtilityText } from 'components/Typography';
import styled from 'styled-components/macro';

export const Leyend = styled(UtilityText)`
  color: ${({ theme }) => theme.shadows.black(0.5)};
`;

export const StyledImage = styled(Image)`
  width: 40px;
  height: 40px;
`;

export const TopIssueWrapper = styled.div<{ $width?: string; $fullHeight?: boolean }>`
  overflow: hidden;
  border-radius: 10px;
  width: ${({ $width }) => $width || 'auto'};
  height: ${({ $fullHeight }) => ($fullHeight ? 'auto' : 'fit-content')};
  box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.shadows.black(0.08)};

  @media (max-width: 800px) {
    width: ${({ $width }) => ($width ? '100%' : 'auto')};
  }
`;

export const Container = styled.div<{ $isSmallCard?: boolean; $fullHeight?: boolean }>`
  ${({ $isSmallCard }) =>
    $isSmallCard &&
    `
      margin: auto;
      @media (min-width: 1000px) {
        max-width: 375px;
      }
    `};
  text-align: left;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};
  position: relative;
  border-width: 6px 0 0 0;
  border-color: ${({ theme }) => theme.colors.inkBlue};
  border-image-slice: 1;
  border-style: solid;
  ${({ $fullHeight }) => $fullHeight && 'height: 100%'};
`;

export const Content = styled(Column)`
  padding: 16px;
`;

export const FadeBottom = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(181.81deg, rgba(255, 255, 255, 0) 1.54%, ${theme.colors.white} 88.78%)`};
  bottom: 0;
  height: 80px;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.silverChalise};
  margin-bottom: 16px;
  border-radius: 10px;
`;
