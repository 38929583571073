import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import { Title, Description, InnerGradientBox, WhiteBox } from './styled';

export const ImpactCard = ({ tag }) => {
  return (
    <>
      <WhiteBox />
      <GradientBorderCard
        borderType="full"
        margin="50px 100px 25px 100px"
        withShadow={false}
        overflow="inherit"
        gradient={theme.gradients.fullGradient({ direction: '150deg' })}
      >
        <InnerGradientBox>
          <Title>{tag.value}</Title>
          <Spacing $size={4} />
          <Description>{tag.tag_cta || tag.desc}</Description>
          <Spacing $size={12} />
        </InnerGradientBox>
      </GradientBorderCard>
    </>
  );
};
