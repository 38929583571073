import axios from 'axios';
import { Dispatch } from 'redux';
import RECIPIENTS_TYPES, { getRecipientProps } from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getRecipientsAPI = async ({
  amount,
  tags,
  search_str,
  query_str,
}: getRecipientProps) => {
  let tag_params = '';
  let search_str_params = '';
  let query_str_params = '';
  tags?.forEach(item => {
    tag_params += `&tags=${item}`;
  });
  if (search_str) search_str_params = `&search_str=${search_str}`;
  if (query_str) query_str_params = `&query_str=${query_str}`;
  const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_RECIPIENTS(
    amount,
    tag_params,
    search_str_params,
    query_str_params
  )}`;
  try {
    const response = await axios.get(url, {
      headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
    });
    return Promise.resolve(response);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getRecipientsDetailedAPI = async ({ amount, query_str }: getRecipientProps) => {
  let query_str_params = '';
  if (query_str) query_str_params = `&query_str=${query_str}`;
  const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_RECIPIENTS_DETAILED(
    amount,
    query_str_params
  )}`;
  try {
    const response = await axios.get(url, {
      headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
    });
    return Promise.resolve(response);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getRecipients =
  ({ amount, tags, search_str, query_str }: getRecipientProps) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(RECIPIENTS_TYPES.recipientsStart());

    try {
      const response = await getRecipientsAPI({ amount, tags, search_str, query_str });
      dispatch(RECIPIENTS_TYPES.recipientsSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(RECIPIENTS_TYPES.recipientsError(error.message));
      return Promise.reject(error);
    }
  };

export const getRecipientsDetailed =
  ({ amount, query_str }: getRecipientProps) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(RECIPIENTS_TYPES.recipientsStart());

    try {
      const response = await getRecipientsDetailedAPI({ amount, query_str });
      dispatch(RECIPIENTS_TYPES.recipientsDetailedSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(RECIPIENTS_TYPES.recipientsDetailedError(error.message));
      return Promise.reject(error);
    }
  };

export const getRecipientDetailedById =
  (recipientId: number) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(RECIPIENTS_TYPES.recipientDetailedByIdStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_RECIPIENT_DETAILED_BY_ID(
      String(recipientId)
    )}`;
    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(RECIPIENTS_TYPES.recipientDetailedByIdSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(RECIPIENTS_TYPES.recipientDetailedByIdError(error.message));
      return Promise.reject(error);
    }
  };
