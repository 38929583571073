import { createActions } from 'reduxsauce';

export interface SiteConfigState {
  data: SiteConfigData;
  isLoading: boolean;
  error: string;
}

export type SiteConfigData = {
  section: string;
  component: string;
  details: any;
};

export interface SiteConfigTypes {
  SITE_CONFIG: 'SITE_CONFIG';
  SITE_CONFIG_START: 'SITE_CONFIG_START';
  SITE_CONFIG_SUCCESS: 'SITE_CONFIG_SUCCESS';
  SITE_CONFIG_ERROR: 'SITE_CONFIG_ERROR';
  SITE_CONFIG_CLEAN_UP: 'SITE_CONFIG_CLEAN_UP';
}

const { Types, Creators } = createActions<SiteConfigTypes>({
  siteConfig: ['data'],
  siteConfigStart: null,
  siteConfigSuccess: ['data'],
  siteConfigError: ['error'],
  siteConfigCleanUp: null,
});

export { Types };

export default Creators;
