import styled from 'styled-components/macro';
import Column from 'components/Column';
import { H2Text, H3 } from 'components/Typography';
import { Row } from 'components/Row';

export const Container = styled(Column)`
  padding: 70px 40px 40px;
  text-align: center;
  max-width: 1100px;
  margin: auto;
  justify-content: center;
  justify-items: center;

  @media (max-width: 800px) {
    padding: 32px 20px;
  }
`;

export const FactorContainer = styled(Column)`
  padding-top: 40px;
  margin: 0 24px;
`;

export const Subtitle = styled(H3)`
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 27px;
  }
`;

export const CirclePosition = styled.div`
  width: 30px;
  height: 30px;
  text-align: center;
  display: table;
  background: ${({ theme }) => theme.colors.oathBlue};
  margin-bottom: 8px;
  border-radius: 22px;
  justify-self: center;
`;

export const ImageContainer = styled.div`
  text-align: center;
  width: 100%;
`;

export const ImpactsContainer = styled.div`
  display: flex;

  @media (max-width: 700px) {
    display: grid;
  }
`;

export const BottomContainer = styled(Column)`
  padding: 0px 32px 70px;
  background: ${({ theme }) => theme.colors.concrete};

  @media (max-width: 800px) {
    padding: 32px 0;
  }
`;

export const TextColumn = styled(Column)`
  width: 500px;
  align-content: center;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const ImpactRow = styled(Row)<{ $isReverse?: boolean }>`
  gap: 50px;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    gap: 20px;
    flex-direction: ${({ $isReverse }) => ($isReverse ? 'column-reverse' : 'column')};
  }
`;

export const TextsContainer = styled.div<{ $isFirst?: boolean }>`
  max-width: 850px;

  @media (max-width: 1100px) {
    ${({ $isFirst }) => $isFirst && 'padding-bottom: 40px'};
  }
`;

export const Title = styled(H2Text)`
  @media (max-width: 800px) {
    font-size: 30px;
    line-height: 33px;
  }
`;
