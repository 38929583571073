import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import {
  TNetworkSummary,
  TSummary,
  TTransactions,
  TransactionsState,
} from 'store/transactions/types';

export const getTransactionsState = (state: RootState): TransactionsState => state.transactions;

export const getTransactionsData = createSelector(
  [getTransactionsState],
  (state: TransactionsState): TTransactions[] => state?.data?.transactions
);

export const getTransactionsSummary = createSelector(
  [getTransactionsState],
  (state: TransactionsState): TSummary => state?.data?.summary
);

export const getTransactionsNetworkSummary = createSelector(
  [getTransactionsState],
  (state: TransactionsState): TNetworkSummary => state?.data?.network_summary
);
