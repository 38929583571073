import Column from 'components/Column';
import { Image } from 'components/Image';
import { H2Text, H4 } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled(Column)`
  padding: 40px; 120px;

  @media (min-width: 801px) and (max-width: 1400px) {
    padding: 40px 60px;
  }
  @media (max-width: 800px) {
    padding: 32px;
  }
`;

export const Title = styled(H2Text)`
  @media (max-width: 1000px) {
    font-size: 30px;
  }
`;

export const Description = styled(H4)`
  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

export const TopIssueContainer = styled.div`
  padding: 0 16px;

  @media (max-width: 1000px) {
    padding: 16px 0;

    &:nth-child(1) {
      padding-top: 0;
    }
    &:nth-child(3) {
      padding-bottom: 0;
    }
  }
`;

export const InnerGradientBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  display: grid;
  padding: 30px 20px;
`;

export const StyledImage = styled(Image)`
  width: 40px;
  height: 40px;
`;
