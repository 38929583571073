import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const createTokenStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const createTokenSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    deTokensById: {
      ...state.data.deTokensById,
      [data.token]: data,
    },
  },
  isLoading: false,
});

export const createTokenError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const createTokenCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.CREATE_TOKEN_START]: createTokenStart,
  [Types.CREATE_TOKEN_SUCCESS]: createTokenSuccess,
  [Types.CREATE_TOKEN_ERROR]: createTokenError,
  [Types.CREATE_TOKEN_CLEAN_UP]: createTokenCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
