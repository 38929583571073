import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useAuth0 } from '@auth0/auth0-react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import Error from 'components/Error';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Body } from 'components/Typography';
import { ModalComponent } from 'components/Modal';
import { SlideStories } from 'components/SlideStories';
import { ProfileCard } from 'components/Cards/ProfileCard';
import { trackPixel } from 'helpers/pixel';
import { useAppSelector } from 'helpers/hooks';
import { getIsFeatureOn, IMPACT_REPORT } from 'helpers/featureFlags';
import { getUserState } from 'selectors/user';
import { getTagsState } from 'selectors/tags';
import { getSubscriptionsData, getSubscriptionsSummary } from 'selectors/subscriptions';
import { clearAccessToken, setIsUserOnboarded, setUserName } from 'services/storage';
import { Impact } from './Impact';
import { Network } from './Network';
import { Settings } from './Settings';
import { AccountInfo } from './AccountInfo';
import { ProfileHeader } from './ProfileHeader';
import { DonationHistory } from './DonationHistory';
import { MonthlyRecurringDonations } from './MonthlyRecurringDonations';
import { CardsContainer, LeftContent, RightContent } from './styled';

export const ProfileScreen = () => {
  const { logout } = useAuth0();
  const impactRef = useRef(null);
  const networkRef = useRef(null);
  const historyRef = useRef(null);
  const settingsRef = useRef(null);
  const [isSlideVisible, setIsSlideVisible] = useState(false);
  const [topIssues, setTopIssues] = useState(Array<string>());
  const { userState, tagsState, subscriptionsData, subscriptionsSummary } = useAppSelector(
    state => ({
      userState: getUserState(state),
      tagsState: getTagsState(state),
      subscriptionsData: getSubscriptionsData(state),
      subscriptionsSummary: getSubscriptionsSummary(state),
    })
  );
  const hasSubscriptions = !!subscriptionsData?.length;

  useEffect(() => {
    trackPixel('universalPixel');
  }, []);

  useEffect(() => {
    if (!userState.isLoading && userState.data) {
      const isOnboarded = userState.data.is_onboarded;
      setIsUserOnboarded(isOnboarded.toString());
      setUserName(userState.data?.first_name);
    }
  }, [userState]);

  const handleScroll = (place: string) => {
    let newRef: any = impactRef.current;
    if (place === 'network') newRef = networkRef.current;
    if (place === 'history') newRef = historyRef.current;
    if (place === 'settings') newRef = settingsRef.current;
    window.scrollTo({
      top: newRef.offsetTop - 200,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (!tagsState.isLoading && tagsState.data) {
      setTopIssues(
        tagsState.data.tags
          .filter(tag => userState?.data?.issues?.includes(tag.query_str))
          .map(item => item.value)
      );
    }
  }, [tagsState]);

  const handleLogout = () => {
    clearAccessToken();
    logout({ logoutParams: { returnTo: ENV_CONFIG().SITE_URL } });
  };

  return (
    <>
      <ModalComponent
        isOpen={isSlideVisible}
        closeModal={() => setIsSlideVisible(false)}
        style={{
          width: 'fit-content',
          padding: 0,
          height: 'auto',
          maxHeight: 915,
          border: 'none',
        }}
        contentLabel="SlideStories"
        withCustomStyle
        iconColor={theme.colors.white}
      >
        <SlideStories isSlideVisible={isSlideVisible} showSlides={setIsSlideVisible} />
      </ModalComponent>
      <ProfileHeader handlePress={handleScroll} userName={userState?.data?.first_name} />
      <CardsContainer>
        <LeftContent $width={'66%'}>
          <ProfileCard
            smallTitle
            title="Your 2024 Impact Summary"
            withoutPadding={getIsFeatureOn(IMPACT_REPORT)}
          >
            <div ref={impactRef} />
            <Impact viewImpactReport={() => setIsSlideVisible(true)} />
          </ProfileCard>
          <Spacing $size={40} />
          {hasSubscriptions && (
            <>
              <ProfileCard smallTitle title="Your Monthly Recurring Donations">
                <ErrorBoundary
                  fallback={
                    <Error
                      data={{ component: 'SubscriptionManagement' }}
                      errorMsg="Failure with profile SubscriptionManagement component"
                      shouldAlert={true}
                    >
                      <Body>
                        There was an error fetching your subscription history. Please contact{' '}
                        support.
                      </Body>
                    </Error>
                  }
                >
                  <MonthlyRecurringDonations
                    subscriptions={subscriptionsData}
                    totalActiveSubscriptions={subscriptionsSummary.total_active_count}
                    totalAmount={subscriptionsSummary.total_active_amount}
                  />
                </ErrorBoundary>
              </ProfileCard>
              <Spacing $size={40} />
            </>
          )}
          <ProfileCard smallTitle title="Your 2024 Network Impact Summary">
            <div ref={networkRef} />
            <Network />
          </ProfileCard>
          <Spacing $size={40} />
          <ProfileCard smallTitle title="Your Donation History">
            <div ref={historyRef} />
            <ErrorBoundary
              fallback={
                <Error
                  data={{ component: 'DonationHistory' }}
                  errorMsg="Failure with profile DonationHistory component"
                  shouldAlert={true}
                >
                  <Body>
                    There was an error fetching your donation history. Please contact support.
                  </Body>
                </Error>
              }
            >
              <DonationHistory />
            </ErrorBoundary>
          </ProfileCard>
          <Spacing $size={40} />
          <ProfileCard smallTitle title="Your Account Info & Settings">
            <div ref={settingsRef} />
            {userState.data && <AccountInfo topIssues={topIssues} />}
          </ProfileCard>
          <div>
            <Spacing $size={35} />
            <Link onClick={handleLogout} $color={theme.colors.shaft}>
              Log Out
            </Link>
          </div>
        </LeftContent>
        <Spacing $isVertical $size={64} />
        <RightContent $width={'33%'}>
          <Settings topIssues={topIssues} />
        </RightContent>
      </CardsContainer>
    </>
  );
};
