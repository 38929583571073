import { FC } from 'react';
import theme from 'config/theme';
import { noop } from 'helpers/utils';
import Icon, { IconNames } from '../Icon';
import { onChangeType, onKeyboardType } from '.';
import { IconWrapper, InputWrapper, StyledInput } from './styled';

export interface InputProps {
  placeholder?: string;
  autoCapitalize?: string;
  pattern?: string;
  value: string;
  onChange: (e: onChangeType) => void;
  onSubmit: (key: string) => void;
  id: string;
  disabled?: boolean;
}

const InputSearch: FC<InputProps> = ({
  placeholder,
  value,
  onChange,
  id,
  disabled,
  autoCapitalize,
  onSubmit,
  pattern = 'default',
}) => {
  const handleSubmit = (key: string) => {
    onSubmit(key);
  };

  return (
    <InputWrapper $disabled={disabled}>
      <StyledInput
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        id={id}
        autoCapitalize={autoCapitalize}
        pattern={pattern}
        onKeyDown={(e: onKeyboardType) => handleSubmit(e.key)}
      />
      <IconWrapper whileTap={{ scale: value ? 0.9 : 1 }}>
        <Icon
          name={IconNames.Search}
          size={24}
          color={theme.colors.governorBay}
          onClick={value ? () => handleSubmit('Enter') : noop}
        />
      </IconWrapper>
    </InputWrapper>
  );
};

export default InputSearch;
