const shadows = {
  black: (degree: number) => `rgba(0, 0, 0, ${degree})`,
  disabled: (degree: number) => `rgba(218, 218, 218, ${degree})`,
  electricBlue: (degree: number) => `rgba(124, 227, 231, ${degree})`,
  errorRed: (degree: number) => `rgba(193, 75, 11, ${degree})`,
  inkBlue: (degree: number) => `rgba(4, 5, 58, ${degree})`,
  oathBlue: (degree: number) => `rgba(20, 16, 249, ${degree})`,
  white: (degree: number) => `rgba(255, 255, 255, ${degree})`,
};

export default shadows;
