import Column from 'components/Column';
import { Body, H3 } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled(Column)`
  justify-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 160px;
`;

export const FooterContainer = styled.div`
  width: 100%;
  @media (max-width: 1000px) {
    padding-top: 160px;
  }
  @media (min-width: 1000px) {
    position: absolute;
    bottom: 0;
  }
`;

export const Title = styled(H3)`
  color: ${({ theme }) => theme.colors.black};
`;

export const Description = styled(Body)`
  ${({ theme }) => theme.fonts.regular};
`;
