import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import Spacing from 'components/Spacing';
import { Body, Eyebrow, H4 } from 'components/Typography';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { ButtonBrand } from 'components/ButtonBrand';
import {
  BottomContainer,
  CircledImage,
  Container,
  Content,
  Label,
  RowContent,
  StyledImage,
  StyledRow,
  Subtitle,
  Title,
  TopContainer,
} from './styled';
import { Row } from 'components/Row';
import Column from 'components/Column';

export const ImpactReportScreen = () => {
  return (
    <>
      <Header hasIssues />
      <TopContainer>
        <Content>
          <Title>Oath 2024 Annual Impact Report</Title>
          <Spacing $size={16} />
          <H4 $color={theme.colors.white}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore.
          </H4>
        </Content>
      </TopContainer>
      <Container>
        <Content $centered>
          <H4 $color={theme.colors.white}>
            Intro to impact report enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Take a look at how we made
            a difference.
          </H4>
        </Content>
        <Spacing $size={60} />
        <StyledRow $justifyContent="space-between" $alignItems="center" $isReverse>
          <RowContent>
            <Subtitle>What We Accomplished in 2024</Subtitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur.
            </H4>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="45%">
                <Subtitle $panelFont>$24 Million</Subtitle>
                <Body $color={theme.colors.white}>Total amount raised in since January 2024</Body>
              </Column>
              <Column $width="50%">
                <Subtitle $panelFont>$105 Million</Subtitle>
                <Body $color={theme.colors.white}>The total amount raised had 4.4x the impact</Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="45%">
                <Subtitle $panelFont>4,561</Subtitle>
                <Body $color={theme.colors.white}>Total number of candidates funded</Body>
              </Column>
              <Column $width="50%">
                <Subtitle $panelFont>XX%</Subtitle>
                <Body $color={theme.colors.white}>Of candidates funded who won their races</Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="45%">
                <Subtitle $panelFont>3.7%</Subtitle>
                <Body $color={theme.colors.white}>Average margin in all races funded by Oath</Body>
              </Column>
              <Column $width="50%">
                <Subtitle $panelFont>XX</Subtitle>
                <Body $color={theme.colors.white}>ROI</Body>
              </Column>
            </Row>
          </RowContent>
          <StyledImage
            $end
            alt="Impact Report 1"
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/1.png`}
          />
        </StyledRow>
        <Spacing $size={60} />
        <StyledRow $justifyContent="space-between" $alignItems="center">
          <StyledImage
            alt="Impact Report 2"
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/2.png`}
          />
          <RowContent>
            <Subtitle>Oath’s Impact</Subtitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.
            </H4>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="45%">
                <Subtitle $panelFont>XX</Subtitle>
                <Body $color={theme.colors.white}>Total amount seats flipped</Body>
              </Column>
              <Column $width="50%">
                <Subtitle $panelFont>XX</Subtitle>
                <Body $color={theme.colors.white}>Total amount seats held</Body>
              </Column>
            </Row>
            <Spacing $size={20} />
            <Row $justifyContent="space-between">
              <Column $width="45%">
                <Subtitle $panelFont>10X</Subtitle>
                <Body $color={theme.colors.white}>
                  Funds had the biggest impact in this [state] race
                </Body>
              </Column>
              <Column $width="50%">
                <Subtitle $panelFont>X.X%</Subtitle>
                <Body $color={theme.colors.white}>
                  The xx race in [state] won by this margin. So close!
                </Body>
              </Column>
            </Row>
          </RowContent>
        </StyledRow>
        <Spacing $size={60} />
        <StyledRow $justifyContent="space-between" $isReverse $alignItems="flex-start">
          <RowContent>
            <Subtitle>The Breakdown</Subtitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat.
            </H4>
            <Spacing $size={20} />
            <Eyebrow $color={theme.colors.white}>Top Funded Issue</Eyebrow>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Defeat Trump was our top funded issue voluptatem quia voluptas sit aspernatur aut odit
              aut fugit, eos qui ratione voluptatem sequi.
            </H4>
            <Spacing $size={10} />
            <H4 $color={theme.colors.white}>
              The other top issues can be seen in the chart to the right orro quisquam dolore magnam
              aliquam quaerat voluptatem.
            </H4>
          </RowContent>
          <RowContent $fitSize>
            <StyledRow $fitSize>
              <Content $alignContent="flex-start" $gap="8px">
                <Row $width="max-content" $alignItems="center">
                  <CircledImage
                    alt="issue_1"
                    src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/4.png`}
                  />
                  <Spacing $size={8} $isVertical />
                  <Label>XX%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>Protecting Democracy</Body>
                </Row>
                <Row $width="max-content" $alignItems="center">
                  <CircledImage
                    alt="issue_1"
                    src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/4.png`}
                  />
                  <Spacing $size={8} $isVertical />
                  <Label>XX%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>Flip the US House</Body>
                </Row>
                <Row $width="max-content" $alignItems="center">
                  <CircledImage
                    alt="issue_1"
                    src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/4.png`}
                  />
                  <Spacing $size={8} $isVertical />
                  <Label>XX%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>Reproductive Rights</Body>
                </Row>
                <Row $width="max-content" $alignItems="center">
                  <CircledImage
                    alt="issue_1"
                    src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/4.png`}
                  />
                  <Spacing $size={8} $isVertical />
                  <Label>XX%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>Climate Change</Body>
                </Row>
                <Row $width="max-content" $alignItems="center">
                  <CircledImage
                    alt="issue_1"
                    src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/4.png`}
                  />
                  <Spacing $size={8} $isVertical />
                  <Label>XX%</Label>
                  <Spacing $size={8} $isVertical />
                  <Body $color={theme.colors.white}>Gun Safety</Body>
                </Row>
              </Content>
              <StyledImage
                $hideOnMobile
                alt="Impact Report 3"
                src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/3.png`}
              />
            </StyledRow>
          </RowContent>
        </StyledRow>
        <Spacing $size={60} />
        <StyledRow $justifyContent="space-between" $alignItems="center">
          <StyledImage
            alt="Impact Report 4"
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_report/4.png`}
          />
          <RowContent>
            <Subtitle>Looking Forward</Subtitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse.
            </H4>
            <Spacing $size={20} />
            <Eyebrow $color={theme.colors.white}>This is what’s next:</Eyebrow>
            <Spacing $size={8} />
            <Body $color={theme.colors.white}>
              <ul style={{ paddingInlineStart: 16 }}>
                <li>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut.</li>
                <Spacing $size={8} />
                <li>
                  Sel aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                  voluptate velit esse.
                </li>
                <Spacing $size={8} />
                <li>
                  Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed
                  quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                </li>
                <Spacing $size={8} />
                <li>Quis autem vel eum iure reprehenderit qui in ea voluptat.</li>
              </ul>
            </Body>
          </RowContent>
        </StyledRow>
      </Container>
      <BottomContainer>
        <Content $centered>
          <Subtitle>Oath 2024 Annual Impact Report</Subtitle>
          <Spacing $size={16} />
          <H4 $color={theme.colors.white}>
            Curious about what impact you made this year? Sign in to see your personalized Impact
            Report.
          </H4>
          <Spacing $size={16} />
          <H4 $color={theme.colors.white}>
            If you don’t have an Oath account, sign up now to get a personalized Impact Report next
            year.
          </H4>
          <Spacing $size={24} />
          <StyledRow $justifyContent="space-between">
            <ButtonBrand to="/profile" $secondary>
              Sign in to your account
            </ButtonBrand>
            <ButtonBrand to="/login" $secondary>
              Sign up for next year
            </ButtonBrand>
          </StyledRow>
        </Content>
      </BottomContainer>
      <Footer />
    </>
  );
};
