import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const impactReportStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const impactReportSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const impactReportError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const impactReportCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.IMPACT_REPORT_START]: impactReportStart,
  [Types.IMPACT_REPORT_SUCCESS]: impactReportSuccess,
  [Types.IMPACT_REPORT_ERROR]: impactReportError,
  [Types.IMPACT_REPORT_CLEAN_UP]: impactReportCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
