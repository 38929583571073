import { FC, useEffect, useState } from 'react';
import Input, { onChangeTextareaType } from 'components/Input';
import { Container, ItemTitle, ItemContainer } from './styled';

interface Props {
  options: IItem[];
  isMultiple?: boolean;
  optionalDetail?: boolean;
  saveAnswer: (value) => void;
}
interface IItem {
  key: string;
  label: string;
}

export const ListItems: FC<Props> = ({ options, isMultiple, optionalDetail, saveAnswer }) => {
  const [itemsSelected, setItemsSelected] = useState(Array<IItem>());
  const [optionalValue, setOptionalValue] = useState('');

  const checkIfItemIsSelected = (value: string) => {
    return itemsSelected.filter(sel => sel.key === value).length > 0;
  };

  const handlePress = (item: IItem) => {
    if (isMultiple) {
      if (item.key === '*') setItemsSelected([item]);
      else setItemsSelected(itemsSelected.filter(sel => sel.key !== '*'));

      if (checkIfItemIsSelected(item.key) && item.key !== '*') {
        setItemsSelected(itemsSelected.filter(sel => sel.key !== item.key));
      } else setItemsSelected(prev => [...prev, item]);
    } else {
      setItemsSelected([item]);
    }
  };

  useEffect(() => {
    if (itemsSelected.length > 0) {
      if (isMultiple) saveAnswer(itemsSelected.map(item => item.key).toString());
      else saveAnswer(itemsSelected[0].key);
    }
  }, [itemsSelected]);

  return (
    <Container>
      {options.map((item, index) => (
        <ItemContainer
          key={index}
          whileTap={{ scale: 0.95 }}
          onClick={() => handlePress(item)}
          $isSelected={checkIfItemIsSelected(item.key)}
        >
          <ItemTitle $isSelected={checkIfItemIsSelected(item.key)}>{item.label}</ItemTitle>
        </ItemContainer>
      ))}
      {optionalDetail && checkIfItemIsSelected('*') && (
        <Input
          id="optionalValue"
          placeholder="Can you tell us a litte more? (Optional)"
          textareaOnChange={(e: onChangeTextareaType) => setOptionalValue(e.target.value)}
          value={optionalValue}
          textarea
        />
      )}
    </Container>
  );
};
