import { FC, useEffect } from 'react';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import theme from 'config/theme';
import { CounterCharacter, CounterNumber, CounterRow } from './styled';
import { formatNumberAsCurrency } from 'helpers/utils';

interface Props {
  duration: number;
  from: number;
  number: number;
}

export const Counter: FC<Props> = ({ duration = 0.5, from, number }) => {
  const count = useMotionValue(from);
  const rounded = useTransform(count, latest => formatNumberAsCurrency(Math.round(latest)));

  useEffect(() => {
    const controls = animate(count, number, { duration: duration });
    return controls.stop;
  }, [number]);

  return (
    <CounterRow $alignItems="center" $justifyContent="center">
      <CounterNumber>
        <CounterCharacter $color={theme.colors.oathBlue} $fontType="regular">
          <motion.span>{rounded}</motion.span>
        </CounterCharacter>
      </CounterNumber>
    </CounterRow>
  );
};
