import { Image } from 'components/Image';
import styled from 'styled-components/macro';

export const StyledImage = styled(Image)`
  width: 280px;
  justify-self: center;
  padding-bottom: 16px;

  @media (min-width: 800px) and (max-width: 1200px) {
    width: 200px;
  }
  @media (max-width: 800px) {
    width: 280px;
  }
`;
