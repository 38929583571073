export const defaultTrendingIssues = [
  {
    id: 1,
    name: 'Max Impact',
    redirect_url: '/donate?p=mx',
    raised: '',
    fundraising_goal: '',
    cta: 'Support Now',
  },
];
