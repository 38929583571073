import styled from 'styled-components/macro';
import { Row } from 'components/Row';
import Column from 'components/Column';
import { H1Text, H2Text } from 'components/Typography';

export const Title = styled(H2Text)`
  @media (max-width: 800px) {
    font-size: 25px;
    line-height: 33px;
  }
`;

export const WhiteHeader = styled(Row)`
  padding: 12px;
  gap: 14px;
  text-wrap: nowrap;
  background: ${({ theme }) => theme.colors.white};

  @media (max-width: 800px) {
    padding: 12px 20px;
  }
`;

export const ButtonsContainer = styled(Column)`
  padding: 20px 40px;
  background: ${({ theme }) => theme.colors.softGray};
  gap: 16px;

  @media (max-width: 800px) {
    padding: 20px 16px;
  }
`;

export const CounterContainer = styled.div`
  border-radius: 5px;
  box-shadow: ${({ theme }) => `0px 0px 8px 0px ${theme.shadows.black(0.15)}`};
  padding: 4px 10px;
`;

export const CounterNumber = styled(H1Text)`
  color: ${({ theme }) => theme.colors.oathBlue};

  @media (max-width: 800px) {
    font-size: 40px;
    line-height: 44px;
  }
`;
