import colors from './colors';

interface GradientProps {
  direction?: string;
  p1?: string;
  p2?: string;
  p3?: string;
  p4?: string;
  p5?: string;
  p6?: string;
}
const gradients = {
  fullGradient: ({ direction = '90deg', p1 = '19%', p2 = '46%', p3 = '71%' }: GradientProps) =>
    `${direction}, ${colors.electricBlue} ${p1}, ${colors.darkGreen} ${p2}, ${colors.oathBlue} ${p3}`,
  oathToElectricGradient: ({ direction = '240deg', p1 = '0%', p2 = '100%' }: GradientProps) =>
    `${direction}, ${colors.oathBlue} ${p1}, ${colors.electricBlue} ${p2}`,
  oathToIncGradient: ({ direction = '180deg', p1 = '95%', p2 = '0%' }: GradientProps) =>
    `${direction}, ${colors.oathBlue} ${p1}, ${colors.inkBlue} ${p2}`,
  allBlueGradient: ({ direction = '180deg', p1 = '98%', p2 = '50%', p3 = '0%' }: GradientProps) =>
    `${direction}, ${colors.electricBlue} ${p1}, ${colors.oathBlue} ${p2}, ${colors.inkBlue} ${p3}`,
  referralGradient: ({ direction = '180deg', p1 = '100%', p2 = '0%' }: GradientProps) =>
    `${direction}, ${colors.darkGreen} ${p1}, ${colors.electricBlue} ${p2}`,
  inkToDarkGradient: ({ direction = '90deg', p1 = '5%', p2 = '60%', p3 = '95%' }: GradientProps) =>
    `${direction}, ${colors.inkBlue} ${p1}, ${colors.electricBlue} ${p2}, ${colors.darkGreen} ${p3}`,
  electricToDarkGradient: ({ direction = '90deg', p1 = '0%', p2 = '90.9%' }: GradientProps) =>
    `${direction}, ${colors.electricBlue} ${p1}, ${colors.darkGreen} ${p2}`,
  lgbtqGradient: ({
    direction = '180deg',
    p1 = '100%',
    p2 = '81%',
    p3 = '62%',
    p4 = '41%',
    p5 = '20%',
    p6 = '0%',
  }: GradientProps) =>
    `${direction}, ${colors.mauvelous} ${p1}, ${colors.rajah} ${p2}, ${colors.sweetCorn} ${p3}, ${colors.algaeGreen} ${p4}, ${colors.cornFlower} ${p5}, ${colors.lavender} ${p6}`,
};

export default gradients;
