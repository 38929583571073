import { FC } from 'react';
import SpinnerSvg from '../../assets/images/spinner.svg';
import StyledSpinnerImage from './styled';

interface ISpinner {
  height?: number;
  width?: number;
}

const Loader: FC<ISpinner> = ({ height, width }) => {
  return <StyledSpinnerImage src={SpinnerSvg} alt="waiting" $height={height} $width={width} />;
};

export default Loader;
