import { FC, useEffect } from 'react';
import { Field, useField } from 'react-final-form';
import Spacing from 'components/Spacing';
import { Body, BodySmall, UtilityText } from 'components/Typography';
import { TextFieldWithFloatingLabel } from 'components/FinalForm/TextFieldWithFloatingLabel';
import { EmploymentDetails } from './Employment';
import { checkoutLogFirebaseEvent } from './utils';
import theme from 'config/theme';
import { DetailsColumn, DetailsRow, NameValidationError } from '../styled';
import { getUserState } from 'selectors/user';
import { useAppSelector } from 'helpers/hooks';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { CountrySelectField, StateSelectField } from 'components/FinalForm/AddressSelectFields';
import { LoginCTA } from './LoginCTA';
import {
  individualOnlyWarningFull,
  individualOnlyWarningShort,
  required,
  validateNameCheckout,
} from 'helpers/validation';

interface Props {
  candidates: CandidateType[];
  donationAmount: number;
  isEmployerAddressRequired: boolean;
}

export const Details: FC<Props> = ({ candidates, donationAmount, isEmployerAddressRequired }) => {
  const {
    input: { value: country },
  } = useField('country', { subscription: { value: true } });
  const {
    meta: { error: firstNameError },
  } = useField('first_name', { subscription: { error: true } });
  const {
    meta: { error: lastNameError },
  } = useField('last_name', { subscription: { error: true } });
  const userState = useAppSelector(getUserState);

  useEffect(() => {
    checkoutLogFirebaseEvent({
      eventName: 'view_step_details',
      userId: userState?.data?.id || '',
      candidates,
      donationAmount,
    });
  }, []);

  return (
    <>
      <Body>Personal Details</Body>
      {(firstNameError === individualOnlyWarningShort ||
        lastNameError === individualOnlyWarningShort) && (
        <NameValidationError>
          <BodySmall $color={theme.colors.errorRed}>{individualOnlyWarningFull}</BodySmall>
        </NameValidationError>
      )}
      <DetailsColumn>
        {userState?.data?.email ? (
          <Field name="email" component="input" hidden />
        ) : (
          <>
            <TextFieldWithFloatingLabel name="email" label="Email" validate={required} />
            <LoginCTA candidates={candidates} donationAmount={donationAmount} />
          </>
        )}
        <DetailsRow>
          <TextFieldWithFloatingLabel
            label="First Name"
            name="first_name"
            validate={validateNameCheckout}
          />
          <TextFieldWithFloatingLabel
            name="last_name"
            label="Last Name"
            validate={validateNameCheckout}
          />
        </DetailsRow>
        <TextFieldWithFloatingLabel name="address1" label="Address 1" validate={required} />
        <TextFieldWithFloatingLabel name="address2" label="Address 2" />
        <DetailsRow>
          <TextFieldWithFloatingLabel name="city" label="City" validate={required} />
          <StateSelectField
            country={country}
            label="State"
            labelRegion="Region"
            name="state"
            validate={required}
          />
        </DetailsRow>
        <DetailsRow>
          <TextFieldWithFloatingLabel name="zipcode" label="Zip" validate={required} />
          <CountrySelectField label="Country" name="country" />
        </DetailsRow>
      </DetailsColumn>
      <UtilityText $color={theme.shadows.black(0.6)}>
        *All fields are required unless otherwise noted.
      </UtilityText>

      <Spacing $size={15} />

      <EmploymentDetails isEmployerAddressRequired={isEmployerAddressRequired} />

      <Spacing $size={24} />
    </>
  );
};
