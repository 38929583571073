import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

export const InputWrapper = styled.div<{ $disabled?: boolean; $price?: boolean }>`
  display: flex;
  align-items: center;
  ${({ $price, theme, $disabled }) =>
    $price
      ? `
    top: 0;
    bottom: 0;
    right: 16px;
    left: 16px;
    justify-content: center;
  `
      : `
    background: ${$disabled ? theme.colors.alto : theme.colors.softGray};
    border: none;
    border-radius: 5px;
    padding: 12px 16px;
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25);
  `}
`;

export const StyledInput = styled.input<{ $price?: boolean }>`
  border: none;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 0.15px;
  outline: 0px;
  ${({ $price, theme }) => ($price ? theme.fonts.semiBold : theme.fonts.regular)};
  ${({ $price, theme }) =>
    $price
      ? `margin: 32px 0; text-align: center; font-size: 45px; border-bottom: 1px solid ${theme.colors.black};`
      : `background: ${theme.colors.concrete}; font-size: 12px;`}

  ::placeholder {
    color: ${({ theme }) => theme.colors.black};
  }

  ::-webkit-search-cancel-button {
    /* Remove default cancel button that appears for Chrome */
    -webkit-appearance: none;
  }
`;

export const IconWrapper = styled(motion.div)`
  align-items: center;
  flex: 1;
  justify-content: center;
  padding-left: 8px;
`;

export const Wrapper = styled.div<{ $fullWidth?: boolean }>`
  display: flex;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.inkBlue};
  border-radius: 5px;
  padding: 12px 16px;
  ${({ $fullWidth }) => $fullWidth && 'width: -webkit-fill-available'};
`;

export const StyledTextarea = styled.textarea`
  border: none;
  min-width: 100%;
  color: ${({ theme }) => theme.colors.inkBlue};
  letter-spacing: 0.15px;
  outline: 0px;
  ${({ theme }) => theme.fonts.regular};
  min-height: 100px;
  line-height: 20px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.inkBlue};
  }
`;

export const StyledText = styled.input`
  border: none;
  min-width: 100%;
  color: ${({ theme }) => theme.colors.inkBlue};
  letter-spacing: 0.15px;
  outline: 0px;
  background: ${({ theme }) => theme.colors.transparent};
  ${({ theme }) => theme.fonts.regular};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  ::placeholder {
    color: ${({ theme }) => theme.colors.inkBlue};
  }
`;
