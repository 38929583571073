import styled from 'styled-components/macro';

export const InnerGradientBox = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  margin: 2px;
  padding: 32px 120px;

  @media (max-width: 700px) {
    padding: 32px 80px;
  }
  @media (max-width: 500px) {
    padding: 32px;
  }
`;
