import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { BodySmall } from 'components/Typography';

export const MotionContainer = styled(motion.div)<{ $selected?: boolean; $lastone?: boolean }>`
  padding: 8px 0 8px 8px;
  cursor: pointer;
  ${({ $selected, theme }) => $selected && `background: ${theme.colors.white}`};
  ${({ $selected, $lastone }) =>
    $selected &&
    $lastone &&
    `
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    `};
`;

export const Label = styled(BodySmall)<{ $selected?: boolean }>`
  padding-left: 8px;
  color: ${({ theme, $selected }) => ($selected ? theme.colors.inkBlue : theme.colors.white)};
`;
