import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const payStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const paySuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const payError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const payCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.PAY_START]: payStart,
  [Types.PAY_SUCCESS]: paySuccess,
  [Types.PAY_ERROR]: payError,
  [Types.PAY_CLEAN_UP]: payCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
