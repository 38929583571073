import { FC, useEffect, useRef, useState } from 'react';
import theme from 'config/theme';
import Loader from 'components/Loader';
import Button from 'components/Button';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { ListTags } from 'components/ListTags';
import { ModalComponent } from 'components/Modal';
import { InputSearch, onChangeType } from 'components/Input';
import { CandidateCard } from 'components/Cards/CandidateCard';
import { getUrlParams } from 'helpers/utils';
import { getSelectedCandidates } from 'helpers/donate';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { Donate } from 'screens/Donate';
import { getAllTags } from 'selectors/tags';
import { getRecipientsState } from 'selectors/recipients';
import { clearDonationSelections, getSessionID } from 'services/storage';
import { ITag } from 'store/tags/types';
import { IRecipient } from 'store/recipients/types';
import { getRecipients } from 'store/recipients/actions';
import { ButtonContainer, Container, InputContainer, Label, Content } from 'screens/Main/styled';
import { CandidateType } from './Candidates/Candidates';
import { ContributionRules } from './ContributionRules';
import { Toast } from 'components/Toast';

interface Props {
  allTags: ITag[];
  candidates: CandidateType[];
  onSelectCandidates: (updatedCandidates: CandidateType[]) => void;
  recipients: IRecipient[];
  selectedDonationAmount?: number;
  onDonateClick: () => void;
  onUserDonationTotalChange: (value?: number) => void;
  refWidthValue: number;
  setCandidates: React.Dispatch<React.SetStateAction<CandidateType[]>>;
  setSelectedDonationAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  showCustomSplit: boolean;
}

export const ListSlate: FC<Props> = ({
  candidates,
  onDonateClick,
  onSelectCandidates,
  onUserDonationTotalChange,
  refWidthValue,
  selectedDonationAmount,
  setCandidates,
  setSelectedDonationAmount,
  showCustomSplit,
}) => {
  const { urlPartner } = getUrlParams();
  const ref = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [clearTags, setClearTags] = useState(false);
  const [isDonateAmountModalOpen, setIsDonateAmountModalOpen] = useState(false);
  const [tagsDynSearch, setTagsDynSearch] = useState(Array<string>());
  const [buttonWithShadow, setButtonWithShadow] = useState(true);
  const dispatch = useAppDispatch();
  const { recipients, allTags } = useAppSelector(state => ({
    recipients: getRecipientsState(state),
    allTags: getAllTags(state),
  }));

  useEffect(() => {
    const element = ref.current;
    if (element) {
      const observer = new IntersectionObserver(([entry]) => {
        setButtonWithShadow(!entry.isIntersecting);
      });
      observer.observe(element);
    }
  }, []);

  const handleSubmit = (key: string) => {
    clearDonationSelections();
    if (key === 'Enter') {
      dispatch(getRecipients({ amount: 6, search_str: searchValue }));
      setClearTags(true);
    } else if (key === 'Backspace' && searchValue.length === 1) {
      dispatch(getRecipients({ amount: 6 }));
      setClearTags(false);
    }
  };

  const clearInput = () => {
    setSearchValue('');
    setClearTags(false);
  };

  const handleCandidatesSelected = (index, checkValue: boolean) => {
    const updatedCandidates = [...candidates];
    updatedCandidates[index] = {
      ...candidates[index],
      isSelected: checkValue,
    };
    onSelectCandidates(updatedCandidates);
    logFirebaseEvent(checkValue ? 'land_select_recipient' : 'land_unselect_recipient', {
      timestamp: new Date().toString(),
      recipient_id: candidates[index].de_id,
      session_id: getSessionID(),
    });
  };

  const handleDonate = () => {
    setIsDonateAmountModalOpen(true);
  };

  const closeDonateAmountModal = () => {
    setIsDonateAmountModalOpen(false);
  };

  return (
    <Content>
      <Toast />
      <ModalComponent
        isOpen={isDonateAmountModalOpen}
        closeModal={closeDonateAmountModal}
        contentLabel="Donate amount modal"
        isFullScreen={true}
      >
        <Donate
          candidates={candidates}
          selectedTags={tagsDynSearch}
          onDonateClick={onDonateClick}
          selectedDonationAmount={selectedDonationAmount}
          setCandidates={setCandidates}
          setSelectedDonationAmount={setSelectedDonationAmount}
          showCustomSplit={showCustomSplit}
          onUserDonationTotalChange={onUserDonationTotalChange}
        />
      </ModalComponent>
      {!urlPartner && (
        <InputContainer>
          <InputSearch
            id="searchValue"
            placeholder="Search by candidates, issues, or states..."
            onChange={(e: onChangeType) => setSearchValue(e.target.value)}
            value={searchValue}
            autoCapitalize="words"
            onSubmit={handleSubmit}
          />
        </InputContainer>
      )}
      <ListTags
        setSelectedTagsDynSearch={setTagsDynSearch}
        nameDynSearch={searchValue}
        clearTags={clearTags}
        urlPartner={urlPartner}
        refWidthValue={refWidthValue}
        clearInput={clearInput}
        allTags={allTags}
      />
      <Container $backgroundColor={theme.colors.softGray}>
        {recipients.isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <Loader width={60} height={60} />
          </div>
        ) : recipients?.data?.recipients?.length === 0 ? (
          <Column>
            <Label>
              Unfortunately your search returned no results. Oath currently includes select
              candidates running in 2023 or 2024 elections who are not in a competitive primary. To
              submit a candidate for consideration,{' '}
              <Label
                $isUnderlined
                onClick={() =>
                  window.open('https://airtable.com/appPQgaEeAQXJmjVx/shrzkMbzZsnYN7lLl')
                }
              >
                click here
              </Label>
              .
            </Label>
            <Spacing $size={16} />
          </Column>
        ) : (
          candidates.map((item, i) => (
            <CandidateCard
              key={i}
              index={i}
              item={item}
              selectItem={handleCandidatesSelected}
              impactScoreVisibility={
                (urlPartner && allTags?.length > 0 && allTags[0].impact_score_visibility) || false
              }
            />
          ))
        )}
        <Spacing $size={16} />
        <ButtonContainer>
          <Button
            text="DONATE NOW"
            onPress={handleDonate}
            disabled={getSelectedCandidates(candidates).length === 0}
            amount={getSelectedCandidates(candidates).length}
            totalRecipients={candidates.length}
            withRecipients
            withShadow={buttonWithShadow}
            backgroundColor={theme.colors.oathBlue}
          />
        </ButtonContainer>
        <div ref={ref} />
        <Spacing $size={16} />
        <ContributionRules />
      </Container>
    </Content>
  );
};
