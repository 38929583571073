import { FC, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import { Body, H4 } from 'components/Typography';
import Icon, { IconNames } from 'components/Icon';
import { useAppSelector } from 'helpers/hooks';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { getFaqsData } from 'selectors/faqs';
import { getSessionID } from 'services/storage';
import { FaqsData } from 'store/faqs/types';
import {
  Accordion,
  Container,
  MotionContainer,
  IconContainer,
  CircleNumber,
  Number,
  Title,
} from './styled';

interface Props {
  title?: string;
  align?: string;
  isNumbered?: boolean;
}

export const FAQs: FC<Props> = ({ align, title, isNumbered }) => {
  const [itemVisible, setItemVisible] = useState<number>(0);
  const faqsData = useAppSelector(getFaqsData);

  const handleClick = (item: number) => {
    logFirebaseEvent('faq_monthly', {
      timestamp: new Date().toString(),
      session_id: getSessionID(),
    });
    setItemVisible(prevItemVisible => (prevItemVisible === item ? -1 : item));
  };

  return (
    <Container $align={align}>
      <Title>{title || 'Oath FAQs'}</Title>
      <Spacing $size={20} />
      {faqsData?.map((item: FaqsData, i: number) => (
        <Accordion key={i}>
          <Row $justifyContent="space-between" $alignItems="center" onClick={() => handleClick(i)}>
            {isNumbered ? (
              <Row>
                <CircleNumber $selected={itemVisible === i}>
                  <Number>{i + 1}</Number>
                </CircleNumber>
                <Spacing $isVertical $size={8} />
                <H4 $color={itemVisible === i ? theme.colors.oathBlue : theme.colors.inkBlue}>
                  {item.question}
                </H4>
              </Row>
            ) : (
              <H4>{item.question}</H4>
            )}
            <IconContainer>
              <Icon
                name={itemVisible === i ? IconNames.Minus : IconNames.Plus}
                size={20}
                color={
                  itemVisible === i && isNumbered ? theme.colors.oathBlue : theme.colors.inkBlue
                }
              />
            </IconContainer>
          </Row>
          <AnimatePresence>
            {itemVisible === i && (
              <MotionContainer
                initial={{ opacity: 0, y: '-100%' }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <Body dangerouslySetInnerHTML={{ __html: item.answer }} />
              </MotionContainer>
            )}
          </AnimatePresence>
        </Accordion>
      ))}
    </Container>
  );
};
