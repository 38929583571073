import Column from 'components/Column';
import styled from 'styled-components/macro';

export const Container = styled(Column)`
  padding: 40px;
  text-align: center;
  max-width: 1100px;
  margin: auto;

  @media (max-width: 800px) {
    padding: 32px 20px;
  }
`;
