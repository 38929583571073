import { FC, ReactNode } from 'react';
import { TextContainer, Container, Content, Header, Title } from './styled';

interface Props {
  title: string;
  align?: string;
  children: ReactNode;
  isInverted?: boolean;
  smallTitle?: boolean;
  withoutPadding?: boolean;
}

export const ProfileCard: FC<Props> = ({
  title,
  children,
  isInverted,
  align,
  smallTitle,
  withoutPadding,
}) => {
  return (
    <Container>
      <Header $isInverted={isInverted}>
        <TextContainer $align={align}>
          <Title $smallTitle={smallTitle} $isInverted={isInverted}>
            {title}
          </Title>
        </TextContainer>
      </Header>
      <Content $withoutPadding={withoutPadding}>{children}</Content>
    </Container>
  );
};
