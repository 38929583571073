const API_ROUTES = {
  ANSWER_QUESTION: '/form/questions/response',
  CANCEL_SUBSCRIPTION: (de_gid: string) => `/user/subscription/${de_gid}/cancel`,
  GET_FAQS: (type: string) => `/faqs/?faq_type=${type}`,
  GET_IMPACT_REPORT: '/user/impact_report/2024',
  GET_RECOMMENDATIONS: '/user/recommendations',
  GET_GALLERY_ITEMS: '/media',
  GET_TRANSACTIONS: (user_ref_code: string) =>
    `/user/transactions/?user_ref_code=${encodeURIComponent(user_ref_code)}`,
  GET_QUESTIONS: '/form/questions',
  GET_RECIPIENTS: (amount: number, tags?: string, search_str?: string, query_str?: string) =>
    `/recipients?amount=${amount}${tags}${search_str}${query_str}`,
  GET_RECIPIENTS_DETAILED: (amount: number, query_str: string) =>
    `/recipients/detailed?amount=${amount}${query_str}`,
  GET_RECIPIENT_DETAILED_BY_ID: (recipientId: string) => `/recipient/${recipientId}/detailed`,
  GET_SETS: (query_str?: string) => `/sets/${query_str}`,
  GET_SITE_CONFIG: (section?: string) => `/component_config?${section}`,
  GET_SUBSCRIPTIONS: '/user/subscriptions',
  GET_TAGS: (query_str?: string) => `/tags${query_str}`,
  FUNDING_GAP: '/funding_gap',
  PAY: '/pay',
  PAYMENTS: '/payments',
  USER_LOGIN: '/user/login',
  USER_PROFILE: '/user/profile',
};

export default API_ROUTES;
