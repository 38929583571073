import axios from 'axios';
import { Dispatch } from 'redux';
import { ENV_CONFIG } from 'config/environment';
import { getPayAuthToken } from 'store/pay/actions';
import { PayAuthTokenResponse } from 'store/pay/types';
import DE_TYPES, { DETokenRequestData } from './types';
import { REQUEST_AUTH_TOKEN_ERROR, TOKEN_AUTH_INCOMPLETE } from './constants';
import { datadogLogs } from '@datadog/browser-logs';
import { getMessageFromAxiosError } from 'helpers/errors';
import { noop } from 'helpers/utils';

export const postDEDonationWithToken =
  (body: DETokenRequestData) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(DE_TYPES.createTokenStart());

    try {
      let payAuthTokenResponse: PayAuthTokenResponse;
      try {
        payAuthTokenResponse = (await getPayAuthToken()).data as PayAuthTokenResponse;
      } catch (error: any) {
        datadogLogs.logger.warn(
          'Error occured getting authentication token GET /pay/auth-token',
          {},
          new Error(getMessageFromAxiosError(error))
        );
        return Promise.reject(REQUEST_AUTH_TOKEN_ERROR);
      }
      const url = `${ENV_CONFIG().DEMOCRACY_ENGINE.DONATE_API_BASE_URL}/subscribers/${
        payAuthTokenResponse.api_subscriber_id
      }/donations.json`;
      body.donation.gid = payAuthTokenResponse.donation_gid;
      const response = await axios.post(url, body, {
        headers: { Authorization: `Bearer ${payAuthTokenResponse.authentication_token}` },
      });
      if (!response.data.token) {
        dispatch(DE_TYPES.createTokenError(TOKEN_AUTH_INCOMPLETE));
        return Promise.reject(TOKEN_AUTH_INCOMPLETE);
      }
      dispatch(DE_TYPES.createTokenSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(DE_TYPES.createTokenError(error.message));
      try {
        datadogLogs.logger.warn(
          'Error occured getting payment authentication token from democracy engine POST /donations.json',
          {},
          new Error(getMessageFromAxiosError(error))
        );
      } catch (e) {
        noop();
      }
      return Promise.reject(error);
    }
  };
