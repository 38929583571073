import { FC } from 'react';
import theme from 'config/theme';
import { getPathWithRef } from 'helpers/utils';
import { Container, BannerText, TextWrapper } from './styled';

export interface IBanner {
  text: string;
  link?: string;
  background?: string;
  textColor?: string;
}

const Banner: FC<IBanner> = ({ text, link, background, textColor }) => {
  const navigateToLink = () => {
    if (!link) {
      return;
    }
    window.location.href = getPathWithRef(link);
  };

  return (
    <Container
      $background={background}
      onClick={navigateToLink}
      role="button"
      $hasLink={!!link}
      tabIndex={0}
    >
      <BannerText>
        {Array.from({ length: 50 }, (_, index) => (
          <TextWrapper key={index} $color={textColor || theme.colors.inkBlue}>
            {text}
          </TextWrapper>
        ))}
      </BannerText>
    </Container>
  );
};

export default Banner;
