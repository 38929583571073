import styled from 'styled-components/macro';
import Column from 'components/Column';
import { Progress } from 'reactstrap';
import { Body, Eyebrow, H3 } from 'components/Typography';

export const Container = styled(Column)`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  max-width: 675px;
  margin: auto;
  padding: 32px 16px;

  @media (max-width: 700px) {
    max-width: 400px;
  }
`;

export const ProgressBar = styled(Progress)`
  background: ${({ theme }) => theme.colors.oathBlue};
  border-radius: 8px;
  box-shadow: none;
  height: 8px;
`;

export const BarContainer = styled.div`
  background: ${({ theme }) => theme.colors.concrete};
  border-radius: 8px;
`;

export const Label = styled(Eyebrow)`
  color: ${({ theme }) => theme.colors.inkBlue};
  padding-top: 32px;
`;

export const Title = styled(H3)`
  color: ${({ theme }) => theme.colors.inkBlue};
  ${({ theme }) => theme.fonts.light};
  padding: 8px 0;
`;

export const Subtitle = styled(Body)`
  color: ${({ theme }) => theme.colors.inkBlue};
  ${({ theme }) => theme.fonts.light};
`;
