import Column from 'components/Column';
import { Body } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled(Column)`
  padding: 16px;
  text-align: center;
  max-width: 1000px;
  margin: auto;
`;

export const Description = styled(Body)`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.fonts.light};
  line-height: 24px;
`;
