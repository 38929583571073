import { FC, useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import theme from 'config/theme';
import Input, { onChangeType } from 'components/Input';
import { Container, InputContainer, Tooltip, TooltipValue, Triangle } from './styled';
import { formatNumberAsCurrency } from 'helpers/utils';

const closestMultipleOf5 = (number: number) => {
  return Math.round(number / 5) * 5;
};

interface Props {
  options: IItem[];
  saveAnswer?: (value) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  value?: number;
}
interface IItem {
  key: number;
  label: string;
}

export const CustomSlider: FC<Props> = ({ options, saveAnswer, disabled, value, fullWidth }) => {
  const [marks, setMarks] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [valueReached, setValueReached] = useState(0);

  useEffect(() => {
    setValueReached(value || 0);
  }, [value]);

  useEffect(() => {
    let newArray = {};
    options.forEach(option => {
      newArray = {
        ...newArray,
        [option.key]: {
          style: {
            fontSize: 11,
            color: theme.colors.inkBlue,
            fontFamily: 'DMSans-Regular',
            paddingTop: 4,
          },
          label: option.label,
        },
      };
    });
    setMarks(newArray);
  }, []);

  useEffect(() => {
    if (!disabled && saveAnswer) {
      if (valueReached === options[options.length - 1].key) saveAnswer(inputValue);
      else saveAnswer(valueReached.toString());
    }
  }, [inputValue, valueReached]);

  const onSliderChange = (newValue: number | number[]) => {
    if (typeof newValue === 'number' && !disabled) {
      const finalValue = closestMultipleOf5(Math.abs(newValue));
      setValueReached(finalValue);
    }
  };

  return (
    <Container $fullWidth={fullWidth}>
      <Slider
        min={0}
        max={options[options.length - 1].key}
        count={5}
        marks={marks}
        dotStyle={{ visibility: 'hidden' }}
        onChange={onSliderChange}
        value={value}
        styles={{
          rail: { height: 10 },
          track: {
            background: `linear-gradient(to right, ${theme.colors.oathBlue}, ${theme.colors.darkGreen}, ${theme.colors.electricBlue})`,
            height: 10,
          },
          handle: {
            backgroundColor: theme.colors.oathBlue,
            border: `4px solid ${theme.colors.white}`,
            outline: `1px solid ${theme.colors.oathBlue}`,
            padding: 5,
            ...(!!disabled && { cursor: 'auto' }),
          },
        }}
        handleRender={renderProps => {
          return (
            <div {...renderProps.props}>
              <Tooltip>
                <TooltipValue>{formatNumberAsCurrency(valueReached)}</TooltipValue>
              </Tooltip>
              <Triangle />
            </div>
          );
        }}
      />
      {valueReached === options[options.length - 1].key && !disabled && (
        <InputContainer>
          <Input
            id="valueReached"
            type="number"
            placeholder="Enter amount here"
            value={inputValue}
            textOnChange={(e: onChangeType) => setInputValue(e.target.value.toString())}
          />
        </InputContainer>
      )}
    </Container>
  );
};
