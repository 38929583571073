import { FC } from 'react';
import { FieldProps, FieldRenderProps } from 'react-final-form';
import { SelectField } from '../SelectField';
import { COUNTRY_OPTIONS } from './constants';

interface Props extends FieldProps<HTMLInputElement, FieldRenderProps<any>> {
  label?: string;
  name: string;
}

export const CountrySelectField: FC<Props> = ({ label, name, ...fieldProps }) => {
  return <SelectField label={label} name={name} options={COUNTRY_OPTIONS} {...fieldProps} />;
};
