import styled from 'styled-components/macro';

export const InputWithFloatingLabelContainer = styled.div`
  color: ${({ theme }) => theme.colors.black};
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.fonts.regular}
  label {
    color: ${({ theme }) => theme.colors.black};
    position: absolute;
    pointer-events: none;
    transform: translate(0, 14.5px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-size: 13px;
    line-height: 18px;
    left: 12px;
  }
  &.active label,
  &:focus-within label {
    color: ${({ theme }) => theme.colors.oathBlue};
    font-size: 11px;
    letter-spacing: 0.03em;
    line-height: 16px;
    opacity: 1 !important;
    transform: translate(0, 6px) scale(1);
  }
  &.filled label {
    font-size: 11px;
    letter-spacing: 0.03em;
    line-height: 16px;
    opacity: 0.5;
    transform: translate(0, 6px) scale(1);
  }
  &.filled .input,
  &.filled input {
    border-color: ${({ theme }) => theme.colors.inkBlue};
  }
  .input,
  input {
    ${({ theme }) => theme.fonts.regular}
    border-radius: 5px;
    border: ${({ theme }) => `1px solid ${theme.colors.platinum}`};
    color: ${({ theme }) => theme.colors.inkBlue};
    padding: 12px;
    font-size: 13px;
    line-height: 18px;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  &.withLabel .input,
  &.withLabel input {
    padding: 20px 12px 7px;
  }
  &.active .input,
  .input:focus,
  input:focus {
    border: ${({ theme }) => `1px solid ${theme.colors.oathBlue}`};
  }
  &.error {
    color: ${({ theme }) => theme.colors.errorRed};
    label {
      color: ${({ theme }) => theme.colors.errorRed};
    }
    &.filled .input,
    &.filled input {
      border-color: ${({ theme }) => theme.colors.errorRed};
    }
    .input,
    input {
      background: ${({ theme }) => theme.shadows.errorRed(0.2)};
      border: ${({ theme }) => `1px solid ${theme.colors.errorRed}`};
      color: ${({ theme }) => theme.colors.errorRed};
    }
  }
`;
