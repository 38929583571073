import styled from 'styled-components/macro';

// TODO: add hover style
// TODO: use framer motion for tap event
const ButtonLink = styled.button<{ $color?: string }>`
  text-decoration: underline;
  background: none;
  color: ${({ theme, $color }) => $color || theme.colors.shaft};
  border: none;
  font-size: 13px;
  line-height: 18px;
  padding: 0;
  cursor: pointer;
  outline: inherit;
  &:hover {
    color: ${({ theme }) => theme.colors.electricBlue};
  }
`;

export default ButtonLink;
