import { createActions } from 'reduxsauce';

export interface GalleryState {
  data: GalleryData[];
  isLoading: boolean;
  error: string;
}

export type GalleryData = {
  media_id: string;
  published_date: string;
  title: string;
  summary: string;
  url: string;
  gallery_type: string;
};

export interface GalleryTypes {
  GALLERY: 'GALLERY';
  GALLERY_START: 'GALLERY_START';
  GALLERY_SUCCESS: 'GALLERY_SUCCESS';
  GALLERY_ERROR: 'GALLERY_ERROR';
  GALLERY_CLEAN_UP: 'GALLERY_CLEAN_UP';
}

const { Types, Creators } = createActions<GalleryTypes>({
  gallery: ['data'],
  galleryStart: null,
  gallerySuccess: ['data'],
  galleryError: ['error'],
  galleryCleanUp: null,
});

export { Types };

export default Creators;
