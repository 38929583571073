import { AxiosError } from 'axios';
import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { FORM_ERROR } from 'final-form';
import { useAppSelector } from 'helpers/hooks';
import { getNewPaymentsById, getPaymentsData } from 'selectors/payments';
import { getRefValue, getSessionID } from 'services/storage';
import { PaymentsData } from 'store/payments/types';
import { IUserData } from 'store/user/types';
import { PayRequestData, PayResponseData, PaymentMethod } from 'store/pay/types';
import { ENV_CONFIG } from 'config/environment';
import {
  getIsRetiredOrUnemployed,
  getUrlParams,
  isKeyInObject,
  roundNumberToHundredths,
} from 'helpers/utils';
import { getSelectedCandidates } from 'helpers/donate';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { ITag } from 'store/tags/types';
import { useField } from 'react-final-form';
import { getUserState } from 'selectors/user';

const DE_STAGING_ACTIVE_RECIPIENTS = [
  'Oath API927410000',
  'Oath API927610000',
  'Oath API927710000',
  'Oath API927810000',
];

const ENVIRONMENT_VARS = {
  OATH_TIP_DE_RECIPIENT_ID: {
    STAGING: 'Oath API927510000',
    PRODUCTION: 'a65076e2-2bdb-49e4-8462-120159d62ec6',
  },
};

const getRecipientId = (candidate: CandidateType, index: number) => {
  if (ENV_CONFIG().MODE === 'PRODUCTION') {
    return candidate.de_api_id;
  }
  // If not production, use one of four active recipients in DE staging
  const onesDigit = parseInt(index.toString().split('').pop() as string);
  if (onesDigit < 2) {
    return DE_STAGING_ACTIVE_RECIPIENTS[0];
  }
  if (onesDigit < 4) {
    return DE_STAGING_ACTIVE_RECIPIENTS[1];
  }
  if (onesDigit < 8) {
    return DE_STAGING_ACTIVE_RECIPIENTS[2];
  }
  return DE_STAGING_ACTIVE_RECIPIENTS[3];
};

const getOathTippingId = () => {
  return ENVIRONMENT_VARS.OATH_TIP_DE_RECIPIENT_ID[ENV_CONFIG().MODE];
};

export interface InitialValuesInterface extends IUserData {
  payment_type: string;
  default_token: string;
  tip: number;
}

const isPaymentCreditCard = (payment: PaymentsData) =>
  payment.payment_authorization_display_name.includes('Credit Card');

export const usePaymentOptions = () => {
  const tokenField = useField('default_token').input.value;
  const { newPaymentsById, payments, userState } = useAppSelector(state => ({
    newPaymentsById: getNewPaymentsById(state),
    payments: getPaymentsData(state),
    userState: getUserState(state),
  }));

  const ccPayments: PaymentsData[] = [];
  const achPayments: PaymentsData[] = [];

  payments &&
    payments.forEach(payment => {
      if (isPaymentCreditCard(payment)) {
        ccPayments.push(payment);
      } else {
        achPayments.push(payment);
      }
    });

  if (
    !userState.data &&
    tokenField &&
    tokenField !== 'cc_token' &&
    tokenField !== 'cc_token' &&
    isKeyInObject(tokenField, newPaymentsById)
  ) {
    const savedPayment = newPaymentsById[tokenField];
    if (isPaymentCreditCard(savedPayment)) {
      ccPayments.push(savedPayment);
    } else {
      achPayments.push(savedPayment);
    }
  }

  return { ccPayments, achPayments };
};

export const getInitialValuesFromApiData = (
  userApiData: IUserData | undefined | object = {},
  paymentsApiData: PaymentsData[] | [] = [],
  donationAmount: number
) => {
  const defaultPayment = paymentsApiData && paymentsApiData[0];
  return {
    ...userApiData,
    payment_type: defaultPayment && !isPaymentCreditCard(defaultPayment) ? 'ach' : 'credit_card',
    default_token: defaultPayment && defaultPayment.payment_authorization_token,
    tip: getDefaultTipAmount(Number(donationAmount)),
  };
};

const getPaymentTokenFromLastPayment = (
  payResponseData: PayResponseData,
  paymentsApiData: PaymentsData[] | [] = [],
  newPaymentsById?: {
    [token: string]: PaymentsData;
  }
) => {
  if (
    paymentsApiData?.find(
      payment => payment.payment_authorization_token === payResponseData.token
    ) ||
    isKeyInObject(payResponseData.token || '', newPaymentsById || {})
  ) {
    return payResponseData.token;
  }
  if (!paymentsApiData?.length) {
    return null;
  }
  return payResponseData.payment_method === 'credit_card' ? 'cc_token' : 'ach_token';
};

export const getInitialValuesFromLastPayment = (
  payResponseData: PayResponseData,
  paymentsApiData: PaymentsData[] | [] = [],
  donationAmount: number,
  userApiData?: IUserData,
  newPaymentsById?: {
    [token: string]: PaymentsData;
  }
) => {
  const prevTipAmountStr = payResponseData.line_items.find(
    item => item.recipient_id === getOathTippingId()
  )?.amount;
  let tipAmount = getDefaultTipAmount(Number(donationAmount));
  if (prevTipAmountStr) {
    tipAmount = roundNumberToHundredths(parseFloat(prevTipAmountStr.replaceAll('$', '')));
  }
  return {
    first_name: payResponseData.donor_first_name,
    last_name: payResponseData.donor_last_name,
    address1: payResponseData.donor_address1,
    address2: payResponseData.donor_address2,
    city: payResponseData.donor_city,
    state: payResponseData.donor_state,
    zipcode: payResponseData.donor_zip,
    employer: payResponseData.compliance_employer,
    employer_address1:
      payResponseData.compliance_employer_address1 || userApiData?.employer_address1,
    employer_address2:
      payResponseData.compliance_employer_address2 || userApiData?.employer_address2,
    employer_city: payResponseData.compliance_employer_city || userApiData?.employer_city,
    employer_country: payResponseData.compliance_employer_country || userApiData?.employer_country,
    employer_state: payResponseData.compliance_employer_state || userApiData?.employer_state,
    employer_zipcode: payResponseData.compliance_employer_zip || userApiData?.employer_zipcode,
    occupation: payResponseData.compliance_occupation,
    email: payResponseData.donor_email,
    payment_type: payResponseData.payment_method,
    default_token: getPaymentTokenFromLastPayment(
      payResponseData,
      paymentsApiData,
      newPaymentsById
    ),
    tip: tipAmount,
  };
};

const getPartnerAsRefCode = (tags: ITag[]) => {
  const { urlPartner, urlTags } = getUrlParams();
  if (urlPartner) {
    return urlPartner;
  } else if (urlTags?.length) {
    const tagsQueryStrs = urlTags.map(urlTag => tags.find(tag => tag.value === urlTag)?.query_str);
    return `search-${tagsQueryStrs.join('-')}`;
  } else {
    return '';
  }
};

export const getPayRequestBody = (
  formValues,
  candidates: CandidateType[],
  tags: ITag[],
  isMonthly = false,
  payTokenId: string | null
) => {
  const requestBody = {
    payment_authorization_token: formValues.default_token,
    payment_token_id: payTokenId,
    line_items: candidates.map((candidate, index) => ({
      amount: (candidate.donationAmount || 0).toString(),
      recipient_id: getRecipientId(candidate, index),
    })),
    donor_last_name: formValues.last_name,
    donor_first_name: formValues.first_name,
    donor_zip: formValues.zipcode,
    donor_address1: formValues.address1,
    donor_address2: formValues.address2,
    donor_city: formValues.city,
    donor_state: formValues.state,
    donor_country_code: formValues.country,
    source_code: getUrlParams().urlRef || getRefValue() || '',
    ref_code: getPartnerAsRefCode(tags),
    donor_email: formValues.email,
    compliance_employer: formValues.employer,
    compliance_occupation: formValues.occupation,
    cc_last_name: formValues.last_name,
    cc_first_name: formValues.first_name,
    payment_method: formValues.payment_type,
    client_id: tags[0].client_id,
    tag_id: tags.length === 1 ? tags[0].id : null,
  } as PayRequestData;

  if (Number(formValues.tip)) {
    requestBody.line_items.push({
      amount: formValues.tip.toString(),
      recipient_id: getOathTippingId(),
    });
  }

  if (getIsEmployerAddressRequired(candidates, formValues.employer, formValues.occupation)) {
    requestBody.compliance_employer_address1 = formValues.employer_address1;
    requestBody.compliance_employer_address2 = formValues.employer_address2;
    requestBody.compliance_employer_city = formValues.employer_city;
    requestBody.compliance_employer_state = formValues.employer_state;
    requestBody.compliance_employer_zip = formValues.employer_zipcode;
    requestBody.compliance_employer_country = formValues.employer_country;
  }

  if (isMonthly) {
    requestBody.subscription_request = {
      type: 'monthly',
      ...(getUrlParams().urlPartner !== 'monthly-infra' && { expires_after: '20241231' }),
    };
  }

  return requestBody;
};

export const getCcExpObj = (expDate: string) => ({
  month: expDate.split('/')[0],
  year: `20${expDate.split('/')[1].slice(-2)}`,
});

export const getDEPayRequestBody = formValues => {
  const requestBody = {
    donation: {
      gid: '',
      donor_first_name: formValues.first_name,
      donor_last_name: formValues.last_name,
      donor_address1: formValues.address1,
      donor_city: formValues.city,
      donor_state: formValues.state,
      donor_zip: formValues.zipcode,
      donor_country_code: formValues.country,
      donor_email: formValues.email,
      payment_method: formValues.payment_type,
      ...(formValues.payment_type === 'credit_card' && {
        cc_number: formValues.cc_number,
        cc_month: getCcExpObj(formValues.cc_exp).month,
        cc_year: getCcExpObj(formValues.cc_exp).year,
        cc_verification_value: formValues.cc_verification_value,
      }),
      ...(formValues.payment_type === 'ach' && {
        ach_account_type: formValues.ach_account_type,
        ach_routing_number: formValues.ach_routing_number,
        ach_account_number: formValues.ach_account_number,
      }),
      cc_first_name: formValues.first_name,
      cc_last_name: formValues.last_name,
      authtest_request: true,
      line_items: null,
      token_request: true,
    },
  };

  return requestBody;
};

export const getTipFromPayResponse = (payResponse: PayResponseData) => {
  const tipItem = payResponse.line_items.find(item => item.recipient_id === getOathTippingId());
  if (!tipItem) {
    return 0;
  }
  return Number(tipItem.amount.replace('$', ''));
};

export const getDonationTotalFromPayResponse = (payResponse: PayResponseData) =>
  payResponse.line_items.reduce(
    (total, item) => total + Number(item.amount.replaceAll(/[$,]/g, '')),
    0
  );

export const getFormErrors = (err: AxiosError) => {
  try {
    // @ts-ignore:next-line
    let errorMsg = err.response?.data?.detail?.map(detailItem => detailItem[1]).join('. ');
    // @ts-ignore:next-line
    errorMsg = `${err.response?.data?.error}. ${errorMsg}`;
    return { [FORM_ERROR]: errorMsg || err.message };
  } catch (e) {
    return { [FORM_ERROR]: err.message || 'Donation failed' };
  }
};

const defaultTips = [{ percentage: 0.05 }, { percentage: 0.1 }, { percentage: 0.15 }];
const highDollarTips = [{ percentage: 0.01 }, { percentage: 0.03 }, { percentage: 0.05 }];
const lowDollarTips = [
  { amount: 1, percentage: null },
  { amount: 3, percentage: null },
  { amount: 5, percentage: null },
];

export const roundTwoDigits = (num: number) => Math.floor(num * 100) / 100;

export const getTipOptions = (donationAmount: number) => {
  let tips = defaultTips;
  if (donationAmount < 20) {
    return lowDollarTips;
  }
  if (donationAmount > 5000) {
    tips = highDollarTips;
  }

  return tips.map(tip => ({
    percentage: tip.percentage,
    amount: roundTwoDigits(tip.percentage * donationAmount),
  }));
};

export const getDefaultTipAmount = (donationAmount: number) => {
  return getTipOptions(donationAmount)[1].amount;
};

export const getInitialActivePageIndex = (
  initialValues: InitialValuesInterface,
  candidates: CandidateType[]
) => {
  let initialActivePageIndex = 0;
  const {
    email,
    first_name: firstName,
    last_name: lastName,
    address1: address,
    city: city,
    state,
    zipcode,
    employer,
    occupation,
    payment_type: paymentType,
    default_token: paymentToken,
    employer_address1: employerAddress1,
    employer_city: employerCity,
    employer_state: employerState,
    employer_zipcode: employerZip,
  } = initialValues;
  if (
    email &&
    firstName &&
    lastName &&
    address &&
    city &&
    state &&
    zipcode &&
    employer &&
    occupation &&
    (!getIsEmployerAddressRequired(candidates, employer, occupation) ||
      (employerAddress1 && employerCity && employerState && employerZip))
  ) {
    initialActivePageIndex = 1;
  }
  if (
    paymentToken &&
    paymentToken !== 'cc_token' &&
    paymentToken !== 'ach_token' &&
    paymentType &&
    initialActivePageIndex === 1
  ) {
    initialActivePageIndex = 2;
  }
  return initialActivePageIndex;
};

export const getProgressBarWidth = (activePageIndex: number) => {
  if (activePageIndex === 0) {
    return '50%';
  }
  if (activePageIndex === 1) {
    return '75%';
  }
  return '100%';
};

export const getIsEmployerAddressRequiredByCandidates = (candidates: CandidateType[]) => {
  return !!candidates.find(candidate => candidate.employer_address);
};

export const getIsEmployerAddressRequired = (
  candidates: CandidateType[],
  employer: string,
  occupation: string
) => {
  return (
    getIsEmployerAddressRequiredByCandidates(candidates) &&
    !getIsRetiredOrUnemployed(employer, occupation)
  );
};

interface FirebaseParams {
  eventName: string;
  userId: string;
  candidates: CandidateType[];
  donationAmount: number;
}

export const checkoutLogFirebaseEvent = ({
  eventName,
  userId,
  candidates,
  donationAmount,
}: FirebaseParams) => {
  try {
    const { urlPartner, urlRef } = getUrlParams();

    logFirebaseEvent(`checkout_${eventName}`, {
      timestamp: new Date().toString(),
      user_id: userId,
      session_id: getSessionID(),
      total_amount: donationAmount,
      recipient_ids: getSelectedCandidates(candidates)
        .map(candidate => `${candidate.recipient_id}:${candidate.donationAmount}`)
        .join(','),
      ref_code: urlRef,
      page: urlPartner,
    });
  } catch (e) {
    return;
  }
};

export const removeRepeatedItems = (items: Array<any>) => {
  return items.reduce(function (a, b) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);
};

// payment utils

export const getValidateFn =
  (validate: (value: any) => void | string, error?: string) => (value: any) => {
    if (error) {
      return error;
    }
    return validate(value);
  };

export const getIsCcNumberAmex = (ccNumber: string) =>
  ccNumber.startsWith('34') || ccNumber.startsWith('37') || /^[*]{11}[0-9]{4}$/.test(ccNumber);

export const validateCcNumber = (ccNumber: any = '') => {
  const ccRegex = new RegExp(`^[0-9]{${getIsCcNumberAmex(ccNumber) ? 15 : 16}}$`);
  const isValid = ccRegex.test(ccNumber) || /^[*]{11,12}[0-9]{4}$/.test(ccNumber);
  return isValid ? undefined : 'Credit card number invalid';
};

export const validateCcExp = (exp: any = '') => {
  const [month = '', year = ''] = exp.split('/');
  const isValid = /^[0-9]{2}$/.test(month) && /^[0-9]{2,4}$/.test(year) && year.length !== 3;
  return isValid ? undefined : 'Invalid';
};

export const validateCVV = (cvv: any = '') => {
  const isValid = /^[0-9]{3}$/.test(cvv) || /^[*]{3}$/.test(cvv);
  return isValid ? undefined : 'CVV invalid';
};

export const validateCVVAmex = (cvv: any = '') => {
  const isValid = /^[0-9]{4}$/.test(cvv) || /^[*]{4}$/.test(cvv);
  return isValid ? undefined : 'CVV invalid';
};

export const validateRoutingNumber = (routingNumber: any = '') => {
  const isValid = /^[0-9]{9}$/.test(routingNumber) || /^[*]{9}$/.test(routingNumber);
  return isValid ? undefined : 'Please enter a valid routing number';
};

export const validateAccountNumber = (accountNumber: any = '') => {
  const isValid = /^[0-9]{5,17}$/.test(accountNumber) || /^[*]{12}[0-9]{4}$/.test(accountNumber);
  return isValid ? undefined : 'Please enter a valid account number';
};

export const formatCcExpiration = (value: any = '') => {
  if (value.length === 3 && value[2] !== '/') {
    return `${value.slice(0, 2)}/${value.slice(-1)}`;
  }
  return value;
};

export const getNewCcToken = (
  token: string,
  paymentType: PaymentMethod,
  ccPayments: PaymentsData[]
) => {
  if (
    paymentType === 'ach' ||
    ccPayments.find(payment => payment.payment_authorization_token === token)
  ) {
    return 'cc_token';
  }
  return token;
};

export const getNewAchToken = (
  token: string,
  paymentType: PaymentMethod,
  achPayments: PaymentsData[]
) => {
  if (
    paymentType === 'credit_card' ||
    achPayments.find(payment => payment.payment_authorization_token === token)
  ) {
    return 'ach_token';
  }
  return token;
};

export const paymentErrorsDefaultCC = {
  cc_number: undefined as undefined | string,
  cc_exp: undefined as undefined | string,
  cc_verification_value: undefined as undefined | string,
  ccForm: undefined as undefined | string,
};

export const paymentErrorsDefaultAch = {
  ach: undefined as undefined | string,
  achForm: undefined as undefined | string,
};

export const paymentErrorsDefault = {
  ...paymentErrorsDefaultCC,
  ...paymentErrorsDefaultAch,
};

export const paymentErrorTypes = {
  'Year is not a valid year': 'cc_exp',
  'Card number is not a valid credit card number': 'cc_number',
  'Card number is not recognized as a valid brand': 'cc_number',
  'Year card is expired': 'cc_exp',
  'It is possible you have entered an invalid ba': 'ach',
};

export const getPaymentErrorFieldName = (error: string) => {
  if (Object.keys(paymentErrorTypes).includes(error)) {
    return paymentErrorTypes[error];
  }
  return '';
};

export const getIsDataValid = (hasValidationErrors: boolean, errors = {}) => {
  return (
    !hasValidationErrors ||
    (Object.keys(errors).length === 1 && isKeyInObject('default_token', errors))
  );
};

export const getIsCheckoutMonthly = () => {
  const { urlPartner } = getUrlParams();
  const regex = /^monthly-/;
  return !!urlPartner && regex.test(urlPartner);
};
