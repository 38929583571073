import Spacing from 'components/Spacing';
import { H3 } from 'components/Typography';
import { ButtonBrand } from 'components/ButtonBrand';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import { getPathWithRef } from 'helpers/utils';
import {
  GetRecommendationsAbsolute,
  GetRecommendationsContainer,
  InnerGradientBox,
} from './styled';

export const Recommendations = () => {
  return (
    <GetRecommendationsContainer>
      <GetRecommendationsAbsolute>
        <GradientBorderCard borderType="full" width="875px">
          <InnerGradientBox>
            <H3>Make an impact and join Oath now!</H3>
            <Spacing $size={16} />
            <ButtonBrand href={getPathWithRef('/login')} target="_blank">
              GET YOUR RECOMMENDATION
            </ButtonBrand>
          </InnerGradientBox>
        </GradientBorderCard>
      </GetRecommendationsAbsolute>
    </GetRecommendationsContainer>
  );
};
