import Column from 'components/Column';
import { Image } from 'components/Image';
import styled from 'styled-components/macro';

export const Container = styled(Column)`
  background: ${({ theme }) => theme.colors.softGray};
  border-radius: 8px;
  padding: 25px;
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};
  width: 365px;
  height: fit-content;
  align-content: flex-start;

  @media (max-width: 900px) {
    min-width: fit-content;
  }
`;

export const StyledImage = styled(Image)`
  width: auto;
  height: 30px;
`;
