import { getUrlParams } from 'helpers/utils';
import { HrContribution, RulesContainer } from './styled';

export const ContributionRules = () => {
  const { urlPartner } = getUrlParams();

  return (
    <RulesContainer>
      <HrContribution />
      {urlPartner?.includes('nonprofit') || urlPartner?.startsWith('np') ? (
        <>
          <div>
            All organizations on this page are non-profits and therefore these donations are
            tax-deductible. You will be emailed a receipt for your records.
          </div>
          <p>By proceeding with this transaction, you agree to Oath's terms & conditions.</p>
        </>
      ) : (
        <>
          <div>
            Oath does not share your contact information with any candidates or political parties.
          </div>
          <h2>Contribution Rules</h2>
          <ol>
            <li>
              I attest that I meet all necessary contribution rules and criteria in all relevant
              federal, state and local jurisdictions.
            </li>
            <li>
              I am a U.S. citizen or lawfully admitted permanent resident (i.e., green card holder).
              <ol type="a">
                <li>
                  <i>Applicable to Ohio state or local candidates or committees only</i>: I am a
                  U.S. citizen or U.S. national. [Contributions by individuals with permanent
                  resident status in the U.S. (green card holders) are prohibited to Ohio state or
                  local candidates or committees.]
                </li>
              </ol>
            </li>
            <li>
              This contribution is made from my own funds, and funds are not being provided to me by
              another person or entity for the purpose of making this contribution.
            </li>
            <li>I am at least eighteen years old.</li>
            <li>I am not a federal contractor.</li>
            <li>
              I am making this contribution with my own personal credit card and not with a
              corporate or business credit card or a card issued to another person.
            </li>
          </ol>
          <div>
            <p>By proceeding with this transaction, you agree to Oath's terms & conditions.</p>
          </div>
        </>
      )}
    </RulesContainer>
  );
};
