import { ENV_CONFIG } from 'config/environment';
import { CandidateImage as CandidateImageStyled, CandidateImagePlaceholder } from '../styled';

type CandidateImageProps = {
  recipientId: string | number;
};

export const CandidateImage = ({ recipientId }: CandidateImageProps) => {
  return (
    <CandidateImageStyled
      alt="Recipient"
      src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/recipients/rec_${recipientId}.jpg`}
      fallbackPlaceholder={<CandidateImagePlaceholder />}
    />
  );
};
