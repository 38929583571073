import { CandidateType } from 'components/Slate/Candidates/Candidates';
import { showToast } from 'components/Toast';
import { ENV_CONFIG } from 'config/environment';
import {
  formatNumberAsCurrency,
  getUrlParams,
  getUrlSearchParamsObj,
  getUrlSearchParamsStr,
  roundNumberToHundredths,
  splitAmountRounded,
} from 'helpers/utils';
import {
  clearDonationSelections,
  getDonationSelections,
  getRefValue,
  getSessionID,
  setDonationSelections,
} from 'services/storage';
import { IRecipient } from 'store/recipients/types';
import logFirebaseEvent from './logFirebaseEvent';
import { ProcessingRedirectOptions } from 'store/tags/constants';
import { ITag } from 'store/tags/types';

export const MIN_DONATION_PER_CANDIDATE = 1;
export const MAX_DONATION = 500000;

export const getMinDonationAmount = numCandidates => numCandidates * MIN_DONATION_PER_CANDIDATE;
export const getMaxDonationAmount = () => MAX_DONATION;

export const getMaxDonationAmountContributionLimits = (selectedCandidates: IRecipient[]) => {
  if (selectedCandidates.find(candidate => !candidate.contribution_limit)) {
    // if any candidate has no contribution limit, do not limit total donation amount
    return null;
  }
  return selectedCandidates.reduce((maxDonation, candidate) => {
    return maxDonation + Number(candidate.contribution_limit);
  }, 0);
};

export const validateDonation = (
  selectedCandidates: IRecipient[],
  selectedDonationAmount: number | undefined
) => {
  let isValid = true;
  const numSelectedCandidates = selectedCandidates.length;
  const maxDonationWithLimits = getMaxDonationAmountContributionLimits(selectedCandidates);
  if (numSelectedCandidates <= 0) {
    showToast('Please select at least one candidate', 'error');
    isValid = false;
  }
  if (!selectedDonationAmount) {
    showToast('Please select a donation amount', 'error');
    isValid = false;
  } else if (selectedDonationAmount < getMinDonationAmount(numSelectedCandidates)) {
    showToast(
      `Donation amount must be greater than ${formatNumberAsCurrency(
        MIN_DONATION_PER_CANDIDATE
      )} per candidate.`,
      'error'
    );
    isValid = false;
  } else if (selectedDonationAmount > getMaxDonationAmount()) {
    showToast(
      `Donation amount must be less than ${formatNumberAsCurrency(MAX_DONATION)}.`,
      'error'
    );
    isValid = false;
  } else if (maxDonationWithLimits && selectedDonationAmount > maxDonationWithLimits) {
    showToast(
      `Donation amount must be less than ${formatNumberAsCurrency(
        maxDonationWithLimits
      )} due to election contribution limits.`,
      'error'
    );
    isValid = false;
  }
  return isValid;
};

export const engageLabsCheckoutRedirect = (candidates, amount) => {
  const candidatesExternalIds = candidates.map(candidate => candidate.external_id);
  const partnerParams = getUrlParams()?.urlPartner ? `&p=${getUrlParams()?.urlPartner}` : '';
  const ref = getRefValue() ? `&ref=${getRefValue()}` : '';
  const splits = splitAmountRounded(amount, candidates.length);
  const rec = candidatesExternalIds
    .map((candidate, index) => `${candidate}:${splits[index]}`)
    .join(';');

  window.location.href = `${
    ENV_CONFIG().ENGAGE_URL
  }?rec=${rec}${ref}&s=${getSessionID()}${partnerParams}`;
};

export const getDemocracyEngineCheckoutUrl = (
  candidates,
  amount,
  monthlyKey?: string,
  searchTags?: string[]
) => {
  let partnerParams = '';
  // Check if urlPartner exists and append it to partnerParams
  const urlPartner = getUrlParams()?.urlPartner;
  if (urlPartner) {
    partnerParams = `&utm_term=${urlPartner}`;
  } else {
    // If urlPartner doesn't exist, check if searchTags exist and append each tag to partnerParams
    if (searchTags && searchTags.length > 0) {
      partnerParams += '&utm_term=search';
      searchTags.forEach((tag: string) => {
        partnerParams += `-${tag}`;
      });
    }
  }
  const ref = getUrlParams()?.urlRef ? `&sc=${getUrlParams().urlRef}` : '';
  const splits = splitAmountRounded(amount, candidates.length);
  const useCustomSplits = Object.hasOwn(candidates[0], 'donationAmount');
  const rec = candidates
    .map(
      (candidate, index) =>
        `${candidate.de_id}:${useCustomSplits ? candidate.donationAmount : splits[index]}`
    )
    .join(';');

  if (monthlyKey)
    return `${
      ENV_CONFIG().DONATE_URL
    }${monthlyKey}?recipients=${rec}${ref}&s=${getSessionID()}${partnerParams}`;

  return `${
    ENV_CONFIG().DEMOCRACY_ENGINE.CHECKOUT_URL
  }?recipients=${rec}${ref}&s=${getSessionID()}${partnerParams}`;
};

export const democracyEngineCheckoutRedirect = (
  candidates,
  amount,
  monthlyKey?: string,
  searchTags?: string[]
) => {
  window.location.href = getDemocracyEngineCheckoutUrl(candidates, amount, monthlyKey, searchTags);
};

export const getDonationSelectionsObj = () => {
  const donationSelections = getDonationSelections();
  if (!donationSelections) {
    return {};
  }
  return JSON.parse(donationSelections);
};

export const getUrlSearchParamsStrWithoutRecipients = () => {
  const searchParams = getUrlSearchParamsObj();
  searchParams.delete('recipients');
  return searchParams.toString();
};

export const saveDonationSelectionsFromUrl = (recipients: IRecipient[]) => {
  const queryParamObj = getUrlSearchParamsObj();
  const urlRecipients = queryParamObj.get('recipients');
  if (!urlRecipients) {
    return;
  }
  try {
    const candidates: CandidateType[] = urlRecipients
      .split(';')
      .map((recipientWithAmount, index) => {
        const [deId, amount] = recipientWithAmount.split(':');
        const donationAmount = roundNumberToHundredths(amount);
        const recipient = recipients.find(rec => rec.de_id === deId);
        if (!recipient || donationAmount < 1) {
          return null;
        }
        return {
          ...recipient,
          donationAmount,
          isSelected: true,
          index,
        };
      })
      .filter(candidate => candidate !== null) as CandidateType[];
    const amount = roundNumberToHundredths(
      candidates.reduce((total, candidate) => total + (candidate.donationAmount || 0), 0)
    );
    saveDonationSelections(candidates, amount);
  } catch (e) {
    return;
  }
};

export const saveDonationSelections = (candidates: CandidateType[], amount: number) => {
  const queryParams = getUrlSearchParamsStr();
  if (!queryParams) {
    return;
  }
  const donationSelections = {
    candidates,
    total: amount.toString(),
    pathname: new URL(window.location.href).pathname,
    queryParams,
  };

  // remove sessionStorage item if it was previously set and not removed
  clearDonationSelections();

  // convert donationSelection object to string so it can be stored in sessionStorage
  const donationSelectionsString = JSON.stringify(donationSelections);
  // save donationSelections to sessionStorage after user hits donate button on partner/issue/main page
  // and BEFORE user enters login/signup flow
  setDonationSelections(donationSelectionsString);
};

export const getCandidates = (recipients: IRecipient[], savedCandidates?: CandidateType[]) =>
  recipients.map((recipient, index) => {
    const candidate =
      savedCandidates &&
      savedCandidates.find(
        savedCandidate => savedCandidate.recipient_id === recipient.recipient_id
      );
    return {
      ...recipient,
      index,
      isSelected: !savedCandidates || (candidate && candidate.isSelected),
      donationAmount: !candidate ? 0 : Number(candidate.donationAmount),
    } as CandidateType;
  });

export const getCandidatesByImpactScore = (recipients: IRecipient[]) =>
  recipients.map((recipient, index) => ({
    ...recipient,
    index,
    isSelected: recipient.impact_score > 5 || recipient.impact_score === null,
    donationAmount: 0,
  }));

export const getSelectedCandidates = (candidates: CandidateType[]) =>
  candidates.filter(candidate => candidate.isSelected);

export const sortCandidatesByContributionLimitAsc = (
  candidateA: CandidateType,
  candidateB: CandidateType
) => {
  const { contribution_limit: limitA } = candidateA;
  const { contribution_limit: limitB } = candidateB;

  if ((!limitA && !limitB) || limitA === limitB) {
    return 0;
  }
  if (!limitB) {
    return -1; // a before b if no limit to b
  }
  if (!limitA) {
    return 1; // b before a if no limit to a
  }
  return Number(limitA) - Number(limitB); // a before b if b bigger than a
};

export const getCandidatesWithNewAmounts = (
  donationTotal = 0,
  candidates: CandidateType[],
  onCustomSplit?: (isCustomSplit: boolean) => void
) => {
  const selectedCandidates = getSelectedCandidates(candidates);
  let splits = splitAmountRounded(donationTotal, selectedCandidates.length);
  let remainingTotal = donationTotal;
  let remainingCandidatesCount = selectedCandidates.length;
  const updatedCandidates: CandidateType[] = [...candidates];
  const sortedSelectedCandidates = selectedCandidates.sort(sortCandidatesByContributionLimitAsc);
  const firstUnlimitedIndex = sortedSelectedCandidates.findIndex(
    (candidate, index) =>
      !candidate.contribution_limit || Number(candidate.contribution_limit) > splits[index]
  );
  let limitedCandidates = [] as CandidateType[];
  let unlimitedCandidates = [] as CandidateType[];
  let isCustomSplit = false;

  if (firstUnlimitedIndex !== 0) {
    limitedCandidates = sortedSelectedCandidates;
    unlimitedCandidates = [];
    isCustomSplit = true;
  } else {
    limitedCandidates = [];
    unlimitedCandidates = sortedSelectedCandidates;
  }

  limitedCandidates.forEach(candidate => {
    const contributionLimit = parseInt(candidate.contribution_limit || '0');
    const donationAmount =
      contributionLimit && contributionLimit < splits[0] ? contributionLimit : splits.shift() || 0;
    updatedCandidates[candidate.index] = {
      ...candidate,
      donationAmount,
    };
    remainingTotal = roundNumberToHundredths(remainingTotal - donationAmount);
    remainingCandidatesCount--;
    if (remainingCandidatesCount > 0) {
      splits = splitAmountRounded(remainingTotal, remainingCandidatesCount);
    }
  });

  unlimitedCandidates.forEach(candidate => {
    updatedCandidates[candidate.index] = {
      ...candidate,
      donationAmount: splits.shift(),
    };
  });

  candidates.forEach((candidate, index) => {
    if (!candidate.isSelected) {
      updatedCandidates[index].donationAmount = 0;
    }
  });

  if (onCustomSplit) {
    onCustomSplit(isCustomSplit);
  }

  return updatedCandidates;
};

export const logFirebaseEventDonateIntent = (
  selectedDonationAmount = 0,
  candidates: CandidateType[],
  selectedTags?: string[]
) => {
  let tagParams = '';
  selectedTags?.forEach((item: string) => {
    tagParams += `&tags=${item}`;
  });
  logFirebaseEvent('donate_intent', {
    timestamp: new Date().toString(),
    session_id: getSessionID(),
    total_amount: selectedDonationAmount,
    recipient_ids: getSelectedCandidates(candidates)
      .map(candidate => `${candidate.recipient_id}:${candidate.donationAmount}`)
      .join(','),
    ref_code: getRefValue() ? `&ref=${getRefValue()}` : '',
    tag_ids: tagParams,
  });
};

export const getProcessingRedirect = (allTags: ITag[]) => {
  return allTags.findIndex(
    tag => tag.processing_redirect === ProcessingRedirectOptions.DE_GENERAL
  ) >= 0
    ? ProcessingRedirectOptions.DE_GENERAL
    : allTags[0].processing_redirect;
};
