import Column from 'components/Column';
import { Image } from 'components/Image';
import { RowToGrid } from 'components/Row';
import { H3, H4 } from 'components/Typography';
import styled from 'styled-components/macro';

export const DonorsContainer = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.shadows.black(0.3)}`};
  margin-top: 16px;
  padding-top: 16px;
`;

export const DonorsContent = styled.div`
  padding-top: 10px;
  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.colors.white} 100%, ${theme.colors.white} 0%)`};
`;

export const Container = styled(Column)`
  padding: 0 120px;

  @media (min-width: 1001px) and (max-width: 1400px) {
    padding: 0 60px;
  }
  @media (min-width: 801px) and (max-width: 1000px) {
    padding: 40px 60px;
  }
  @media (max-width: 800px) {
    padding: 32px;
  }
`;

export const OrbsContainer = styled.div`
  background: ${({ theme }) => theme.colors.softGray};
  position: relative;
  z-index: 1;
`;

export const Content = styled(Container)`
  background: ${({ theme }) => theme.colors.softGray};
  padding: 0 32px;
`;

export const IssueTitle = styled(H3)`
  @media (max-width: 1400px) {
    font-size: 24px;
  }
`;

export const StyledImage = styled(Image)`
  width: 40px;
  height: 40px;
`;

export const RowContainer = styled(RowToGrid)`
  padding: 50px;

  @media (max-width: 1000px) {
    padding: 32px;
  }
`;

export const DetailVideo = styled.video`
  width: 100%;
  height: auto;
  align-self: flex-start;
  border-radius: 10px;
`;

export const TranscriptTitle = styled(H4)`
  @media (max-width: 1000px) {
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const TranscriptDescription = styled(TranscriptTitle)`
  font-size: 15px;
`;

export const HiddenContainer = styled.div<{ $showInDesktop: boolean }>`
  width: 80%;
  justify-self: center;
  display: ${({ $showInDesktop }) => ($showInDesktop ? 'block' : 'none')};
  margin-bottom: 32px;

  @media (max-width: 1000px) {
    display: ${({ $showInDesktop }) => ($showInDesktop ? 'none' : 'block')};
    width: 100%;
  }
`;

export const HR = styled.hr`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.inkBlue};
  margin-top: 16px;
`;
