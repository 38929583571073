import Column from 'components/Column';
import { Row } from 'components/Row';
import { H2Text } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled(Column)<{ $topContainer?: boolean }>`
  padding: ${({ $topContainer }) => ($topContainer ? '72px 40px 40px' : '40px')};
  text-align: center;
  max-width: 1100px;
  margin: auto;
  justify-items: center;

  @media (max-width: 800px) {
    padding: 32px 20px;
  }
`;

export const MembersRow = styled(Row)`
  gap: 50px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    gap: 20px;
  }
`;

export const MembersContainer = styled(Column)`
  padding: 32px 0;
  max-width: 1100px;
  margin: auto;
  width: 100%;
`;

export const BottomContainer = styled(Column)`
  padding: 32px 0;
  background: ${({ theme }) => theme.colors.softGray};
`;

export const JoinCommunityContainer = styled.div`
  margin-top: -135px;
`;

export const TextsContainer = styled.div`
  max-width: 850px;
`;

export const Title = styled(H2Text)`
  @media (max-width: 800px) {
    font-size: 30px;
    letter-spacing: -0.01em;
    line-height: 33px;
  }
`;
