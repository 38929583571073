import { BodySmall } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled.div<{ $fullWidth?: boolean }>`
  width: 100%;
  ${({ $fullWidth }) => ($fullWidth ? 'max-width: 96%' : 'max-width: 400px')};
  margin: auto;
  padding: 16px 0;
`;

export const Tooltip = styled.div`
  background: ${({ theme }) => theme.colors.oathBlue};
  padding: 4px 10px;
  width: fit-content;
  margin-top: -40px;
  margin-left: -16px;
  border-radius: 6px;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid ${({ theme }) => theme.colors.oathBlue};
  margin-left: -6px;
`;

export const TooltipValue = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.fonts.semiBold};
`;

export const InputContainer = styled.div`
  padding-top: 40px;
`;
