import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { Body } from 'components/Typography';

export const Container = styled.div`
  max-width: 600px;
  margin: auto;
  width: 100%;
`;

export const ItemTitle = styled(Body)<{ $isSelected?: boolean }>`
  color: ${({ theme, $isSelected }) => ($isSelected ? theme.colors.white : theme.colors.oathBlue)};
  ${({ theme }) => theme.fonts.regular};
`;

export const ItemContainer = styled(motion.div)<{ $isSelected?: boolean }>`
  padding: 8px 16px;
  border-radius: 100px;
  background: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.oathBlue : theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.oathBlue};
  cursor: pointer;
  text-align: center;
  margin-bottom: 8px;
`;
