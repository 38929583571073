import { useEffect, useRef, useState } from 'react';
import {
  Container,
  HeaderTitle,
  Navbar,
  NavbarItem,
  NavbarItemContainer,
  NavbarUnderline,
} from './styled';

export const ProfileHeader = ({ handlePress, userName }) => {
  const navRef: any = useRef(null);
  const [selected, setSelected] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const navItems = [
    { label: 'Your Impact', value: 'impact' },
    { label: 'Network Impact', value: 'network' },
    { label: 'Your Oath History', value: 'history' },
    { label: 'Settings', value: 'settings' },
  ];
  const showHeaders = false;

  const handleClick = (index: number) => {
    handlePress(navItems[index].value);
    setSelected(index);
  };

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setIsFixed(e.target.documentElement.scrollTop > navRef?.current?.offsetTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return (
    <Container ref={navRef} $isFixed={isFixed}>
      <HeaderTitle>Welcome, {userName || 'friend'}!</HeaderTitle>
      {showHeaders && (
        <Navbar $justifyContent="space-between">
          {navItems.map((navItem, index) => (
            <NavbarItemContainer key={navItem.value} whileTap={{ scale: 0.9 }}>
              <NavbarItem onClick={() => handleClick(index)}>{navItem.label}</NavbarItem>
              <NavbarUnderline $isSelected={selected === index} />
            </NavbarItemContainer>
          ))}
        </Navbar>
      )}
    </Container>
  );
};
