import { useEffect } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { FAQs } from 'components/Faqs';
import Spacing from 'components/Spacing';
import { Image } from 'components/Image';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { Eyebrow, H4 } from 'components/Typography';
import { scrollToTop } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getFaqsData } from 'selectors/faqs';
import { getFaqs } from 'store/faqs/actions';
import {
  Container,
  BottomContainer,
  ImpactRow,
  TextColumn,
  TextsContainer,
  Title,
  Subtitle,
} from './styled';

export const ImpactScoreScreen = () => {
  const dispatch = useAppDispatch();
  const faqsData = useAppSelector(getFaqsData);

  useEffect(() => {
    scrollToTop();
    dispatch(getFaqs('impact_score'));
  }, []);

  return (
    <>
      <Bar />
      <Container>
        <TextsContainer $isFirst>
          <Eyebrow>How does it work?</Eyebrow>
          <Spacing $size={16} />
          <Title>
            Oath combines the power of data analytics with top tier political expertise for{' '}
            <Title $italic $color={theme.colors.oathBlue}>
              maximum impact.
            </Title>
          </Title>
          <Spacing $size={16} />
          <H4>
            The core of Oath’s model is the Impact Score. Oath’s proprietary algorithm analyzes
            thousands of races across dozens of data points to rate the relative value of your
            donation in each race.
          </H4>
          <Spacing $size={32} />
          <H4 $fontType="italic" $color={theme.colors.oathBlue}>
            The higher the Impact Score (assigned on a scale of 0-10), the greater the value of your
            donation in that race.
          </H4>
        </TextsContainer>
        <ImpactRow $isReverse>
          {faqsData?.length > 0 && (
            <TextColumn>
              <FAQs title="Our Impact Score is calculated across 3 metrics:" isNumbered />
            </TextColumn>
          )}
          <TextColumn>
            <Image
              alt="How Impact Score is calculated"
              src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/impact_score_1.png`}
              style={{ width: '100%' }}
            />
          </TextColumn>
        </ImpactRow>
      </Container>
      <BottomContainer>
        <Container>
          <TextsContainer>
            <Eyebrow>Proven Results</Eyebrow>
            <Spacing $size={16} />
            <Title>
              In 2022, Oath analyzed over 1000 races & directed more than{' '}
              <Title $italic $color={theme.colors.oathBlue}>
                $2,000,000
              </Title>{' '}
              to the races with the highest Impact Scores.
            </Title>
            <Spacing $size={16} />
            <H4>
              The Oath model is designed to help candidates in close elections with high stakes who
              are currently underfunded.
            </H4>
          </TextsContainer>
          <Spacing $size={30} />
          <ImpactRow $isReverse>
            <TextColumn>
              <Subtitle>
                One example was Kris Mayes who ran for Attorney General in Arizona.
              </Subtitle>
              <Spacing $size={20} />
              <H4>
                Mayes was in a Toss Up race (competitiveness), trailing her opponents in fundraising
                (financial need) and the winner would determine the future of abortion access and
                voting rights in a battleground state (stakes).
              </H4>
              <Spacing $size={20} />
              <H4>
                Oath identified the race as a high impact opportunity 12 months before the election
                and more than 2,500 Oath donors contributed to her campaign.{' '}
                <H4 $color={theme.colors.oathBlue}>
                  She ultimately won by 280 votes out of more than 2.5M cast.
                </H4>
              </H4>
            </TextColumn>
            <TextColumn>
              <Image
                alt="Kris Mayes who ran for Attorney General in Arizona."
                src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/impact_score_2.png`}
                style={{ width: '100%' }}
              />
            </TextColumn>
          </ImpactRow>
          <Spacing $size={40} />
          <ImpactRow>
            <TextColumn>
              <Image
                alt="Oath continues to put money where it matters."
                src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/impact_score_3.png`}
                style={{ width: '100%' }}
              />
            </TextColumn>
            <TextColumn>
              <Subtitle>Like Kris Mayes, Oath continues to put money where it matters.</Subtitle>
              <Spacing $size={20} />
              <ul style={{ paddingLeft: 16 }}>
                <li>
                  <H4>76% of Oath targeted races in 2022 won their race</H4>
                </li>
                <Spacing $size={20} />
                <li>
                  <H4>
                    More than 70% of the funding directed through the platform went to races that
                    were decided by less than 5%
                  </H4>
                </li>
                <Spacing $size={20} />
                <li>
                  <H4>
                    In 2024, we are continuing to optimize our model to expand your opportunities to
                    make an impact.
                  </H4>
                </li>
              </ul>
            </TextColumn>
          </ImpactRow>
        </Container>
      </BottomContainer>
      <Footer />
    </>
  );
};
