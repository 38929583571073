import styled from 'styled-components/macro';

const Column = styled.div<{
  $width?: string;
  $textAlign?: string;
  $justifySelf?: string;
  $alignSelf?: string;
  $alignContent?: string;
  $justifyItems?: string;
  $noWrap?: boolean;
  $gap?: string;
}>`
  display: grid;
  ${({ $width }) => $width && `width: ${$width}`};
  text-align: ${({ $textAlign }) => $textAlign || 'left'};
  justify-self: ${({ $justifySelf }) => $justifySelf || 'inherit'};
  align-self: ${({ $alignSelf }) => $alignSelf || 'inherit'};
  align-content: ${({ $alignContent }) => $alignContent || 'inherit'};
  justify-items: ${({ $justifyItems }) => $justifyItems || 'inherit'};
  ${({ $noWrap }) => $noWrap && 'text-wrap: nowrap'};
  ${({ $gap }) => $gap && `gap: ${$gap}`};
`;

export default Column;
