import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENV_CONFIG } from 'config/environment';
import Error from 'components/Error';
import Loader from 'components/Loader';
import Spacing from 'components/Spacing';
import { H3 } from 'components/Typography';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { OrbsHeader } from 'components/Orbs';
import { TopIssues } from 'components/TopIssues';
import { ButtonBrand } from 'components/ButtonBrand';
import { TextsContainer } from 'components/Orbs/styled';
import { MaximizeImpact } from 'components/MaximizeImpact';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { getPathWithRef, getUrlParams, scrollToTop } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useAuth } from 'hooks/auth';
import { getSetsState } from 'selectors/sets';
import { getSessionID } from 'services/storage';
import { ITag } from 'store/tags/types';
import { getSets } from 'store/sets/actions';
import { getTags } from 'store/tags/actions';
import { getUserProfile } from 'store/user/actions';
import { Content, Description, Title, Video, ButtonContainer } from './styled';

export const SetScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { urlPartner, urlRef } = getUrlParams();
  const [topTags, setTopTags] = useState(Array<ITag>());
  const [mediaAvailable, setMediaAvailable] = useState(true);
  const setsState = useAppSelector(getSetsState);

  useAuth(() => {
    dispatch(getUserProfile());
  });

  const handleMediaError = () => {
    setMediaAvailable(false);
  };

  useEffect(() => {
    if (!urlPartner) navigate('/');
    else {
      dispatch(getSets(urlPartner));
      dispatch(getTags({}));
      logFirebaseEvent('land_set', {
        timestamp: new Date().toString(),
        session_id: getSessionID(),
        ref_code: urlRef,
        set_query_str: urlPartner,
      });
    }
  }, []);

  const handlePlayVideo = () => {
    logFirebaseEvent('land_set', {
      timestamp: new Date().toString(),
      session_id: getSessionID(),
      ref_code: urlRef,
      set_query_str: urlPartner,
    });
  };

  useEffect(() => {
    if (setsState.data && !setsState.isLoading) {
      setTopTags(
        setsState.data.tags.map(tag => ({
          ...tag,
          raised:
            setsState.data.set_tags.find(set => set.tag_id === tag.id)?.tag_fundraising_raised ||
            '',
        }))
      );
      scrollToTop();
    }
  }, [setsState]);

  if (!setsState.error && !setsState.data) {
    return (
      <>
        <Header />
        <div className="loader-center">
          <Loader width={60} height={60} />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header hasIssues />
      {setsState.error || (setsState.data && !setsState.data.tags.length) ? (
        <Error
          data={{ component: 'Set screen' }}
          errorMsg="Set page data not found."
          shouldAlert={false}
        >
          <H3>Set page data not found. Please check url and try again.</H3>
        </Error>
      ) : (
        <>
          <OrbsHeader tagsLength={topTags?.length} />
          <Content $textAlign="center">
            <TopIssues tags={topTags} />
            <Spacing $size={40} />
            <TextsContainer>
              <Title>{setsState.data?.set_title_2}</Title>
              <Spacing $size={8} />
              <Description>{setsState.data?.set_desc_2}</Description>
            </TextsContainer>
            <Spacing $size={40} />
            {mediaAvailable && (
              <Video
                id="maximize-video"
                controls
                playsInline
                onPlay={handlePlayVideo}
                onError={handleMediaError}
              >
                <source
                  src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/sets/${urlPartner}/video.mp4`}
                  type="video/mp4"
                />
              </Video>
            )}
            <ButtonContainer>
              <ButtonBrand
                href={getPathWithRef(`/donate?p=${setsState.data?.querystring}`)}
                target="_blank"
              >
                Donate now
              </ButtonBrand>
            </ButtonContainer>
          </Content>
          <MaximizeImpact />
        </>
      )}
      <Footer />
    </>
  );
};
