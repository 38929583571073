import { useEffect } from 'react';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { MediaCard } from 'components/Cards/MediaCard';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getGalleryData } from 'selectors/gallery';
import { getGalleryItems } from 'store/gallery/actions';
import { TopContainer, Title, Description, MediaCardsContainer } from './styled';
import { Link } from 'components/Link';

export const GalleryScreen = () => {
  const dispatch = useAppDispatch();
  const galleryItems = useAppSelector(getGalleryData);

  useEffect(() => {
    dispatch(getGalleryItems());
  }, []);

  return (
    <>
      <Header hasIssues />
      <TopContainer $textAlign="center" $justifyItems="center">
        <Title>Oath in the Media</Title>
        <Description>
          See how Oath is being talked about in the media. If you would like to feature Oath please{' '}
          email{' '}
          <Link href="mailto:press@oath.vote" $fontSize="inherit">
            press@oath.vote
          </Link>
        </Description>
      </TopContainer>
      <MediaCardsContainer $justifyContent="center">
        {galleryItems?.map(item => (
          <MediaCard
            key={item.media_id}
            media_id={item.media_id}
            title={item.title}
            summary={item.summary}
            published_date={item.published_date}
            gallery_type={item.gallery_type}
            url={item.url}
          />
        ))}
      </MediaCardsContainer>
      <Footer />
    </>
  );
};
