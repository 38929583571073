import { useState } from 'react';
import { AnimatePresence, wrap } from 'framer-motion';
import theme from 'config/theme';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { BodySmall, Eyebrow, H4 } from 'components/Typography';
import {
  BGContainer,
  ExperienceContainer,
  Carousel,
  Dot,
  DotsRow,
  MotionContainer,
  ItemTitle,
} from './styled';

const sliderVariants = {
  incoming: (direction: number) => ({
    x: direction > 0 ? '100%' : '-100%',
    scale: 1.2,
    opacity: 0,
  }),
  active: { x: 0, scale: 1, opacity: 1 },
  exit: (direction: number) => ({
    x: direction > 0 ? '-100%' : '100%',
    scale: 1,
    opacity: 0.2,
  }),
};

const sliderTransition = {
  duration: 1,
  ease: [0.56, 0.03, 0.12, 1.04],
};

export const OtherExperiences = () => {
  const experiences = [
    {
      description: `"SO grateful to Oath for not adding me to new lists when I donate through your platform!"`,
      author: 'Ryan',
      location: 'New York',
    },
    {
      description: `"Oath is the smartest way to protect my sister's marriage to her wife, my own access to reproductive agency, my children... and so much more"`,
      author: 'Nicole',
      location: 'Texas',
    },
    {
      description: `"I LOVE the whole concept of Oath, and you've gotten me off the whole treadmill, rat race (would it be rat wheel?) of political donations and the stress of trying to pick what makes a difference."`,
      author: 'Liz',
      location: 'Wisconsin',
    },
  ];
  const [[itemCount, direction], setItemCount] = useState([0, 0]);
  const activeItemIndex = wrap(0, experiences.length, itemCount);

  const swipeToNextItem = (swipeDirection: number) => {
    setItemCount([itemCount + swipeDirection, swipeDirection]);
  };

  const dragEndHandler = dragInfo => {
    const draggedDistance = dragInfo.offset.x;
    const swipeThreshold = 50;
    if (draggedDistance > swipeThreshold) {
      swipeToNextItem(-1);
    } else if (draggedDistance < -swipeThreshold) {
      swipeToNextItem(1);
    }
  };

  const skipToItem = (itemId: number) => {
    let changeDirection = 0;
    if (itemId > activeItemIndex) {
      changeDirection = 1;
    } else if (itemId < activeItemIndex) {
      changeDirection = -1;
    }
    setItemCount([itemId, changeDirection]);
  };

  return (
    <BGContainer>
      <Column $justifyItems="center" $textAlign="center">
        <Spacing $size={80} />
        <Eyebrow $color={theme.colors.white}>Why Donors are Choosing Oath</Eyebrow>
        <ExperienceContainer>
          <Carousel>
            <AnimatePresence initial={false} custom={direction}>
              <MotionContainer
                key={itemCount}
                custom={direction}
                variants={sliderVariants}
                initial="incoming"
                animate="active"
                exit="exit"
                transition={sliderTransition}
                drag="x"
                dragElastic={1}
                onDragEnd={(_, dragInfo) => dragEndHandler(dragInfo)}
              >
                <ItemTitle $color={theme.colors.white}>
                  {experiences[activeItemIndex].description}
                </ItemTitle>
                <Spacing $size={80} />
                <H4 $color={theme.colors.white}>{experiences[activeItemIndex].author}</H4>
                <Spacing $size={4} />
                <BodySmall $color={theme.colors.white}>
                  {experiences[activeItemIndex].location}
                </BodySmall>
              </MotionContainer>
            </AnimatePresence>
          </Carousel>
        </ExperienceContainer>
        <DotsRow>
          {experiences.map((_, index) => (
            <Dot
              key={index}
              onClick={() => skipToItem(index)}
              $isSelected={index === activeItemIndex}
            />
          ))}
        </DotsRow>
      </Column>
    </BGContainer>
  );
};
