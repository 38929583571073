import { useState, useEffect, FC } from 'react';
import { formatNumber, formatNumberAsCurrency } from 'helpers/utils';
import {
  CustomDonationSpan,
  DonateAmountButton,
  DonateAmountButtonsContainer,
  DonationTotalContainer,
  InputCustomDonation,
} from '../styled';

interface Props {
  children: React.ReactNode;
  donationAmounts: number[];
  selectedDonationAmount?: number;
  setSelectedDonationAmount: (amount?: number) => void;
  fromIssues?: boolean;
  label?: string;
}

export const DonationTotal: FC<Props> = ({
  children,
  donationAmounts,
  selectedDonationAmount,
  setSelectedDonationAmount,
  fromIssues = false,
  label,
}) => {
  const [customDonateAmount, setCustomDonateAmount] = useState<number | undefined>();
  const [isDonationInputVisible, setIsDonationInputVisible] = useState(false);

  useEffect(() => {
    if (isDonationInputVisible) {
      document.getElementById('custom-donate-input')?.focus();
    }
  }, [isDonationInputVisible]);

  useEffect(() => {
    if (selectedDonationAmount && !donationAmounts.includes(selectedDonationAmount)) {
      setCustomDonateAmount(selectedDonationAmount);
    }
  }, [selectedDonationAmount]);

  const getOnDonateAmountClick = (amount?: number) => () => {
    setSelectedDonationAmount(amount);
  };

  const getIsSelected = (amount?: number) => {
    return !!selectedDonationAmount && amount === selectedDonationAmount;
  };

  const hideDonationInput = () => {
    setIsDonationInputVisible(false);
  };

  const toggleDonationInput = () => {
    setIsDonationInputVisible(isVisible => !isVisible);
  };

  const onCustomDonationClick = () => {
    toggleDonationInput();
    if (customDonateAmount) {
      setSelectedDonationAmount(customDonateAmount);
    }
  };

  const onDonationInputChange = e => {
    if (e.target.value) {
      setCustomDonateAmount(Math.floor(Number.parseFloat(e.target.value) * 100) / 100);
      return;
    }
    setCustomDonateAmount(undefined);
  };

  const onInputBlur = event => {
    if (event.relatedTarget?.id !== 'donate-amount-button') {
      if (customDonateAmount) {
        setSelectedDonationAmount(customDonateAmount);
      }
      hideDonationInput();
    }
  };

  const getCustomDonationDisplay = () =>
    customDonateAmount ? formatNumber(customDonateAmount) : null;

  return (
    <DonationTotalContainer $fromIssues={fromIssues}>
      <div>{label || 'Select total contribution amount:'}</div>
      <DonateAmountButtonsContainer>
        {donationAmounts.map((amount, index) => (
          <DonateAmountButton
            key={`${amount}-${index}`}
            $isSelected={getIsSelected(amount)}
            onClick={getOnDonateAmountClick(amount)}
          >
            {formatNumberAsCurrency(amount)}
          </DonateAmountButton>
        ))}
        <DonateAmountButton
          id="donate-amount-button"
          $isSelected={getIsSelected(customDonateAmount)}
          onClick={onCustomDonationClick}
          $clickableWhenSelected={true}
          $hasInput={isDonationInputVisible}
        >
          $
          {!isDonationInputVisible && (
            <CustomDonationSpan $isSelected={getIsSelected(customDonateAmount)}>
              {getCustomDonationDisplay()}
            </CustomDonationSpan>
          )}
          {isDonationInputVisible && (
            <InputCustomDonation
              type="number"
              id="custom-donate-input"
              value={customDonateAmount || ''}
              onChange={onDonationInputChange}
              onClick={e => {
                e.stopPropagation();
              }}
              onBlur={onInputBlur}
            />
          )}
        </DonateAmountButton>
      </DonateAmountButtonsContainer>
      {children}
    </DonationTotalContainer>
  );
};
