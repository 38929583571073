import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import { Eyebrow } from 'components/Typography';
import { PressSectionContainer } from './styled';

const baseImgUrl = `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/press_logos`;
const logoUrlTime = `${baseImgUrl}/Time_Magazine_logo.png`;
const logoUrlCampaignsElections = `${baseImgUrl}/Campaigns___Elections.jpg`;
const logoUrlNYT = `${baseImgUrl}/The_New_York_Times_logo.png`;
const logoPolitico = `${baseImgUrl}/POLITICO_logo.png`;
const logoBloomberg = `${baseImgUrl}/bloomberg.svg`;
const logoNewYorkMag = `${baseImgUrl}/New_York_Magazine_Logo.svg`;

const pressLogos = [
  logoUrlCampaignsElections,
  logoNewYorkMag,
  logoUrlTime,
  logoUrlNYT,
  logoPolitico,
  logoBloomberg,
];
const pressLogosDouble = [...pressLogos, ...pressLogos];

export const PressSection = () => {
  const navigate = useNavigate();

  return (
    <PressSectionContainer>
      <Row $justifyContent="space-around" $alignItems="center">
        <Eyebrow $color={theme.colors.inkBlue}>Oath has been featured in...</Eyebrow>
      </Row>
      <div className="ticker-wrap">
        <div className="ticker">
          <Row $pressable onClick={() => navigate('/gallery')}>
            {[1, 2].map((value, index) => (
              <Row
                $justifyContent="space-between"
                $alignItems="center"
                className={`item-collection-${value}`}
                key={index}
              >
                {pressLogosDouble.map((logoSrc, index) => (
                  <img key={index} className="item" src={logoSrc} />
                ))}
              </Row>
            ))}
          </Row>
        </div>
      </div>
    </PressSectionContainer>
  );
};
