import { FC } from 'react';
import Slider from 'rc-slider';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { H4, UtilityText } from 'components/Typography';
import { getPathWithRef } from 'helpers/utils';
import { ITrendingIssue } from 'store/sets/types';
import { CardRow, Content, StyledImage, ImageContainer } from './styled';

interface Props {
  issue: ITrendingIssue;
}

const sliderStyles = {
  rail: {
    height: 12,
    borderRadius: 12,
  },
  track: {
    background: `linear-gradient(${theme.gradients.fullGradient({})})`,
    height: 12,
  },
  handle: {
    display: 'none',
  },
};

const oathIconWhite = `${
  ENV_CONFIG().CLOUD_STORAGE.URL
}/static_content/oath_logos/Oath_Icon_White.png`;

export const IssueCard: FC<Props> = ({ issue }) => {
  return (
    <CardRow key={issue.name} $withBorder={issue.id < 0}>
      {issue.id > 0 && (
        <ImageContainer>
          <StyledImage alt="White Oath icon" src={oathIconWhite} />
        </ImageContainer>
      )}
      <Content $alignItems="flex-start">
        <H4>{issue.name}</H4>
        {issue.id > 0 && !!issue.raised && !!issue.fundraising_goal && (
          <>
            <Spacing $size={6} />
            <Slider
              min={0}
              max={100}
              value={(parseInt(issue.raised) * 100) / parseInt(issue.fundraising_goal)}
              styles={sliderStyles}
            />
            <Spacing $size={6} />
            <UtilityText>
              {Math.round((parseInt(issue.raised) * 100) / parseInt(issue.fundraising_goal))}%
              funded
            </UtilityText>
          </>
        )}
        <Spacing $size={6} />
        <Link href={getPathWithRef(issue.redirect_url)}>{issue.cta}</Link>
      </Content>
    </CardRow>
  );
};
