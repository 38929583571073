export const required = (value: any) => (value ? undefined : 'Required');

const multipleUserKeywords = [' and ', ' + ', '&'];
export const individualOnlyWarningFull =
  "Due to campaign finance rules, donations must be made in an individual's name. There is no way to donate as a couple - please complete two separate donations.";
export const individualOnlyWarningShort = 'Invalid multi';

export const singleUser = (value: any) =>
  multipleUserKeywords.some(keyword => value.includes(keyword))
    ? individualOnlyWarningShort
    : undefined;

export const minTwoLetters = (value: any) =>
  // This regular expression matches any letter from any alphabet (including accented letters).
  value.match(/\p{L}/gu)?.length >= 2 ? undefined : 'Full required';

export const validateNameCheckout = (value: any) => {
  return required(value) || singleUser(value) || minTwoLetters(value);
};
