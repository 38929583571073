import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { FaqsData, FaqsState } from 'store/faqs/types';

export const getFaqsState = (state: RootState): FaqsState => state.faqs;

export const getFaqsData = createSelector(
  [getFaqsState],
  (state: FaqsState): FaqsData[] => state?.data
);
