import styled from 'styled-components/macro';
import Column from 'components/Column';
import { H2Text, H4 } from 'components/Typography';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.softGray};
  padding: 80px 25px;
`;

export const MaximizeColumn = styled(Column)`
  width: 500px;
  margin: auto;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ShareContainer = styled.div<{ $showOnMobile?: boolean }>`
  display: ${({ $showOnMobile }) => ($showOnMobile ? 'none' : 'contents')};

  @media (max-width: 600px) {
    display: ${({ $showOnMobile }) => ($showOnMobile ? 'contents' : 'none')};
  }
`;

export const Title = styled(H2Text)`
  @media (max-width: 1000px) {
    font-size: 30px;
    line-height: 33px;
  }
`;

export const Description = styled(H4)`
  ${({ theme }) => theme.fonts.light};

  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
