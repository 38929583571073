import { Image } from 'components/Image';
import { Row } from 'components/Row';
import styled from 'styled-components/macro';

export const ImageContainer = styled.div`
  background: ${({ theme }) => theme.colors.inkBlue};
  border-radius: 10px 0 0 10px;
  padding: 32px 20px;
`;

export const StyledImage = styled(Image)`
  width: 56px;
`;

export const CardRow = styled(Row)<{ $withBorder?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};
  width: 380px;
  min-height: 120px;
  justify-content: flex-start;
  align-items: center;
  ${({ $withBorder, theme }) => $withBorder && `border: 2px solid ${theme.colors.inkBlue}`}
`;

export const Content = styled(Row)`
  flex-direction: column;
  padding: 13.5px 20px;
  width: 100%;
`;
