import { useEffect, useState } from 'react';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { Eyebrow } from 'components/Typography';
import { IssueCard } from 'components/Cards/IssueCard';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getSetsState } from 'selectors/sets';
import { getSets } from 'store/sets/actions';
import { ITrendingIssue } from 'store/sets/types';
import { defaultTrendingIssues } from './constants';
import {
  TrendingIssuesGrid,
  TrendingIssuesContainer,
  IssueContainer,
  HiddenContainer,
  PairColumn,
} from './styled';

export const TrendingSection = () => {
  const dispatch = useAppDispatch();
  const setsState = useAppSelector(getSetsState);
  const [trendingIssues, setTrendingIssues] = useState<ITrendingIssue[]>();
  const [trendingIssuesByPairs, setTrendingIssuesByPairs] = useState<ITrendingIssue[][]>();

  useEffect(() => {
    dispatch(getSets('top-issues'));
  }, []);

  useEffect(() => {
    if (setsState.data && !setsState.isLoading) {
      const setTags = setsState.data?.set_tags.filter(tag => tag.is_top_tag);
      const pairs: ITrendingIssue[][] = [];
      const issues = Array<ITrendingIssue>();
      if (setTags?.length) {
        for (let i = 0; i < setTags.length && i < 5; i++) {
          issues.push({
            id: setTags[i].tag_id,
            name: setsState.data.tags.find(tag => tag.id === setTags[i].tag_id)?.value || '',
            redirect_url: `donate?p=${setsState.data.tags.find(tag => tag.id === setTags[i].tag_id)
              ?.query_str}`,
            raised: setTags[i].tag_fundraising_raised,
            fundraising_goal: setTags[i].tag_fundraising_goal,
            cta: 'Support Now',
          });
        }
        issues.push({
          id: -1,
          name: 'Learn more about other issues that need your support.',
          redirect_url: 'set?p=top-issues',
          raised: '',
          fundraising_goal: '',
          cta: 'Explore Now',
        });
        for (let i = 0; i < 5; i += 2) {
          pairs.push(
            i < 4
              ? issues.slice(i, i + 2)
              : [
                  issues[i],
                  {
                    id: -1,
                    name: 'Learn more about other issues that need your support.',
                    redirect_url: 'set?p=top-issues',
                    raised: '',
                    fundraising_goal: '',
                    cta: 'Explore Now',
                  },
                ]
          );
        }
        setTrendingIssuesByPairs(pairs);
        setTrendingIssues(issues);
      }
    }
  }, [setsState]);

  if (setsState.error || !trendingIssues?.length) {
    return (
      <TrendingIssuesContainer>
        <Eyebrow>High priority donations</Eyebrow>
        <TrendingIssuesGrid>
          {defaultTrendingIssues.map(issue => (
            <IssueCard key={issue.id} issue={issue} />
          ))}
        </TrendingIssuesGrid>
      </TrendingIssuesContainer>
    );
  }
  return (
    <TrendingIssuesContainer>
      <Eyebrow $color={theme.colors.inkBlue} style={{ paddingLeft: 20 }}>
        Trending issues
      </Eyebrow>
      <TrendingIssuesGrid>
        <HiddenContainer $showInDesktop={true}>
          {trendingIssues?.map((issue, i) => (
            <IssueContainer $isOdd={i % 2 !== 0} key={i}>
              <IssueCard key={issue.id} issue={issue} />
            </IssueContainer>
          ))}
        </HiddenContainer>
        <HiddenContainer $showInDesktop={false}>
          {trendingIssuesByPairs?.map((pair, index) => (
            <PairColumn $position={index} key={index}>
              {pair?.map(issue => (
                <div key={issue.id}>
                  <IssueCard issue={issue} />
                  <Spacing $size={30} />
                </div>
              ))}
            </PairColumn>
          ))}
        </HiddenContainer>
      </TrendingIssuesGrid>
    </TrendingIssuesContainer>
  );
};
