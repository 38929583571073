import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import Button from 'components/Button';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Container, Description, FooterContainer, Title } from './styled';
import { getPathWithRef } from 'helpers/utils';

export const NotFoundScreen = () => {
  const navigate = useNavigate();
  document.body.style.backgroundColor = theme.colors.catskillWhite;

  const handleSubmit = () => {
    document.body.style.backgroundColor = theme.colors.white;
    navigate(getPathWithRef('/'));
  };

  return (
    <>
      <Container>
        <Title>Sorry, page not found</Title>
        <Spacing $size={16} />
        <Description>
          We can’t seem to find the page you’re looking for.
          <br />
          Let's get you on the right page.
        </Description>
        <Spacing $size={24} />
        <Button
          text="GO HOME"
          onPress={handleSubmit}
          backgroundColor={theme.colors.oathBlue}
          textColor={theme.colors.white}
        />
      </Container>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
};
