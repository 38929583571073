import { ButtonBrand } from 'components/ButtonBrand';
import { Link } from 'components/Link';
import { ModalComponent } from 'components/Modal';
import { Body, Eyebrow, UtilityText } from 'components/Typography';
import theme from 'config/theme';
import { FC, useState } from 'react';
import { CancelModalContent, SubmitButton } from './styled';
import Spacing from 'components/Spacing';
import { formatNumberAsCurrency } from 'helpers/utils';
import { TSubscription } from 'store/subscriptions/types';
import { useAppDispatch } from 'helpers/hooks';
import { cancelSubscription, getSubscriptions } from 'store/subscriptions/actions';
import { getMonthlyTagName } from './utils';
import classNames from 'classnames';

interface Props {
  isOpen: boolean;
  handleCloseModal: () => void;
  subscription?: TSubscription;
}

export const CancelSubscriptionModal: FC<Props> = ({ isOpen, handleCloseModal, subscription }) => {
  const dispatch = useAppDispatch();
  const [isCancelSuccessful, setIsCancelSuccessful] = useState(false);
  const [cancelError, setCancelError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const cancelSub = () => {
    if (!subscription) {
      return;
    }
    setIsProcessing(true);
    dispatch(cancelSubscription(subscription.de_gid))
      .then(() => {
        dispatch(getSubscriptions());
        setIsCancelSuccessful(true);
        setIsProcessing(false);
      })
      .catch(() => {
        setCancelError(
          'There was an error canceling your monthly donation. Please contact support.'
        );
        setIsProcessing(false);
      });
  };

  if (!subscription) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeModal={handleCloseModal}
      contentLabel="Cancel subscription modal"
      style={{ maxWidth: '325px' }}
    >
      <CancelModalContent>
        {isCancelSuccessful ? (
          <>
            <Eyebrow $color={theme.colors.inkBlue}>Monthly Donation Canceled</Eyebrow>
            <Spacing $size={2} />
            <Body>
              You have successfully canceled you {formatNumberAsCurrency(subscription.amount)}{' '}
              {getMonthlyTagName(subscription.tag_name)} monthly donation.
            </Body>
            <Spacing $size={13} />
            <ButtonBrand
              $backgroundColor={theme.colors.inkBlue}
              $width="100%"
              onClick={handleCloseModal}
            >
              Close
            </ButtonBrand>
          </>
        ) : (
          <>
            <Eyebrow $color={theme.colors.inkBlue}>Cancel Monthly Donation</Eyebrow>
            <Spacing $size={2} />
            <Body>
              Are you sure you want to cancel {formatNumberAsCurrency(subscription.amount)}{' '}
              {getMonthlyTagName(subscription.tag_name)} monthly donation?
            </Body>
            <Spacing $size={13} />
            <SubmitButton
              $backgroundColor={theme.colors.inkBlue}
              $width="100%"
              onClick={cancelSub}
              className={classNames({ 'animated-background': isProcessing })}
              disabled={isProcessing}
              $submitting={isProcessing}
            >
              {isProcessing ? 'Canceling Donation' : 'Confirm'}
            </SubmitButton>
            <Spacing $size={5} />
            <Link $color={theme.colors.inkBlue} onClick={handleCloseModal}>
              <UtilityText>Go Back</UtilityText>
            </Link>
            {cancelError && (
              <>
                <Spacing $size={5} />
                <UtilityText $color={theme.colors.errorRed}>{cancelError}</UtilityText>
              </>
            )}
          </>
        )}
      </CancelModalContent>
    </ModalComponent>
  );
};
