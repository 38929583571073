import { Field } from 'react-final-form';
import { Input, ErrorText } from './styled';

export const TextField = ({ isLabelBelow = false, label, maxLength = 100, name, ...props }) => {
  return (
    <Field name={name} allowNull parse={value => (value === '' ? null : value)} {...props}>
      {({ input, meta }) => (
        <div>
          {!isLabelBelow && <label>{label}</label>}
          <Input
            {...input}
            type="text"
            maxLength={maxLength}
            $hasError={(meta.error || meta.submitError) && meta.touched}
          />
          {isLabelBelow && <label>{label}</label>}
          {(meta.error || meta.submitError) && meta.touched && (
            <ErrorText>{meta.error || meta.submitError}</ErrorText>
          )}
        </div>
      )}
    </Field>
  );
};
