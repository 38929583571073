import { useEffect, useState } from 'react';
import { ENV_CONFIG } from 'config/environment';
import Spacing from 'components/Spacing';
import { RowToGrid } from 'components/Row';
import { Body } from 'components/Typography';
import { listItems } from './constants';
import { HowItWorksContainer, BigText, StyledImage, InfoContainer, ItemTitle } from './styled';

export const HowItWorks = ({ tabPressed }: { tabPressed: string }) => {
  const [items, setItems] = useState(listItems);

  useEffect(() => {
    if (tabPressed === 'infra') {
      setItems(items.filter(item => item.id !== 1));
    }
  }, []);

  return (
    <HowItWorksContainer $textAlign="center">
      <BigText>How It Works</BigText>
      <Spacing $size={20} />
      <RowToGrid $justifyContent="center">
        {items.map(item => (
          <InfoContainer
            key={item.id}
            $width="25%"
            $textAlign="center"
            $justifyItems="center"
            $alignSelf="flex-start"
          >
            <StyledImage
              alt={`${item.title} logo`}
              src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/monthly/monthly_how_${
                item.id
              }.png`}
            />
            <ItemTitle>{item.title}</ItemTitle>
            <Spacing $size={4} />
            <Body>{item.subtitle}</Body>
          </InfoContainer>
        ))}
      </RowToGrid>
    </HowItWorksContainer>
  );
};
