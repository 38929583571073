import { CSSProperties, ReactNode } from 'react';

export interface LottieProps {
  children?: ReactNode;
  slide?: string;
  isPaused?: boolean;
  style?: CSSProperties;
  childrenStyle?: CSSProperties;
}

export const defaultOptions = (animationData: any) => ({
  loop: true,
  autoplay: true,
  animationData,
  // canvas fixes square circle issue on safari
  renderer: 'canvas', // 'svg', 'canvas' or 'html'
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});

const JSONDictionary = {
  intro: ['intro'],
  blueOrbs: ['blueOrbs'],
  orbs_01: ['cl'],
  orbs_02: ['lg', 'trans'],
  orbs_03: ['dt', 'amaga'],
  orbs_04: ['rr', 'teigen'],
  orbs_05: ['gs'],
  orbs_06: ['pd', 'tpsl-gs', 'fh', 'hs', 'harris2024'],
  orbs_07: ['ng', 'breakingbarriers'],
  orbs_08: ['mx', 'lastmin'],
  orbs_09: ['bb'],
};

export const getJSONFile = (key: string) => {
  for (const [fileName, keys] of Object.entries(JSONDictionary)) {
    if (keys.includes(key)) {
      return fileName;
    }
  }
  return null;
};
