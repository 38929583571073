import { Row } from 'components/Row';
import Column from 'components/Column';
import { Image } from 'components/Image';
import { UtilityText } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled.div`
  margin: auto;
  width: 100%;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};
  margin-bottom: 8px;
`;

export const Subtitle = styled(UtilityText)`
  color: ${({ theme }) => theme.shadows.black(0.5)};
`;

export const StyledImage = styled(Image)`
  width: 55px;
  height: 55px;
  border-radius: 3px;
`;

export const Content = styled(Column)`
  align-self: center;
  cursor: pointer;
  width: -webkit-fill-available;
  grid-column: 3;
  grid-row: 1;
`;

export const CandidatePhoto = styled(Row)`
  grid-column: 2;
  grid-row: 1;
`;

export const CardContent = styled(Row)<{ $isSelected?: boolean; $detailVisible?: boolean }>`
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ $detailVisible }) => ($detailVisible ? '5px 5px 0 0' : '5px')};
  border-width: 3px 0 0 0;
  border-style: solid;

  ${({ $isSelected, theme }) =>
    $isSelected
      ? `
      ${`border-image: linear-gradient(${theme.gradients.inkToDarkGradient({})});`}
      border-image-slice: 1;
    `
      : `
      border-color: ${theme.colors.white}
    `}
`;

export const ShowMoreLessRow = styled(Row)<{
  $isSelected?: boolean;
  $detailVisible?: boolean;
  $detailOpen?: boolean;
}>`
  ${({ $detailVisible, $detailOpen }) =>
    $detailVisible &&
    $detailOpen &&
    `
    border-radius: 0 0 5px 5px;
    padding: 8px;
    justify-content: flex-end;
  `}
  display: ${({ $detailVisible }) => ($detailVisible ? 'flex' : 'none')};
  background: ${({ theme }) => theme.colors.white};
  text-wrap: nowrap;
  &:hover {
    span {
      color: ${({ theme }) => theme.colors.electricBlue};
      text-decoration: underline;
    }

    svg path {
      fill: ${({ theme }) => theme.colors.electricBlue} !important;
    }
  }
`;

export const CandidateGrid = styled(Column)`
  width: -webkit-fill-available;
  width: 100%;
  grid-template-columns: 23px 55px 1fr 80px;
  grid-template-rows: auto auto;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 12px;
  column-gap: 7px;
  margin-bottom: -12px;
`;

export const TextsColumn = styled(Column)`
  width: -webkit-fill-available;
  gap: 9px;
`;

export const CandidateDetailsColumn = styled(Column)`
  grid-template-columns: 104px 1fr;
  grid-template-rows: auto auto auto;
  align-items: center;
  column-gap: 10px;
  row-gap: 6px;
  grid-column: 2 / 4;
  grid-row: 2;
  margin-bottom: -6px;
  position: relative;
  @media (min-width: 800px) {
    grid-column: 3;
  }
`;

export const AlignTop = styled.span`
  align-self: start;
  line-height: 0;
  margin-bottom: 3px;
  margin-top: 4px;
`;

export const NoImpactScore = styled.div`
  grid-column: 2 / 4;
  grid-row: 2;
  @media (min-width: 800px) {
    grid-column: 3;
  }
`;

export const GridCheckbox = styled(Row)`
  align-self: center;
  grid-column: 1;
  grid-row: 1;
`;

export const GhostRectangle = styled.div`
  background: ${({ theme }) => theme.colors.softGray};
`;

export const GhostLabel = styled(GhostRectangle)`
  height: 16px;
  width: 90px;
`;

export const GhostContent = styled(GhostRectangle)`
  height: 19px;
  width: 70px;
`;
