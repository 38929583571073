import axios from 'axios';
import { Dispatch } from 'redux';
import SITE_CONFIG_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { siteConfigHelper } from './helper';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getSiteConfig =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(SITE_CONFIG_TYPES.siteConfigStart());
    const { urlSection } = siteConfigHelper();

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_SITE_CONFIG(urlSection)}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
      });
      dispatch(SITE_CONFIG_TYPES.siteConfigSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(SITE_CONFIG_TYPES.siteConfigError(error.message));
      return Promise.reject(error);
    }
  };
