import { FC, useEffect, useState } from 'react';
import { Field, useForm } from 'react-final-form';
import {
  CustomTipRow,
  CustomTipSpan,
  InputCustomTip,
  TipAmountButton,
  TipButtonsContainer,
} from './styled';
import { formatNumber, formatNumberAsCurrency } from 'helpers/utils';
import { Body, BodySmall } from 'components/Typography';
import { Row } from 'components/Row';
import { getTipOptions } from '../utils';
import { Link } from 'components/Link';
import theme from 'config/theme';

interface Props {
  donationAmount: number;
  tipAmount: number;
}

export const Tip: FC<Props> = ({ donationAmount, tipAmount }) => {
  const [customTipAmount, setCustomTipAmount] = useState<number | undefined>();
  const [isDonationInputVisible, setIsDonationInputVisible] = useState(false);
  const { change: changeForm } = useForm();

  useEffect(() => {
    if (getTipOptions(donationAmount).findIndex(option => option.amount === tipAmount) < 0) {
      setCustomTipAmount(tipAmount);
    }
  }, [tipAmount]);

  useEffect(() => {
    if (isDonationInputVisible) {
      document.getElementById('custom-donate-input')?.focus();
    }
  }, [isDonationInputVisible]);

  const setTipAmount = (amount: number) => {
    changeForm('tip', amount);
  };

  const getOnTipAmountClick = (amount?: number) => () => {
    setTipAmount(amount || 0);
  };

  const getIsSelected = (amount?: number) => {
    return !!tipAmount && amount === tipAmount;
  };

  const hideDonationInput = () => {
    setIsDonationInputVisible(false);
  };

  const toggleDonationInput = () => {
    setIsDonationInputVisible(isVisible => !isVisible);
  };

  const onCustomDonationClick = () => {
    toggleDonationInput();
    if (customTipAmount) {
      setTipAmount(customTipAmount);
    }
  };

  const onDonationInputChange = e => {
    if (e.target.value) {
      setCustomTipAmount(Math.floor(Number.parseFloat(e.target.value) * 100) / 100);
      return;
    }
    setCustomTipAmount(undefined);
  };

  const enterCustomTip = () => {
    if (customTipAmount) {
      setTipAmount(customTipAmount);
    }
    hideDonationInput();
  };

  const onInputBlur = event => {
    if (event.relatedTarget?.id !== 'donate-amount-button') {
      enterCustomTip();
    }
  };

  const getCustomDonationDisplay = () => (customTipAmount ? formatNumber(customTipAmount) : null);

  const onRemoveTip = () => {
    setTipAmount(0);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      enterCustomTip();
    }
  };

  return (
    <>
      <Field hidden name="tip" component="input" />
      <TipButtonsContainer>
        {getTipOptions(donationAmount).map((tip, index) => (
          <TipAmountButton
            key={`${tip.amount}-${index}`}
            type="button"
            $isSelected={getIsSelected(tip.amount)}
            onClick={getOnTipAmountClick(tip.amount)}
          >
            <Body $color="inherit">
              {!tip.percentage ? formatNumberAsCurrency(tip.amount) : `${tip.percentage * 100}%`}
            </Body>
          </TipAmountButton>
        ))}
        <TipAmountButton
          id="donate-amount-button"
          type="button"
          $isSelected={getIsSelected(customTipAmount)}
          onClick={onCustomDonationClick}
          $clickableWhenSelected={true}
          $hasInput={isDonationInputVisible}
        >
          <Body $color="inherit">
            <CustomTipRow>
              $
              {!isDonationInputVisible && (
                <CustomTipSpan $isSelected={getIsSelected(customTipAmount)}>
                  {getCustomDonationDisplay()}
                </CustomTipSpan>
              )}
              {isDonationInputVisible && (
                <InputCustomTip
                  type="number"
                  id="custom-donate-input"
                  value={customTipAmount || ''}
                  onChange={onDonationInputChange}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  onBlur={onInputBlur}
                  onKeyDown={handleKeyDown}
                />
              )}
            </CustomTipRow>
          </Body>
        </TipAmountButton>
      </TipButtonsContainer>
      <Row $justifyContent="space-around">
        <Link $color={theme.colors.shaft} onClick={onRemoveTip} type="button">
          <BodySmall $color={theme.colors.shaft}>Leave no tip</BodySmall>
        </Link>
      </Row>
    </>
  );
};
