import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { hubspotForms, zoomForms } from './constants';
import { Container } from './styled';
import { getPathWithRef } from 'helpers/utils';

declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (options: {
          region: string;
          portalId: string;
          formId: string;
          target: string;
        }) => void;
      };
    };
  }
}

export const FormsScreen = () => {
  const { form } = useParams();
  const navigate = useNavigate();
  const scriptLoaded = useRef(false);
  const formContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!form || (!hubspotForms[form] && !zoomForms[form])) {
      navigate('/');
      return;
    }

    const formContainer = formContainerRef.current;
    if (!formContainer) return;

    formContainer.innerHTML = '';

    if (zoomForms[form]) {
      window.open(zoomForms[form], '_blank');
      navigate(getPathWithRef('/set?p=standup'));
    } else if (!scriptLoaded.current) {
      const script = document.createElement('script');
      script.src = '//js.hsforms.net/forms/embed/v2.js';
      script.async = true;

      script.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: 'na1',
            portalId: '43762772',
            formId: hubspotForms[form],
            target: '#hs-form-container',
          });
          scriptLoaded.current = true;
        }
      };

      document.body.appendChild(script);
    }
  }, []);

  return (
    <>
      <Bar />
      <Container>
        <div id="hs-form-container" ref={formContainerRef} />
      </Container>
      <Footer />
    </>
  );
};
