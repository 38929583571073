import { FC, InputHTMLAttributes } from 'react';
import InputSearch from './Search';
import { StyledText, StyledTextarea, Wrapper } from './styled';

export type onChangeType = React.ChangeEvent<HTMLInputElement>;
export type onKeyboardType = React.KeyboardEvent<HTMLInputElement>;
export type onChangeTextareaType = React.ChangeEvent<HTMLTextAreaElement>;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  placeholder?: string;
  fullWidth?: boolean;
  value: string;
  textOnChange?: (e: onChangeType) => void;
  textareaOnChange?: (e: onChangeTextareaType) => void;
  textarea?: boolean;
}

const Input: FC<InputProps> = ({
  placeholder = '',
  fullWidth,
  value,
  textOnChange,
  textareaOnChange,
  id,
  textarea = false,
  ...inputProps
}) => {
  return (
    <Wrapper $fullWidth={fullWidth}>
      {!textarea ? (
        <StyledText
          value={value}
          placeholder={placeholder}
          onChange={textOnChange}
          id={id}
          {...inputProps}
        />
      ) : (
        <StyledTextarea
          value={value}
          placeholder={placeholder}
          onChange={textareaOnChange}
          id={id}
        />
      )}
    </Wrapper>
  );
};

export { InputSearch };

export default Input;
