import styled from 'styled-components/macro';
import Column from 'components/Column';
import { ButtonBrand } from 'components/ButtonBrand';
import { Row } from 'components/Row';
import ButtonLink from 'components/ButtonLink';
import { Image } from 'components/Image';

export const SlateContainer = styled(Row)`
  color: ${({ theme }) => theme.colors.shaft};
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => theme.fonts.regular};
  padding: 35px 49px;
  @media (max-width: 800px) {
    padding: 16px 15px 38px;
  }
`;

export const Bold = styled.span`
  ${({ theme }) => theme.fonts.semiBold};
`;

export const CandidatesSection = styled.div`
  background: ${({ theme }) => theme.colors.softGray};
  border-radius: 10px;
  font-size: 15px;
  line-height: 20px;
  margin-top: 16px;
  max-width: 100%;
  padding: 19px 39px 15px;
  @media (max-width: 800px) {
    padding: 12.5px 16px;
  }
`;

export const CandidateImage = styled(Image)`
  height: 40px;
  margin-right: 7px;
  width: 40px;
`;

export const CandidateImagePlaceholder = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(270deg, ${theme.colors.oathBlue} 0%, ${theme.colors.electricBlue} 100%)`};
  height: 40px;
  margin-right: 7px;
  width: 40px;
`;

export const Candidate = styled(Row)<{ $isSelected: boolean; $whiteBackground?: boolean }>`
  ${({ theme, $whiteBackground }) =>
    $whiteBackground &&
    `
      background: ${theme.colors.white};
      border-radius: 2px;
      padding: 3px;
    `};
  font-size: 13px;
  line-height: 18px;
  opacity: ${({ $isSelected }) => ($isSelected ? '1' : '0.5')};
  position: relative;
  &.single-recipient {
    div {
      font-size: 15px;
      line-height: 20px;
    }
    img {
      height: 80px;
      margin-right: 14px;
      width: 80px;
    }
  }
  @media (max-width: 530px) {
    margin-bottom: 12px;
    min-width: 240px;
  }
`;

export const CandidateEdit = styled(Row)`
  color: ${({ theme }) => theme.colors.inkBlue};
`;

export const CandidatesGrid = styled(Column)<{ $hideForSmall: boolean }>`
  color: ${({ theme }) => theme.colors.inkBlue};
  column-gap: 8px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 18px;
  margin-top: 14px;
  row-gap: 18px;
  &.single-recipient {
    grid-template-columns: none !important;
  }
  @media (max-width: 1488px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    div:nth-child(9),
    div:nth-child(10),
    div:nth-child(11),
    div:nth-child(12) {
      display: none;
    }
  }
  @media (max-width: 1233px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    div:nth-child(5),
    div:nth-child(6),
    div:nth-child(7),
    div:nth-child(8) {
      display: none;
    }
  }
  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    div:nth-child(5),
    div:nth-child(6),
    div:nth-child(7),
    div:nth-child(8) {
      display: flex;
    }
  }
  @media (max-width: 530px) {
    ${({ $hideForSmall }) => $hideForSmall && 'display: none'};
  }
`;

export const DonateAmountButton = styled.button<{
  $isSelected: boolean;
  $clickableWhenSelected?: boolean;
  $hasInput?: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  background: inherit;
  color: ${({ theme }) => theme.colors.inkBlue};
  border: ${({ theme }) => `1px solid ${theme.colors.inkBlue}`};
  border-radius: 100px;
  flex: 0 0 calc(33.3333% - 14px);
  height: 38px;
  margin-bottom: 8px;
  margin-left: 14px;
  padding: 0 15px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
      background: ${theme.colors.oathBlue};
      color: ${theme.colors.white};
      border: none;
    `}
  &:hover {
    ${({ $isSelected, theme }) =>
      !$isSelected &&
      `
        border-color: ${theme.colors.oathBlue};
        color: ${theme.colors.oathBlue};
      `}
  }
  @media (max-width: 1233px) {
    flex: 0 0 calc(50% - 6px);
    margin-bottom: 6px;
    margin-left: 6px;
  }
  @media (max-width: 800px) {
    flex: 0 0 calc(33.3333% - 14px);
    margin-bottom: 8px;
    margin-left: 14px;
  }
  @media (max-width: 530px) {
    flex: 0 0 calc(50% - 6px);
    margin-bottom: 6px;
    margin-left: 6px;
  }
`;

export const DonateButton = styled(ButtonBrand)`
  align-self: center;
  margin-bottom: 5px;
  margin-top: 18px;
  width: 350px;
  &.split-screen {
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const DonationTotalContainer = styled.div<{ $fromIssues: boolean }>`
  ${({ $fromIssues, theme }) =>
    $fromIssues
      ? `
    color: ${theme.colors.inkBlue};
    padding: 16px 0;
  `
      : `
    border: 1px solid ${theme.colors.inkBlue};
    border-radius: 10px;
    margin-top: 25px;
    padding: 17px 36px;
    text-align: left;
    @media (max-width: 800px) {
      padding: 17px 16px;
    }
  `}
  ${({ theme }) => theme.fonts.regular};
  font-size: 15px;
  line-height: 20px;
`;

export const DonateAmountButtonsContainer = styled(Row)`
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: center;
  line-height: 18px;
  margin-left: -14px;
  margin-top: 6px;
  @media (max-width: 1233px) {
    margin-left: -6px;
  }
  @media (max-width: 800px) {
    margin-left: -14px;
  }
  @media (max-width: 530px) {
    margin-left: -6px;
  }
`;

export const Label = styled.label`
  align-items: center;
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
`;

export const Checkmark = styled(Row)<{ $isChecked: boolean }>`
  background: ${({ $isChecked, theme }) => ($isChecked ? theme.colors.inkBlue : 'inherit')};
  border: ${({ theme }) => `1px solid ${theme.colors.inkBlue}`};
  border-radius: 100px;
  flex-shrink: 0;
  justify-content: center;
  top: 0;
  left: 0;
  height: 27px;
  margin-right: 7px;
  width: 27px;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const ModalContent = styled(Row)`
  ${({ theme }) => theme.fonts.regular}
  color: ${({ theme }) => theme.colors.shaft};
  flex-direction: column;
  height: auto !important;
  max-height: calc(100vh - 100px) !important;
  overflow: scroll;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 10px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: ${({ theme }) => `2px solid ${theme.colors.white}`};
    background-color: ${({ theme }) => theme.shadows.black(0.2)};
  }
`;

export const CandidatesEditGrid = styled(Column)`
  column-gap: 18px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-size: 13px;
  line-height: 18px;
  margin: 18px auto 91px;
  row-gap: 12px;
  width: 100%;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const ModalTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 14px;
  margin-bottom: 2px;
`;

export const ModalSubheader = styled.div`
  font-size: 15px;
  line-height: 20px;
`;

export const ModalFooter = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(181.81deg, rgba(255, 255, 255, 0) 1.54%, ${theme.colors.white} 88.78%)`};
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const FooterContainer = styled(Row)`
  flex-direction: column;
  padding: 14px 20px 9px;
`;

export const CustomDonationSpan = styled.span<{ $isSelected: boolean; children?: React.ReactNode }>`
  border-bottom: 1px solid;
  border-color: inherit;
  display: inline-block;
  ${({ $isSelected, theme }) => $isSelected && `border-color: ${theme.colors.white}`};
  ${({ children }) =>
    !children &&
    `
      width: 48px; 
      height: 18px;
  `};
`;

export const InputCustomDonation = styled.input`
  border: 1px solid;
  border-radius: 4px;
  height: 26px;
  margin-left: 2px;
  max-width: 60%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const HrContribution = styled.hr`
  margin-bottom: 20px;
  width: 100%;
`;

export const RulesContainer = styled.div`
  ${({ theme }) => theme.fonts.regular}
  color: ${({ theme }) => theme.colors.black};
  font-size: 13px;
  line-height: 18px;
  opacity: 0.7;
  h2 {
    font-size: 13px;
    line-height: 18px;
  }
  ol {
    padding-inline-start: 22px;
  }
`;

export const CandidateButtonLink = styled(ButtonLink)<{ $numCandidates: number }>`
  display: ${({ $numCandidates }) => ($numCandidates > 12 ? 'flex' : 'none')};
  @media (max-width: 1488px) {
    display: ${({ $numCandidates }) => ($numCandidates > 8 ? 'flex' : 'none')};
  }
  @media (max-width: 1233px) {
    display: ${({ $numCandidates }) => ($numCandidates > 4 ? 'flex' : 'none')};
  }
  @media (max-width: 800px) {
    display: ${({ $numCandidates }) => ($numCandidates > 8 ? 'flex' : 'none')};
  }
  @media (max-width: 530px) {
    display: ${({ $numCandidates }) => ($numCandidates > 4 ? 'flex' : 'none')};
  }
`;

export const CandidatesColumns = styled(Row)<{ $hideForLarge: boolean }>`
  margin: 17.5px 0;
  overflow: hidden;
  ${({ $hideForLarge }) => $hideForLarge && 'display: none'};
  @media (max-width: 530px) {
    display: flex;
  }
`;

export const CandidatesColumn = styled(Row)`
  flex-direction: column;
  @media (max-width: 530px) {
    width: 100%;
  }
`;

export const CandidatesFooter = styled(Row)`
  column-gap: 12px;
  justify-content: flex-end;
  @media (max-width: 800px) {
    justify-content: center;
    padding-right: 16px;
  }
`;

export const TagIdentifier = styled.div`
  ${({ theme }) => theme.fonts.bold};
  background: linear-gradient(
      98deg,
      rgba(230, 70, 82, 0.7) -2.44%,
      rgba(244, 138, 48, 0.7) 18.05%,
      rgba(252, 233, 54, 0.7) 40.73%,
      rgba(91, 220, 144, 0.7) 60.49%,
      rgba(114, 152, 226, 0.7) 78.78%,
      rgba(136, 5, 167, 0.7) 100%
    ),
    #fff;
  border-radius: 1px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  color: ${({ theme }) => theme.colors.black};
  font-size: 8px;
  letter-spacing: 0.01em;
  line-height: 8px;
  padding: 2px 3px;
  text-align: center;
  width: fit-content;
`;
