import styled from 'styled-components/macro';

interface ISpacing {
  $size: number;
  $isVertical?: boolean;
  $sizeToSwitch?: number;
}

export const Spacing = styled.div<ISpacing>`
  width: ${({ $size, $isVertical }) => ($isVertical ? `${$size}px` : '100%')};
  height: ${({ $size, $isVertical }) => ($isVertical ? '100%' : `${$size}px`)};

  ${({ $sizeToSwitch, $isVertical, $size }) =>
    $sizeToSwitch &&
    $sizeToSwitch > 0 &&
    `
      @media (max-width: ${$sizeToSwitch}px) {
        width: ${$isVertical ? '100%' : `${$size}px`};
        height: ${$isVertical ? `${$size}px` : '100%'};
      }
    `};
`;

export default Spacing;
