import { Field } from 'react-final-form';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import { BodySmall } from 'components/Typography';
import Icon, { IconNames } from 'components/Icon';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { IssueEdit } from '../styled';
import { CloseIcon, HR, IssueTagsContainer } from './styled';

export const TagSelectField = ({ getState, allTags, userId }) => {
  const state = getState();
  const activeTags = state.values.issues;

  const getClickHandler = (selectOrUnselect: 'select' | 'unselect', tagId) => () => {
    const eventName = `profile_${selectOrUnselect}_tag`;
    logFirebaseEvent(eventName, {
      timestamp: new Date().toString(),
      user_id: userId,
      tag_id: tagId,
    });
  };

  return (
    <>
      <IssueTagsContainer>
        {!activeTags?.length && <span>No issues selected</span>}
        {allTags
          .filter(tag => activeTags.indexOf(tag.query_str) >= 0)
          .map(tag => (
            <label key={tag.query_str}>
              <Field name="issues" component="input" type="checkbox" hidden value={tag.query_str} />
              <IssueEdit $isSelected onClick={getClickHandler('unselect', tag.id)}>
                <Row $alignItems="center">
                  <BodySmall>{tag.value}</BodySmall>
                  <CloseIcon>
                    <Icon name={IconNames.XClose} size={13} color={theme.colors.shaft} />
                  </CloseIcon>
                </Row>
              </IssueEdit>
            </label>
          ))}
      </IssueTagsContainer>
      <HR />
      <span>{activeTags.length ? 'Add more issues' : 'Add issues'}</span>
      <Spacing $size={6} />
      <IssueTagsContainer>
        {allTags
          .filter(tag => activeTags.indexOf(tag.query_str) === -1)
          .map(tag => (
            <label key={tag.query_str}>
              <Field name="issues" component="input" type="checkbox" hidden value={tag.query_str} />
              <IssueEdit $isSelected={false} onClick={getClickHandler('select', tag.id)}>
                <BodySmall>
                  {tag.value}
                  &nbsp;&nbsp;+
                </BodySmall>
              </IssueEdit>
            </label>
          ))}
      </IssueTagsContainer>
    </>
  );
};
