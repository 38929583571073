import { Row } from 'components/Row';
import styled from 'styled-components/macro';

export const IssuesContainer = styled.div`
  margin-top: -160px;
`;

export const TopIssueContainer = styled.div`
  flex: 0 0 calc(33.3333% - 36px);

  @media (max-width: 1000px) {
    flex: 0 0 calc(100%);
  }
`;

export const TopIssuesRow = styled(Row)`
  flex-wrap: wrap;
  gap: 50px;
`;
