const colors = {
  //Common Colors
  black: '#000000',
  darkGreen: '#093434',
  electricBlue: '#7CE3E7',
  electricBlue1: '#CBF4F5',
  electricBlue2: '#BDF1F3',
  electricBlue3: '#B0EEF1',
  electricBlue4: '#A3EBEE',
  electricBlue5: '#96E9EC',
  electricBlue6: '#89E6E9',
  electricBlue7: '#AAEDEF',
  errorRed: '#C14B0B',
  errorRed1: '#F3DBCE',
  errorRed2: '#ECC9B6',
  errorRed3: '#E6B79D',
  errorRed4: '#E0A585',
  errorRed5: '#DA936D',
  errorRed6: '#D48154',
  errorRed7: '#EFD2C2',
  errorRed8: '#E3AE91',
  inkBlue: '#04053A',
  oathBlue: '#1410F9',
  platinum: '#E4E1DC',
  softGray: '#F2F2F2',
  white: '#FFFFFF',

  //Candidate Tag Palette Colors
  algaeGreen: '#8CE7B1',
  cornFlower: '#9CB7EB',
  corvette: '#F9CCA4',
  frenchLilac: '#DDB5EC',
  lavender: '#C382D3',
  marcipan: '#F7DF94',
  mauvelous: '#F09097',
  rajah: '#F8B983',
  sweetCorn: '#FDF286',
  wewak: '#F5ABAB',

  //Rest of the colors
  alto: '#D9D9D9',
  catskillWhite: '#EAF6FF',
  concrete: '#F3F2F2',
  disabled: '#F0F0F0',
  governorBay: '#4F41C8',
  gray: '#828282',
  iron: '#DAE1E1',
  jumbo: '#7E8087',
  lightGray: '#DADADA',
  mercury: '#E9E9E9',
  paleBlue: '#ECF6FF',
  shaft: '#272727',
  silver: '#C4C4C4',
  silverChalise: '#B2B2B2',
  successGreen: '#089D55',
  transparent: 'transparent',
  webOrange: '#FFA400',
};

export const ratingColors = {
  'D+1': colors.electricBlue1,
  'D+2': colors.electricBlue2,
  'D+3': colors.electricBlue3,
  'D+4': colors.electricBlue4,
  'D+5': colors.electricBlue5,
  'D+6': colors.electricBlue6,
  Even: colors.platinum,
  'R+1': colors.errorRed1,
  'R+2': colors.errorRed2,
  'R+3': colors.errorRed3,
  'R+4': colors.errorRed4,
  'R+5': colors.errorRed5,
  'R+6': colors.errorRed6,
  'Lean D': colors.electricBlue,
  'Likely D': colors.electricBlue7,
  'Slight D': colors.electricBlue1,
  'Toss Up': colors.platinum,
  'Slight R': colors.errorRed7,
  'Likely R': colors.errorRed8,
  'Lean R': colors.errorRed6,
};

export default colors;
