import styled from 'styled-components/macro';
import { Row } from 'components/Row';
import { Body, H3 } from 'components/Typography';

export const Content = styled.div`
  text-align: center;
  display: grid;
  padding: 0 16px 16px 16px;
`;

export const Title = styled(H3)`
  color: ${({ theme }) => theme.colors.black};
  padding-bottom: 16px;
`;

export const Subtitle = styled(Body)`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.fonts.regular};
`;

export const Description = styled(Subtitle)`
  padding-top: 8px;
`;

export const DonateContainer = styled(Row)`
  color: ${({ theme }) => theme.colors.shaft};
  flex-direction: column;
  flex-grow: 1;
  ${({ theme }) => theme.fonts.regular};
  margin: 0 auto;
  max-width: 800px;
  padding: 27px 23px 9px;
  @media (max-width: 800px) {
    padding: 16px 15px 38px;
  }
`;
