import Column from 'components/Column';
import styled from 'styled-components/macro';
import { H2Text, H4 } from 'components/Typography';

export const Content = styled(Column)`
  background: ${({ theme }) => theme.colors.white};
  padding: 60px 120px;

  @media (min-width: 801px) and (max-width: 1200px) {
    padding: 80px;
  }
  @media (max-width: 800px) {
    padding: 60px 25px;
  }
`;

export const Title = styled(H2Text)`
  @media (max-width: 1000px) {
    font-size: 30px;
    line-height: 33px;
  }
`;

export const Description = styled(H4)`
  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Video = styled.video`
  width: 100%;
  max-width: 900px;
  height: auto;
  justify-self: center;
  border-radius: 12px;
`;

export const ButtonContainer = styled.div`
  padding: 40px 0;
  @media (max-width: 1000px) {
    display: grid;
  }
`;
