import fonts from './fonts';
import colors from './colors';
import shadows from './shadows';
import gradients from './gradients';

const theme = {
  fonts,
  colors,
  shadows,
  gradients,
};

export default theme;
