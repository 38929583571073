import { FC, ReactNode } from 'react';
import { Container, OuterGradientBox, Wrapper } from './styled';

interface Props {
  borderType: 'top' | 'full';
  width?: string;
  borderSize?: number;
  margin?: string;
  gradient?: string;
  withShadow?: boolean;
  overflow?: string;
  children: ReactNode;
}

export const GradientBorderCard: FC<Props> = ({
  borderType,
  width,
  borderSize,
  margin,
  gradient,
  withShadow = true,
  overflow,
  children,
}) => {
  return (
    <Wrapper $width={width} $withShadow={withShadow} $overflow={overflow}>
      {borderType === 'full' ? (
        <OuterGradientBox $borderSize={borderSize} $margin={margin} $gradient={gradient}>
          {children}
        </OuterGradientBox>
      ) : (
        <Container $borderSize={borderSize} $gradient={gradient}>
          {children}
        </Container>
      )}
    </Wrapper>
  );
};
