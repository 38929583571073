import { formatNumberAsCurrency, roundNumberToHundredths } from 'helpers/utils';
import { Input, NumberInput } from './styled';
import { ChangeEvent, FC, useEffect, useState } from 'react';

const format = (value?: number) => {
  if (value === undefined) {
    return '';
  }
  return formatNumberAsCurrency(value, {
    trailingZeroDisplay: 'auto',
  });
};

interface Props {
  id: string;
  validate?: (value?: number) => string | undefined;
  value?: number;
  onChange: (value?: number) => void;
}

export const CurrencyInput: FC<Props> = ({ id, validate, value, onChange }) => {
  const [isNumberInputVisible, setIsNumberInputVisible] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const activateInput = () => {
    setIsNumberInputVisible(true);
  };

  useEffect(() => {
    if (isNumberInputVisible) {
      document.getElementById(id)?.focus();
    }
  }, [isNumberInputVisible]);

  const onChangeRound = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value ? roundNumberToHundredths(e.target.value) : 0;
    onChange(newValue);
    validate && setError(validate(newValue));
  };

  return (
    <>
      {isNumberInputVisible ? (
        <NumberInput
          className={error && 'error'}
          type="number"
          id={id}
          value={value || ''}
          onChange={onChangeRound}
          onBlur={() => {
            setIsNumberInputVisible(false);
          }}
        />
      ) : (
        <Input
          className={error && 'error'}
          type="text"
          value={format(value)}
          onClick={activateInput}
          readOnly
        />
      )}
    </>
  );
};
