import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import { getPathWithRef } from 'helpers/utils';
import { useAppSelector } from 'helpers/hooks';
import { getLandingConfig } from 'selectors/siteConfig';
import {
  GetStartedSectionContainer,
  H2,
  LandingButton,
  SectionContent,
  SectionImage,
  SectionImageContainer,
  SectionRow,
  SectionSubheader,
} from './styled';

const image = `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/landing_images/oath-image1.png`;

export const GetStartedSection = () => {
  const landingImpactConfig = useAppSelector(state => getLandingConfig(state, 'impact_card'));

  return (
    <GetStartedSectionContainer>
      <Row $justifyContent="space-around">
        <div>
          <SectionRow $justifyContent="space-between" $alignItems="center">
            <SectionContent>
              {landingImpactConfig?.impact_card_config !== 'on' ? (
                <>
                  <H2>Maximize the impact of your donations</H2>
                  <SectionSubheader>
                    We analyze thousands of elections from President to school board to find which
                    candidates need your support the most. We provide our recommendations for free
                    and we don’t sell or share your contact information.
                  </SectionSubheader>
                  <LandingButton href={getPathWithRef('/donate?p=mx')}>
                    Support Oath’s Top 5
                  </LandingButton>
                </>
              ) : (
                <>
                  <H2>We are your trusted political advisor</H2>
                  <SectionSubheader>
                    With a 0% platform fee and the lowest credit card processing in the industry -
                    send the most amount of money to the candidates that need it most.
                  </SectionSubheader>
                  <LandingButton href={getPathWithRef('/login')}>Get started</LandingButton>
                </>
              )}
            </SectionContent>
            <SectionImageContainer>
              <SectionImage src={image} />
            </SectionImageContainer>
          </SectionRow>
        </div>
      </Row>
    </GetStartedSectionContainer>
  );
};
