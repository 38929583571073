import { FC } from 'react';
import { CompactSlate } from './CompactSlate';
import { ListSlate } from './ListSlate';
import { ITag, TagPageType } from 'store/tags/types';
import { CandidateType } from './Candidates/Candidates';
import { IRecipient } from 'store/recipients/types';
import { getUrlParams } from 'helpers/utils';
import { MediaSlate } from 'screens/MediaSlate';

interface Props {
  allTags: ITag[];
  tagsFullList: ITag[];
  candidates: CandidateType[];
  onDonateClick: () => void;
  onSelectCandidates: (updatedCandidates: CandidateType[]) => void;
  onUserDonationTotalChange: (value?: number) => void;
  recipients: IRecipient[];
  refWidthValue: number;
  selectedDonationAmount?: number;
  setCandidates: React.Dispatch<React.SetStateAction<CandidateType[]>>;
  setSelectedDonationAmount: React.Dispatch<React.SetStateAction<number | undefined>>;
  showCustomSplit: boolean;
  type: TagPageType;
}

export const Slate: FC<Props> = ({ refWidthValue, type, ...slateProps }) => {
  const { urlPartner } = getUrlParams();

  if (urlPartner && type === 'COMPACT') {
    return <CompactSlate {...slateProps} />;
  } else if (urlPartner && type === 'MEDIA') {
    return <MediaSlate {...slateProps} />;
  }
  return <ListSlate refWidthValue={refWidthValue} {...slateProps} />;
};
