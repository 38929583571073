import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const siteConfigStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const siteConfigSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const siteConfigError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const siteConfigCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.SITE_CONFIG_START]: siteConfigStart,
  [Types.SITE_CONFIG_SUCCESS]: siteConfigSuccess,
  [Types.SITE_CONFIG_ERROR]: siteConfigError,
  [Types.SITE_CONFIG_CLEAN_UP]: siteConfigCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
