import Column from 'components/Column';
import styled from 'styled-components/macro';

export const Container = styled(Column)`
  background: ${({ theme }) => theme.colors.softGray};
  border-radius: 8px;
  padding: 25px 30px;
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};
  width: 380px;

  @media (max-width: 900px) {
    min-width: fit-content;
  }
`;
