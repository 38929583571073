import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { DETokenResponseData, DEState } from 'store/democracyengine/types';

export const getDEState = (state: RootState): DEState => state.democracyEngine;

export const getDETokensById = createSelector(
  [getDEState],
  (state: DEState): { [token: string]: DETokenResponseData } => state.data.deTokensById
);
