import { AxiosError } from 'axios';
import { noop } from './utils';

export const getMessageFromAxiosError = (error: AxiosError) => {
  let message = '';
  try {
    message = `${error.message} ${JSON.stringify(error.response?.data || '')}`;
  } catch (e) {
    noop();
  }
  return message;
};
