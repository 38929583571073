import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const subscriptionsStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const subscriptionsSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const subscriptionsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const subscriptionsCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.SUBSCRIPTIONS_START]: subscriptionsStart,
  [Types.SUBSCRIPTIONS_SUCCESS]: subscriptionsSuccess,
  [Types.SUBSCRIPTIONS_ERROR]: subscriptionsError,
  [Types.SUBSCRIPTIONS_CLEAN_UP]: subscriptionsCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
