import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { PaymentsData, PaymentsState } from 'store/payments/types';

export const getPaymentsState = (state: RootState): PaymentsState => state.payments;

export const getPaymentsData = createSelector(
  [getPaymentsState],
  (state: PaymentsState): PaymentsData[] => state.data.existingPayments
);

export const getNewPaymentsById = createSelector(
  [getPaymentsState],
  (state: PaymentsState): { [token: string]: PaymentsData } => state.data.newPaymentsById
);
