import { useState } from 'react';
import { Field } from 'react-final-form';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import { BodySmall } from 'components/Typography';
import Icon, { IconNames } from 'components/Icon';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { CadenceSelectContainer } from './styled';
import { IEmailCadence, emailCadenceDictionary } from '../constants';
import { getObjectDisplayName } from 'helpers/utils';

const getToggleIconStyles = (showOptions: boolean) => ({
  background: showOptions ? theme.colors.oathBlue : theme.colors.inkBlue,
  borderRadius: '30px',
  cursor: 'pointer',
});

const getInactiveOptions = (activeEmailCadence: string) => {
  return Object.keys(emailCadenceDictionary).reduce((inactiveOptions, emailCadenceOption) => {
    if (activeEmailCadence !== emailCadenceOption) {
      return [...inactiveOptions, emailCadenceOption];
    }
    return inactiveOptions;
  }, [] as string[]);
};

export const CadenceSelectField = ({ getState, userId }) => {
  const [showOptions, setShowOptions] = useState(false);
  const state = getState();
  const activeEmailCadence = state.values.email_cadence;

  const onSelectToggle = () => {
    setShowOptions(showOptions => !showOptions);
  };

  const onHideOptions = e => {
    logFirebaseEvent('profile_update_cadence', {
      timestamp: new Date().toString(),
      user_id: userId,
      email_cadence: e.target.value,
    });
    setShowOptions(false);
  };

  return (
    <CadenceSelectContainer $isActive={showOptions}>
      <Row $justifyContent="space-between" $alignItems="center">
        <BodySmall>
          {getObjectDisplayName<IEmailCadence>(activeEmailCadence, emailCadenceDictionary)}
        </BodySmall>
        <Icon
          name={showOptions ? IconNames.ChevronUp : IconNames.ChevronDown}
          color={theme.colors.white}
          onClick={onSelectToggle}
          size={30}
          style={getToggleIconStyles(showOptions)}
        />
      </Row>
      <Spacing $size={3} />
      {showOptions && (
        <>
          {getInactiveOptions(activeEmailCadence).map(inactiveOption => (
            <label key={inactiveOption}>
              <Field
                name="email_cadence"
                component="input"
                type="radio"
                hidden
                onClick={onHideOptions}
                value={inactiveOption}
              />
              <div>
                {getObjectDisplayName<IEmailCadence>(inactiveOption, emailCadenceDictionary)}
              </div>
            </label>
          ))}
        </>
      )}
    </CadenceSelectContainer>
  );
};
