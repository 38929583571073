import React from 'react';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Input from 'components/Input';
import Spacing from 'components/Spacing';
import { PopupModal } from 'components/PopupModal';
import { ButtonBrand } from 'components/ButtonBrand';
import { formatNumber } from 'helpers/utils';
import { useAppSelector } from 'helpers/hooks';
import { useShareUrl } from 'hooks/useShareUrl';
import { getUserData } from 'selectors/user';
import { getTransactionsNetworkSummary } from 'selectors/transactions';
import {
  BigText,
  LeftContent,
  RightContent,
  RowContainer,
  SmallText,
  Bubble,
  ReferralCard,
  MixedSpacing,
} from './styled';
import { H4 } from 'components/Typography';

export const Network = () => {
  const { userData, transactionsNetworkSummary } = useAppSelector(state => ({
    userData: getUserData(state),
    transactionsNetworkSummary: getTransactionsNetworkSummary(state),
  }));
  const { handleShare, baseUrlWithRefCode, isPopupOpen } = useShareUrl(userData?.ref_code);

  const bubbles = Array.from(
    { length: transactionsNetworkSummary?.referred_count },
    (_, index) => index + 1
  ).slice(0, 10);

  return (
    <RowContainer>
      <LeftContent $width={'50%'} $withBorder>
        <Row>
          <BigText color={theme.colors.oathBlue}>
            {transactionsNetworkSummary?.referred_count || 0}
          </BigText>
          <Row $alignItems="center">
            <Spacing $isVertical $size={4} />
            {bubbles.map((size, index) => (
              <React.Fragment key={index}>
                <Bubble key={size} $size={size} />
                <Spacing $isVertical $size={4} />
              </React.Fragment>
            ))}
          </Row>
        </Row>
        <SmallText color={theme.shadows.black(0.5)}>Friends joined!</SmallText>
        <Spacing $size={16} />
        <Row>
          <LeftContent $width={'50%'}>
            <BigText>
              ${formatNumber(transactionsNetworkSummary?.total_amount_donated || 0)}
            </BigText>
            <SmallText color={theme.shadows.black(0.5)}>Donated by your network</SmallText>
          </LeftContent>
          <RightContent $width={'50%'}>
            <BigText>{transactionsNetworkSummary?.total_candidates || 0}</BigText>
            <SmallText color={theme.shadows.black(0.5)}>Candidates supported</SmallText>
          </RightContent>
        </Row>
      </LeftContent>
      <RightContent $width={'50%'} $withBorder>
        <ReferralCard>
          <SmallText color={theme.colors.white}>Referral Code</SmallText>
          <H4 $color={theme.colors.white}>Maximize Your Impact</H4>
          <SmallText color={theme.colors.white}>
            Invite your friends so they can get their personal recommendation.
          </SmallText>
          <Spacing $size={16} />
          <RowContainer>
            <Input id="textBoxValue" value={baseUrlWithRefCode} fullWidth disabled />
            <MixedSpacing />
            <ButtonBrand onClick={handleShare} $backgroundColor={theme.colors.inkBlue}>
              COPY
            </ButtonBrand>
          </RowContainer>
        </ReferralCard>
      </RightContent>
      <PopupModal autoClose={true} isSelected={isPopupOpen} title="Copied to Clipboard" />
    </RowContainer>
  );
};
