import { ReactNode } from 'react';
import { TableContainer, Th, DataTable, Tbody } from './styled';

type Column = {
  key: string;
  name: string;
  formatFn?: (arg1: any, arg2: any) => ReactNode;
  width?: string;
  className?: string;
};

type CustomTableProps = {
  columns: Column[];
  data: object[];
  hideForLarge?: boolean;
  hideForSmall?: boolean;
};

export const CustomTable = ({ columns, data, hideForLarge, hideForSmall }: CustomTableProps) => {
  return (
    <TableContainer $hideForLarge={hideForLarge} $hideForSmall={hideForSmall}>
      <DataTable>
        <thead>
          <tr>
            {columns.map(column => (
              <Th
                key={column.key}
                style={column.width ? { width: column.width } : {}}
                className={column.className || ''}
              >
                {column.name}
              </Th>
            ))}
          </tr>
        </thead>
        <Tbody>
          {data?.map((row, rowIndex) => (
            <tr key={rowIndex} className={row['className'] || ''}>
              {columns.map((column, keyIndex) => (
                <td key={keyIndex} className={column.className || ''}>
                  {!row[column.key]
                    ? '-'
                    : column.formatFn
                    ? column.formatFn(row[column.key], row)
                    : row[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </Tbody>
      </DataTable>
    </TableContainer>
  );
};
