import axios from 'axios';
import { Dispatch } from 'redux';
import USER_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getRefValue, getSiteAccessToken } from 'services/storage';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const loginUser =
  (accessToken: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(USER_TYPES.userStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.USER_LOGIN}`;
    const referred_by = getRefValue();
    const body = referred_by ? { referred_by } : {};

    try {
      const response = await axios.post(url, body, {
        headers: { ...headers, Authorization: `Bearer ${accessToken}` },
      });
      dispatch(USER_TYPES.userSuccess(response.data));
    } catch (error: any) {
      dispatch(USER_TYPES.userError(error.message));
    }
  };

export const getUserProfile =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(USER_TYPES.userStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.USER_PROFILE}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
      });
      dispatch(USER_TYPES.userProfileSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(USER_TYPES.userProfileError(error.message));
      return Promise.reject(error);
    }
  };

export const updateUserProfile =
  body =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(USER_TYPES.userStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.USER_PROFILE}`;

    try {
      const response = await axios.patch(url, body, {
        headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
      });
      dispatch(USER_TYPES.userProfileSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(USER_TYPES.userProfileError(error.message));
      return Promise.reject(error);
    }
  };
