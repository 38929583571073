import { DesktopLeftContainer } from 'screens/Main/DesktopLeftContainer';
import { MainContainer } from 'screens/Main/styled';
import { CheckoutContainer } from './styled';
import { CheckoutForm } from './Form';
import { ContributionRules } from 'components/Slate/ContributionRules';
import { noop } from 'helpers/utils';
import Spacing from 'components/Spacing';

export const CheckoutFlow = ({ candidates, donationAmount, payments, user }) => {
  return (
    <MainContainer>
      <DesktopLeftContainer setRefWidthValue={noop} />
      <CheckoutContainer>
        <CheckoutForm
          candidates={candidates}
          donationAmount={donationAmount}
          payments={payments}
          user={user}
        />
        <Spacing $size={70} />
        <ContributionRules />
        <Spacing $size={48.5} />
      </CheckoutContainer>
    </MainContainer>
  );
};
