import { FC, ReactNode } from 'react';
import { GradientBorderCard } from '../GradientBorderCard';
import { Content, FloatingWrapper } from './styled';
import theme from 'config/theme';

interface Props {
  children: ReactNode;
}

export const SettingsCard: FC<Props> = ({ children }) => {
  return (
    <FloatingWrapper>
      <GradientBorderCard borderType="top" gradient={theme.gradients.inkToDarkGradient({})}>
        <Content>{children}</Content>
      </GradientBorderCard>
    </FloatingWrapper>
  );
};
