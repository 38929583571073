import { ENV_CONFIG } from 'config/environment';
import { FC, ImgHTMLAttributes, useEffect, useState } from 'react';

export const DEFAULT_FALLBACKS = {
  candidate: `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/no-image-profile.png`,
  candidate_square: `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/no-image-profile-square.png`,
};

const getFallbackImgFromType = (type?: ImageType) => {
  if (type && Object.keys(DEFAULT_FALLBACKS).includes(type)) {
    return DEFAULT_FALLBACKS[type];
  }
  return;
};

type ImageType = 'candidate' | 'candidate_square';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  alt: string;
  fallbackImgSrc?: string;
  fallbackImgType?: ImageType;
  fallbackPlaceholder?: React.ReactNode;
}

export const Image: FC<Props> = ({
  fallbackImgSrc,
  fallbackImgType,
  fallbackPlaceholder,
  src,
  ...imgProps
}) => {
  const [showImage, setShowImage] = useState(true);
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const onError = () => {
    const fallbackImg = fallbackImgSrc || getFallbackImgFromType(fallbackImgType);
    if (fallbackImg && imgSrc !== fallbackImg) {
      setImgSrc(fallbackImg);
      return;
    }
    setShowImage(false);
  };

  if (!showImage || !imgSrc) {
    if (fallbackPlaceholder) {
      return <>{fallbackPlaceholder}</>;
    }
    return null;
  }

  return <img {...imgProps} src={imgSrc} onError={onError} />;
};
