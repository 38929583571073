import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const setsStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const setsSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const setsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const setsCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.SETS_START]: setsStart,
  [Types.SETS_SUCCESS]: setsSuccess,
  [Types.SETS_ERROR]: setsError,
  [Types.SETS_CLEAN_UP]: setsCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
