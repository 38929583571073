import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { IUserData, UserState } from 'store/user/types';

export const getUserState = (state: RootState): UserState => state.user;

export const getUserData = createSelector(
  [getUserState],
  (state: UserState): IUserData => state?.data
);
