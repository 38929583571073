import Column from 'components/Column';
import { Image } from 'components/Image';
import { Body, BodySmall } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled(Column)<{ $width?: string }>`
  width: ${({ $width }) => $width || 'min-content'};
  padding: 0 8px;
  display: block;

  @media (min-width: 320px) and (max-width: 475px) {
    width: 40%;
  }
`;

export const Label = styled(Body)`
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.fonts.regular};
`;

export const Title = styled(BodySmall)`
  ${({ theme }) => theme.fonts.semiBold};
  color: ${({ theme }) => theme.colors.black};
  width: 50px;
`;

export const StyledImage = styled(Image)`
  height: 180px;
  width: 180px;
  border-radius: 90px;
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};

  @media (max-width: 800px) {
    height: 120px;
    width: 120px;
  }
  @media (max-width: 500px) {
    height: 90px;
    width: 90px;
  }
`;
