export const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
  detailed: null,
  detailedById: {
    data: {},
    isLoading: false,
    error: null,
  },
};
