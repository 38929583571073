import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Eyebrow } from 'components/Typography';
import {
  PoweredBySectionContainer,
  PartnerLogoImage,
  PartnerLogoImageDE,
  PartnerLogos,
} from './styled';

const baseImgUrl = `${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/partner_logos`;

export const PoweredBySection = () => {
  return (
    <PoweredBySectionContainer>
      <Eyebrow $color={theme.colors.inkBlue}>Oath Partners</Eyebrow>
      <PartnerLogos $justifyContent="space-between" $alignItems="center">
        <PartnerLogoImage src={`${baseImgUrl}/Ballotpedia_logo.png`} />
        <PartnerLogoImage src={`${baseImgUrl}/Catalist_logo.png`} />
        <PartnerLogoImageDE src={`${baseImgUrl}/DemocracyEngine_logo.png`} />
      </PartnerLogos>
    </PoweredBySectionContainer>
  );
};
