import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { GalleryData, GalleryState } from 'store/gallery/types';

export const getGalleryState = (state: RootState): GalleryState => state.gallery;

export const getGalleryData = createSelector(
  [getGalleryState],
  (state: GalleryState): GalleryData[] => state?.data
);
