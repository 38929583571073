export interface ITabIssue {
  rr: { value: string; query_str: string };
  dt: { value: string; query_str: string };
  pd: { value: string; query_str: string };
  fh: { value: string; query_str: string };
}

export const tabIssues = {
  rr: { value: 'Reproductive Rights', query_str: 'monthly-rr' },
  dt: { value: 'Defeat Trump', query_str: 'monthly-dt' },
  pd: { value: 'Protect Democracy', query_str: 'monthly-pd' },
  fh: { value: 'Flip the House', query_str: 'monthly-fh' },
  infra: { value: 'Monthly Infrastructure', query_str: 'monthly-infra' },
};

export const TAB_ISSUES_OPTIONS = [
  {
    label: 'Reproductive Rights',
    value: 'rr',
  },
  {
    label: 'Defeat Trump',
    value: 'dt',
  },
  {
    label: 'Protect Democracy',
    value: 'pd',
  },
  {
    label: 'Flip the House',
    value: 'fh',
  },
];

export const listItems = [
  {
    id: 1,
    title: 'Choose an Issue',
    subtitle: 'Select the issue you would like to impact to get started.',
  },
  {
    id: 2,
    title: 'Choose an Amount',
    subtitle: 'Set the budget for your monthly recurring donation',
  },
  {
    id: 3,
    title: 'Make an Impact',
    subtitle: 'Oath will direct your dollars to the races where they are most needed',
  },
  {
    id: 4,
    title: 'Confirm Updates',
    subtitle:
      'When a race is fully funded or it’s time to make a change, we will ask you to confirm your new recommendations',
  },
];
