import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { TSummary, TSubscription, SubscriptionsState } from 'store/subscriptions/types';

export const getSubscriptionsState = (state: RootState): SubscriptionsState => state.subscriptions;

export const getSubscriptionsData = createSelector(
  [getSubscriptionsState],
  (state: SubscriptionsState): TSubscription[] => state?.data?.subscriptions
);

export const getSubscriptionsSummary = createSelector(
  [getSubscriptionsState],
  (state: SubscriptionsState): TSummary => state?.data?.summary
);
