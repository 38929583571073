import theme from 'config/theme';
import Spacing from 'components/Spacing';
import { CustomTable } from 'components/CustomTable';
import {
  BigText,
  RowContainer,
  LeftContent,
  SmallText,
  RecurringFooterRow,
  RecurringFooterText,
} from './styled';
import { formatNumberAsCurrency } from 'helpers/utils';
import { BodySmall } from 'components/Typography';
import { FC, useState } from 'react';
import { Link } from 'components/Link';
import { CancelSubscriptionModal } from './CancelSubscriptionModal';
import { TSubscription } from 'store/subscriptions/types';
import { getColumnsMonthly, getColumnsMonthlyMobile } from './utils';

interface Props {
  subscriptions: Array<TSubscription>;
  totalActiveSubscriptions: number;
  totalAmount: number;
}

export const MonthlyRecurringDonations: FC<Props> = ({
  subscriptions,
  totalAmount,
  totalActiveSubscriptions,
}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [subscriptionToDelete, setSubscriptionToDelete] = useState<TSubscription | undefined>();

  const handleDeleteModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setSubscriptionToDelete(undefined);
  };

  const getDeleteModalOpenHandler = (subscription: TSubscription) => {
    return () => {
      setSubscriptionToDelete(subscription);
      handleDeleteModalOpen();
    };
  };

  const formattedSubscriptions = subscriptions.map(subscription => ({
    ...subscription,
    next_transaction_date: subscription.next_transaction_date || 'NA',
    className: subscription.state !== 'active' ? 'disabled' : '',
  }));

  return (
    <>
      <CancelSubscriptionModal
        isOpen={isDeleteModalOpen}
        handleCloseModal={handleDeleteModalClose}
        subscription={subscriptionToDelete}
      />
      <RowContainer>
        <LeftContent $width={'50%'}>
          <BigText>{formatNumberAsCurrency(totalAmount, { trailingZeroDisplay: 'auto' })}</BigText>
          <SmallText color={theme.shadows.black(0.5)}>Total monthly donation amount</SmallText>
        </LeftContent>
      </RowContainer>
      <Spacing $size={16} />
      <CustomTable
        hideForSmall={true}
        columns={getColumnsMonthly(getDeleteModalOpenHandler, totalActiveSubscriptions)}
        data={formattedSubscriptions}
      />
      <CustomTable
        hideForLarge={true}
        columns={getColumnsMonthlyMobile(getDeleteModalOpenHandler, totalActiveSubscriptions)}
        data={formattedSubscriptions}
      />
      <RecurringFooterRow>
        <RecurringFooterText>
          <BodySmall $color={theme.colors.black}>
            Want to add another subscription?&nbsp;
            <Link to="/monthly" $fontSize="inherit">
              Click here
            </Link>
          </BodySmall>
        </RecurringFooterText>
        <RecurringFooterText>
          <BodySmall $color={theme.colors.black}>
            If you would like to modify your monthly donations, please email{' '}
            <Link href="mailto:monthly@oath.vote" $fontSize="inherit">
              monthly@oath.vote
            </Link>
            .
          </BodySmall>
        </RecurringFooterText>
      </RecurringFooterRow>
    </>
  );
};
