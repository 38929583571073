import { FC, useState } from 'react';
import { CandidateType } from '../Candidates/Candidates';
import { BodySmall, UtilityText } from 'components/Typography';
import { CurrencyInput } from './CurrencyInput';
import { RecipientInputRow } from './styled';
import theme from 'config/theme';
import { formatNumberAsCurrency } from 'helpers/utils';
import { MAX_DONATION } from 'helpers/donate';

interface Props {
  candidate: CandidateType;
  onChangeAmount: (candidate: CandidateType, amount: number, index: number) => void;
  index: number;
}

export const RecipientRow: FC<Props> = ({ candidate, onChangeAmount, index }) => {
  const [error, setError] = useState<string | undefined>();

  const onChange = (value?: number) => {
    onChangeAmount(candidate, value || 0, index);
  };

  const getValidateFn = (contributionLimit: number) => {
    return (amount?: number) => {
      let errorStr;
      if (!amount) {
        setError(undefined);
        return;
      }
      if (contributionLimit && amount > contributionLimit) {
        errorStr = 'Please enter a lower amount.';
      }
      if (amount > MAX_DONATION) {
        errorStr = `Please contact support if you would like to donate ${formatNumberAsCurrency(
          MAX_DONATION
        )} or more.`;
      }
      setError(errorStr);
      return errorStr;
    };
  };

  if (!candidate.isSelected) {
    return null;
  }

  return (
    <RecipientInputRow $justifyContent="space-between" $alignItems="center">
      <div>
        <BodySmall $color={error && theme.colors.errorRed}>
          {`${candidate.recipient_name} (${candidate.display_info})`}
        </BodySmall>
        <div>
          {candidate.contribution_limit && (
            <UtilityText
              $fontType="regular"
              $color={error ? theme.shadows.errorRed(0.7) : theme.shadows.black(0.5)}
            >
              Contribution limit {formatNumberAsCurrency(Number(candidate.contribution_limit))}
              {error && '. '}
            </UtilityText>
          )}
          {error && <UtilityText $color={theme.shadows.errorRed(0.7)}>{error}</UtilityText>}
        </div>
      </div>
      <CurrencyInput
        id={`${candidate.recipient_id}-custom-amount-input`}
        validate={getValidateFn(Number(candidate.contribution_limit || 0))}
        value={candidate.donationAmount}
        onChange={onChange}
      />
    </RecipientInputRow>
  );
};
