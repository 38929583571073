import { createActions } from 'reduxsauce';

export interface RecommendationsState {
  data: RecommendationsData[];
  isLoading: boolean;
  error: string;
}

export type RecommendationsData = {
  value: string;
  tag_cta: string;
  query_str: string;
  desc: string;
};

export interface RecommendationsTypes {
  RECOMMENDATIONS: 'RECOMMENDATIONS';
  RECOMMENDATIONS_START: 'RECOMMENDATIONS_START';
  RECOMMENDATIONS_SUCCESS: 'RECOMMENDATIONS_SUCCESS';
  RECOMMENDATIONS_ERROR: 'RECOMMENDATIONS_ERROR';
  RECOMMENDATIONS_CLEAN_UP: 'RECOMMENDATIONS_CLEAN_UP';
}

const { Types, Creators } = createActions<RecommendationsTypes>({
  recommendations: ['data'],
  recommendationsStart: null,
  recommendationsSuccess: ['data'],
  recommendationsError: ['error'],
  recommendationsCleanUp: null,
});

export { Types };

export default Creators;
