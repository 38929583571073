import theme from 'config/theme';
import { Row } from 'components/Row';
import Column from 'components/Column';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { ENV_CONFIG } from 'config/environment';
import { CustomSlider } from 'components/Slider';
import LottieOrbs from 'components/LottieElements/Orbs';
import { ButtonBrand } from 'components/ButtonBrand';
import { BodySmall, Eyebrow, H4 } from 'components/Typography';
import { useAppSelector } from 'helpers/hooks';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { getIsFeatureOn, IMPACT_REPORT, IMPACT_REPORT_SOON } from 'helpers/featureFlags';
import { formatNumberAsCurrency, getUrlParams, isKeyInObject } from 'helpers/utils';
import { tabIssues } from 'screens/Monthly/constants';
import { getUserData } from 'selectors/user';
import { getTransactionsSummary } from 'selectors/transactions';
import { getRecommendationsData } from 'selectors/recommendations';
import { BigText, RowContainer, LeftContent, RightContent, SmallText } from './styled';
import { getImpactReportData } from 'selectors/impactReport';
import { getProfileConfig } from 'selectors/siteConfig';

const DEFAULT_RECOMMENDATION_BUDGET = 50;

export const Impact = ({ viewImpactReport }) => {
  const { urlRef } = getUrlParams();
  const ref_code = urlRef ? `&ref=${urlRef}-PRD` : '&ref=PRD';
  const { userData, recommendations, summary, impactReportData, profileConfig } = useAppSelector(
    state => ({
      userData: getUserData(state),
      recommendations: getRecommendationsData(state),
      summary: getTransactionsSummary(state),
      impactReportData: getImpactReportData(state),
      profileConfig: getProfileConfig(state, 'profile_impact'),
    })
  );

  const goal = userData?.monthly_budget * 12;

  const getUrlMonthlyParams = () => {
    let path = '';
    if (isKeyInObject(recommendations[0].query_str, tabIssues)) {
      path = `${path}?issue=${recommendations[0].query_str}`;
      if (userData?.monthly_budget) {
        path = `${path}&amount=${userData?.monthly_budget?.toString()}`;
      }
    }
    return path;
  };

  const handleClick = () => {
    logFirebaseEvent('donate_intent_profile', {
      timestamp: new Date().toString(),
      user_id: userData.id,
      tag_id:
        recommendations?.length > 0
          ? profileConfig?.profile_impact_query_str || recommendations[0].query_str
          : '',
      recommended_amount: userData?.monthly_budget || 0,
    });
  };

  const getRecommendedDonationAmount = () => {
    if (!userData || !userData.monthly_budget || Number(userData.monthly_budget) === 0) {
      return DEFAULT_RECOMMENDATION_BUDGET;
    }
    return Number(userData.monthly_budget);
  };

  const getShouldUseImpactReportData = () => getIsFeatureOn(IMPACT_REPORT) && !!impactReportData;
  const getTotalCandidates = () => {
    const totalCandidates = getShouldUseImpactReportData()
      ? impactReportData.recipient_count
      : summary?.total_candidates_ytd;
    return totalCandidates || '-';
  };
  const getTotalAmountDonated = () =>
    getShouldUseImpactReportData()
      ? parseFloat(impactReportData.donation_amount_total)
      : summary?.total_amount_donated_ytd || 0;

  return (
    <>
      <RowContainer>
        <LeftContent
          $width={'55%'}
          $withBorder={!getIsFeatureOn(IMPACT_REPORT) && !getIsFeatureOn(IMPACT_REPORT_SOON)}
          $needsPadding={getIsFeatureOn(IMPACT_REPORT) || getIsFeatureOn(IMPACT_REPORT_SOON)}
        >
          <Spacing $size={10} />
          <Row>
            <Column $width={'50%'}>
              <BigText>
                {formatNumberAsCurrency(getTotalAmountDonated(), {
                  trailingZeroDisplay: 'auto',
                })}
              </BigText>
              <SmallText color={theme.shadows.black(0.5)}>Donated this cycle</SmallText>
            </Column>
            <Column>
              <BigText>{getTotalCandidates()}</BigText>
              <SmallText color={theme.shadows.black(0.5)}>Candidates supported</SmallText>
            </Column>
          </Row>
          <Spacing $size={32} />
          {!!goal && (
            <CustomSlider
              fullWidth
              options={[
                { key: 0, label: '0%' },
                { key: goal / 4, label: '25%' },
                { key: goal / 2, label: '50%' },
                { key: (3 * goal) / 4, label: '75%' },
                { key: goal, label: '100%' },
              ]}
              value={getTotalAmountDonated()}
              disabled
            />
          )}
          <Spacing $size={16} />
        </LeftContent>
        {getIsFeatureOn(IMPACT_REPORT_SOON) ? (
          <RightContent $width="40%" $withBorder={!getIsFeatureOn(IMPACT_REPORT_SOON)}>
            <LottieOrbs>
              <Column>
                <Eyebrow $color={theme.colors.white}>Coming soon</Eyebrow>
                <Spacing $size={10} />
                <H4 $color={theme.colors.white}>Your 2024 Oath Impact Report</H4>
                <BodySmall $color={theme.colors.white}>
                  Discover how your donations affected the 2024 election cycle. Sign up to be
                  notified when your personal impact report is ready!
                </BodySmall>
                <Spacing $size={18} />
                <Column $justifyItems="center">
                  <ButtonBrand
                    to="/forms/notify-impact-report"
                    $secondary
                    $width="-webkit-fill-available"
                  >
                    notify me
                  </ButtonBrand>
                </Column>
              </Column>
            </LottieOrbs>
          </RightContent>
        ) : getIsFeatureOn(IMPACT_REPORT) ? (
          <RightContent $width="40%" $withBorder={!getIsFeatureOn(IMPACT_REPORT)}>
            <LottieOrbs>
              <Column>
                <Eyebrow $color={theme.colors.white}>NEW!</Eyebrow>
                <Spacing $size={10} />
                <H4 $color={theme.colors.white}>Your 2024 Oath Impact Report</H4>
                <BodySmall $color={theme.colors.white}>
                  Your donations made a huge impact in this year’s election. Check it out!
                </BodySmall>
                <Spacing $size={18} />
                <Column $justifyItems="center">
                  <ButtonBrand onClick={viewImpactReport} $secondary $width="100%">
                    View Now
                  </ButtonBrand>
                  <Spacing $size={8} />
                  <Link to="/impact_report" $color={theme.colors.white}>
                    View Oath’s Community Report
                  </Link>
                </Column>
              </Column>
            </LottieOrbs>
          </RightContent>
        ) : (
          <RightContent $width="40%" $withBorder>
            <SmallText color={theme.colors.inkBlue}>
              {profileConfig?.profile_impact_label}
            </SmallText>
            {recommendations?.length > 0 && (
              <>
                <Spacing $size={10} />
                <H4 $color={theme.colors.oathBlue}>
                  {formatNumberAsCurrency(getRecommendedDonationAmount(), {
                    trailingZeroDisplay: 'auto',
                  })}{' '}
                  {profileConfig?.profile_impact_amount_to}
                </H4>
                <Spacing $size={4} />
                <SmallText color={theme.colors.inkBlue}>
                  {profileConfig?.profile_impact_desc}
                </SmallText>
                <Spacing $size={12} />
                <Row $justifyContent="space-between">
                  <ButtonBrand
                    href={`${ENV_CONFIG().SITE_URL}/donate?p=${
                      profileConfig?.profile_impact_query_str || recommendations[0].query_str
                    }${ref_code}`}
                    target="_blank"
                    onClick={handleClick}
                    $secondary
                    $hasBorder
                    $width="100%"
                  >
                    {profileConfig?.profile_impact_button}
                  </ButtonBrand>
                  {profileConfig?.profile_impact_monthly === 'on' && (
                    <>
                      <Spacing $isVertical $size={16} />
                      <ButtonBrand
                        href={`/monthly${getUrlMonthlyParams()}`}
                        target="_blank"
                        onClick={handleClick}
                        $width="100%"
                      >
                        DONATE MONTHLY
                      </ButtonBrand>
                    </>
                  )}
                </Row>
                <Spacing $size={16} />
                <Link to="/recommendations" style={{ textAlign: 'center', color: 'inherit' }}>
                  <BodySmall $color={theme.colors.shaft}>See more recommendations</BodySmall>
                </Link>
              </>
            )}
          </RightContent>
        )}
      </RowContainer>
    </>
  );
};
