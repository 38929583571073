import Column from 'components/Column';
import { H3 } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled.div`
  text-align: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};
  position: relative;
`;

export const Content = styled(Column)<{ $withoutPadding?: boolean }>`
  padding: ${({ $withoutPadding }) => ($withoutPadding ? '0' : '16px')};
`;

export const TextContainer = styled.div<{ $align?: string }>`
  padding: 0 16px;
  text-align: ${({ $align }) => $align || 'left'};
`;

export const Title = styled(H3)<{ $isInverted?: boolean; $smallTitle?: boolean }>`
  color: ${({ theme, $isInverted }) => ($isInverted ? theme.colors.inkBlue : theme.colors.white)};
  ${({ $smallTitle }) => $smallTitle && `font-size: 15px; line-height: 20px;`}
`;

export const Header = styled.div<{ $isInverted?: boolean }>`
  width: 100%;
  background: ${({ theme, $isInverted }) =>
    $isInverted ? theme.colors.softGray : theme.colors.inkBlue};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 8px 0;
  text-align: left;
  position: inherit;
  z-index: 2;
`;
