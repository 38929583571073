import { ButtonBrand } from 'components/ButtonBrand';
import { Row } from 'components/Row';
import styled from 'styled-components/macro';
import { Body, H1Text, H2Text } from 'components/Typography';
import Column from 'components/Column';

export const Container = styled.div`
  ${({ theme }) => theme.fonts.light};
`;

export const InnerGradientBox = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  display: flex;
  padding: 30px 50px;
  @media (max-width: 688px) {
    flex-direction: column;
    padding: 20px 30px;
    text-align: center;
  }
`;

export const RecommendationsText = styled(Row)`
  flex-direction: column;
  margin-right: 40px;
  @media (max-width: 688px) {
    margin-bottom: 20px;
    margin-right: 0px;
  }
`;

export const GetStartedSectionContainer = styled.div`
  background: ${({ theme }) => theme.colors.softGray};
  padding: 40px 50px 128px 125px;
  position: relative;
  @media (max-width: 900px) {
    padding: 40px 20px 130px;
  }
`;

export const LearnMoreSectionContainer = styled.div`
  padding: 120px 125px 77px 50px;
  @media (max-width: 900px) {
    padding: 148px 20px 40px;
  }
`;

export const ImpactReportSectionContainer = styled.div`
  padding: 60px 125px 60px 50px;
  @media (max-width: 900px) {
    padding: 40px 20px;
  }
`;

export const H1 = styled(H1Text)`
  margin-bottom: 15px;
  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 43px;
  }
`;

export const H2 = styled(H2Text)`
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 33px;
  }
`;

export const RegularText = styled(Body)`
  ${({ theme }) => theme.fonts.regular};
`;

export const LandingButton = styled(ButtonBrand)`
  @media (max-width: 600px) {
    display: block;
    text-align: center;
  }
`;

export const RecommendationsButton = styled(LandingButton)`
  flex-shrink: 0;
  @media (max-width: 688px) {
    width: 100%;
  }
`;

export const PoweredBySectionContainer = styled.div`
  padding: 0 122px 77px;
  @media (max-width: 900px) {
    margin: 0 auto;
    max-width: 658px;
    padding: 18px 20px 40px;
  }
`;

export const PartnerLogos = styled(Row)`
  @media (max-width: 900px) {
    flex-direction: column;
    gap: 40px;
    margin-top: 30px;
  }
`;

export const SectionRow = styled(Row)`
  gap: 110px;
  @media (max-width: 1256px) {
    gap: 50px;
  }
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const SectionImage = styled.img`
  max-width: 495px;
  width: 100%;
  @media (max-width: 900px) {
    max-width: 900px;
  }
`;

export const SectionVideo = styled.video`
  max-width: 495px;
  width: 100%;
  @media (max-width: 900px) {
    max-width: 900px;
  }
`;

export const SectionImageContainer = styled.div`
  flex: 1 1 0;
  max-width: 658px;
`;

export const SectionContent = styled.div`
  flex: 2 1 0;
  max-width: 658px;
`;

export const TrendingIssuesGrid = styled(Row)`
  padding-top: 40px;
  gap: 50px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 950px) {
    overflow-x: auto;
    width: -webkit-fill-available;
    flex-wrap: inherit;
  }
`;

export const IssueContainer = styled.div<{ $isOdd: boolean }>`
  ${({ $isOdd }) => (!$isOdd ? 'width: 50%; padding-left: 20px' : 'padding-right: 20px')}
`;

export const HiddenContainer = styled.div<{ $showInDesktop: boolean }>`
  display: ${({ $showInDesktop }) => ($showInDesktop ? 'contents' : 'none')};

  @media (max-width: 950px) {
    display: ${({ $showInDesktop }) => ($showInDesktop ? 'none' : 'contents')};
  }
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.inkBlue};
`;

export const GetRecommendationsContainer = styled.div`
  position: relative;
`;

export const GetRecommendationsAbsolute = styled(Row)`
  position: absolute;
  top: -57px;
  justify-content: center;
  padding: 0 25px;
  left: 0;
  right: 0;
  @media (max-width: 845px) {
    top: -65px;
  }
  @media (max-width: 600px) {
    top: -85px;
  }
`;

export const SectionSubheader = styled.div`
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 20px;
  margin-top: 15px;
`;

export const TrendingIssuesContainer = styled.div`
  padding: 60px 0 120px;
  background: ${({ theme }) => theme.colors.iron};

  @media (min-width: 980px) {
    padding: 60px 16px 120px;
  }
  @media (min-width: 1100px) {
    padding: 60px 60px 120px;
  }
  @media (min-width: 1350px) {
    padding: 60px 180px 120px;
  }
  @media (min-width: 1430px) {
    padding: 60px 240px 120px;
  }
`;

export const PairColumn = styled(Column)<{ $position: number }>`
  padding: ${({ $position }) =>
    $position === 0 ? '0 0 0 20px' : $position === 2 ? '0 20px 0 0' : '0'};
`;

export const HeroSectionSubheader = styled(SectionSubheader)`
  margin-bottom: 25px;
`;

export const HeroContent = styled.div`
  max-width: 658px;
`;

export const HeroText = styled(Row)`
  flex-direction: column;
`;

export const PressSectionContainer = styled.div`
  padding-bottom: 74px;
  padding-top: 54px;
`;

export const PartnerLogoImage = styled.img`
  height: 40px;
`;

export const PartnerLogoImageDE = styled.img`
  height: 100px;
  @media (max-width: 900px) {
    margin-top: -20px;
  }
`;

export const ImpactCTA = styled.div`
  padding-top: 80px;
  @media (max-width: 900px) {
    padding-top: 8px;
  }
`;
