import { createGlobalStyle } from 'styled-components';
import DMSansLight from '../../assets/fonts/DMSans-Light.ttf';
import DMSansRegular from '../../assets/fonts/DMSans-Regular.ttf';
import DMSansSemiBold from '../../assets/fonts/DMSans-SemiBold.ttf';
import PanelSansMedium from '../../assets/fonts/Panel_Sans_Medium.otf';

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'DMSans-Light';
  font-style: normal;
  src: url('${DMSansLight}') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'DMSans-Regular';
  font-style: normal;
  src: url(${DMSansRegular}) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'DMSans-SemiBold';
  font-style: normal;
  src: url(${DMSansSemiBold}) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Panel_Sans_Medium';
  font-style: normal;
  src: url(${PanelSansMedium}) format('truetype');
  font-weight: 400;
}

`;

export default FontStyles;
