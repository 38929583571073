import { useNavigate } from 'react-router-dom';
import {
  clearRedirectAfterLogin,
  getRedirectAfterLogin,
  setRedirectAfterLogin,
} from 'services/storage';

export const useRedirectAfterLogin = () => {
  const navigate = useNavigate();
  const screenToRedirect = getRedirectAfterLogin();

  const handleRedirect = (screen: string) => {
    clearRedirectAfterLogin();
    navigate(screen);
  };

  const handleUpdateCredentials = (screen: string) => {
    setRedirectAfterLogin(screen);
    navigate('/login');
  };

  return {
    screenToRedirect,
    handleRedirect,
    handleUpdateCredentials,
  };
};
