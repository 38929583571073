import { FC, useEffect, useState } from 'react';
import Spacing from 'components/Spacing';
import { Body, BodySmall } from 'components/Typography';
import { TextFieldWithFloatingLabel } from 'components/FinalForm/TextFieldWithFloatingLabel';
import theme from 'config/theme';
import { Checkbox, CheckboxRow, DetailsColumn, DetailsRow } from '../../styled';
import { useField, useForm } from 'react-final-form';
import Icon, { IconNames } from 'components/Icon';
import { RETIRED_OR_UNEMPLOYED, getIsRetiredOrUnemployed, required } from 'helpers/utils';
import { CountrySelectField, StateSelectField } from 'components/FinalForm/AddressSelectFields';

const employmentFieldNames = [
  'employer_address1',
  'employer_address2',
  'employer_city',
  'employer_state',
  'employer_zipcode',
  'employer_country',
];

interface Props {
  isEmployerAddressRequired: boolean;
}

export const EmploymentDetails: FC<Props> = ({ isEmployerAddressRequired }) => {
  const { change: changeForm, getState } = useForm();
  const { employer, occupation } = getState().initialValues;
  const [isRetired, setIsRetired] = useState(getIsRetiredOrUnemployed(employer, occupation));
  const {
    input: { value: employerCountry },
  } = useField('employer_country', { subscription: { value: true } });
  const showEmployerAddressFields = isEmployerAddressRequired;

  useEffect(() => {
    if (isRetired) {
      employmentFieldNames.forEach(fieldName => {
        changeForm(fieldName, '');
      });
      changeForm('employer', RETIRED_OR_UNEMPLOYED);
      changeForm('occupation', RETIRED_OR_UNEMPLOYED);
    }
  }, [isRetired]);

  useEffect(() => {
    setIsRetired(getIsRetiredOrUnemployed(employer, occupation));
  }, [employer, occupation]);

  const onCheckboxClick = () => {
    if (isRetired) {
      changeForm('employer', '');
      changeForm('occupation', '');
    }
    setIsRetired(isRetired => !isRetired);
  };

  return (
    <>
      <Body>Employment</Body>
      {!isRetired && (
        <DetailsColumn>
          <DetailsRow>
            <TextFieldWithFloatingLabel name="occupation" label="Occupation" validate={required} />
            <TextFieldWithFloatingLabel name="employer" label="Employer" validate={required} />
          </DetailsRow>
        </DetailsColumn>
      )}
      {showEmployerAddressFields && !isRetired && (
        <DetailsColumn>
          <TextFieldWithFloatingLabel
            name="employer_address1"
            label="Employer Address 1"
            validate={required}
          />
          <TextFieldWithFloatingLabel name="employer_address2" label="Employer Address 2" />
          <DetailsRow>
            <TextFieldWithFloatingLabel
              name="employer_city"
              label="Employer City"
              validate={required}
            />
            <StateSelectField
              country={employerCountry}
              name="employer_state"
              label="Employer State"
              labelRegion="Employer Region"
              validate={required}
            />
          </DetailsRow>
          <DetailsRow>
            <TextFieldWithFloatingLabel
              name="employer_zipcode"
              label="Employer Zip"
              validate={required}
            />
            <CountrySelectField label="Employer Country" name="employer_country" />
          </DetailsRow>
        </DetailsColumn>
      )}
      <Spacing $size={!isRetired ? 10 : 4} />
      <CheckboxRow role="button" onClick={onCheckboxClick} $alignItems="center" $pressable={true}>
        <Checkbox $isChecked={isRetired} $justifyContent="space-around" $alignItems="center">
          {isRetired && <Icon name={IconNames.Checkmark} color={theme.colors.white} size={10} />}
        </Checkbox>
        <BodySmall $color={theme.colors.shaft}>I'm retired or currently unemployed</BodySmall>
      </CheckboxRow>
    </>
  );
};
