import { useState } from 'react';
import { ListItems } from 'components/ListItems';
import { CustomSlider } from 'components/Slider';
import Input, { onChangeType } from 'components/Input';

export const StepFieldsDictionary = ({ question, saveAnswer }) => {
  const [inputValue, setInputValue] = useState('');

  const handleChangeText = (e: onChangeType) => {
    setInputValue(e.target.value);
    saveAnswer(e.target.value);
  };

  switch (question.component_type) {
    case 'list_items':
      return (
        question?.options && (
          <ListItems
            options={question.options}
            isMultiple={question.is_multiple_choice}
            optionalDetail={question.optional_detail}
            saveAnswer={saveAnswer}
          />
        )
      );
    case 'slider':
      return (
        question?.options && <CustomSlider options={question.options} saveAnswer={saveAnswer} />
      );
    case 'text_box':
      return (
        <Input
          id="textBoxValue"
          placeholder=""
          value={inputValue}
          textOnChange={handleChangeText}
        />
      );
    default:
      return null;
  }
};
