import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const questionsStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const questionsSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const questionsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const questionAnswerSuccess = state => ({
  ...state,
  isLoading: false,
});

export const questionAnswerError = (state, { error }) => ({
  ...state,
  error: {
    ...state.error,
    answerError: error,
  },
  isLoading: false,
});

export const questionsCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.QUESTIONS_START]: questionsStart,
  [Types.QUESTIONS_SUCCESS]: questionsSuccess,
  [Types.QUESTIONS_ERROR]: questionsError,
  [Types.QUESTION_ANSWER_SUCCESS]: questionAnswerSuccess,
  [Types.QUESTION_ANSWER_ERROR]: questionAnswerError,
  [Types.QUESTIONS_CLEAN_UP]: questionsCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
