import ButtonLink from 'components/ButtonLink';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { CandidateType } from '../Candidates/Candidates';
import { RecipientRow } from './RecipientRow';
import { CustomSplitRow, HR, Recipients, TotalRow } from './styled';
import { UtilityText } from 'components/Typography';
import { CurrencyInput } from './CurrencyInput';
import { formatNumberAsCurrency, roundNumberToHundredths, splitAmountRounded } from 'helpers/utils';
import { ButtonBrand } from 'components/ButtonBrand';
import { getSelectedCandidates } from 'helpers/donate';
import { CustomLinkCopy } from './CustomLinkCopy';
import { ADMINS_ONLY, getIsFeatureOn } from 'helpers/featureFlags';

interface Props {
  candidates: CandidateType[];
  donationTotal?: number;
  setCandidates: Dispatch<SetStateAction<CandidateType[]>>;
  setDonationTotal: Dispatch<SetStateAction<number | undefined>>;
  resetDonationTotal: (amount?: number) => void;
  showCustomSplit: boolean;
}

export const CustomSplit: FC<Props> = ({
  candidates,
  donationTotal,
  resetDonationTotal,
  setCandidates,
  setDonationTotal,
  showCustomSplit,
}) => {
  const [isSplitVisible, setIsSplitVisible] = useState(false);
  const [tempDonationTotal, setTempDonationTotal] = useState(donationTotal);

  useEffect(() => {
    setTempDonationTotal(donationTotal);
  }, [donationTotal]);

  useEffect(() => {
    showCustomSplit && setIsSplitVisible(showCustomSplit);
  }, [showCustomSplit]);

  const onCustomizeSplitClick = () => {
    setIsSplitVisible(isVisible => !isVisible);
  };

  const onChangeCandidateAmount = (candidate: CandidateType, amount = 0, index: number) => {
    const updatedCandidates = [...candidates];
    updatedCandidates[index] = {
      ...updatedCandidates[index],
      donationAmount: amount,
    };
    const updatedTotal = updatedCandidates.reduce(
      (total, candidate) => total + (candidate.donationAmount || 0),
      0
    );

    setCandidates(updatedCandidates);
    setDonationTotal(roundNumberToHundredths(updatedTotal));
  };

  const onChangeTotalAmount = (value?: number) => {
    setTempDonationTotal(Number(value || 0));
  };

  const onResetDonationTotal = () => {
    resetDonationTotal(tempDonationTotal);
  };

  const getSplitIncrementValue = () => {
    const num = splitAmountRounded(donationTotal || 0, getSelectedCandidates(candidates).length)[0];
    return formatNumberAsCurrency(num, {
      trailingZeroDisplay: 'auto',
    });
  };

  if (getSelectedCandidates(candidates).length <= 1) {
    return null;
  }

  return (
    <>
      <CustomSplitRow>
        {getIsFeatureOn(ADMINS_ONLY) && <CustomLinkCopy candidates={candidates} />}
        <ButtonLink onClick={onCustomizeSplitClick}>Customize Split</ButtonLink>
      </CustomSplitRow>
      {isSplitVisible && (
        <>
          <HR />
          <div>Your total contribution:</div>
          <TotalRow>
            <CurrencyInput
              id="custom-split-total"
              value={tempDonationTotal}
              onChange={onChangeTotalAmount}
            />
            <ButtonBrand $secondary={true} $hasBorder={true} onClick={onResetDonationTotal}>
              Split
            </ButtonBrand>
          </TotalRow>
          <div>
            <UtilityText $fontType="regular">
              {`Your contribution will be split in increments of ${getSplitIncrementValue()} unless customized below.`}
            </UtilityText>
          </div>
          <Recipients>
            {candidates.map((candidate, index) => (
              <RecipientRow
                key={candidate.recipient_id}
                candidate={candidate}
                index={index}
                onChangeAmount={onChangeCandidateAmount}
              />
            ))}
          </Recipients>
        </>
      )}
    </>
  );
};
