import styled from 'styled-components/macro';
import Column from 'components/Column';
import { Body, H3 } from 'components/Typography';
import { RowToGrid } from 'components/Row';

export const Container = styled(Column)`
  background: ${({ theme }) => theme.colors.softGray};
  width: 100%;
  height: 100%;
  padding: 40px 0;
`;

export const Description = styled(Body)`
  ${({ theme }) => theme.fonts.light};
  padding: 0 32px;
`;

export const Title = styled(H3)`
  padding-bottom: 8px;
`;

export const RowComponent = styled(RowToGrid)`
  gap: 60px;
  margin: 0 60px;
`;
