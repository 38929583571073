import styled from 'styled-components/macro';
import { Row } from 'components/Row';

export const TipButtonsContainer = styled(Row)`
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: center;
  line-height: 18px;
  margin-left: -5px;
  margin-top: 13px;
`;

export const TipAmountButton = styled.button<{
  $isSelected: boolean;
  $clickableWhenSelected?: boolean;
  $hasInput?: boolean;
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  background: inherit;
  color: ${({ theme }) => theme.colors.inkBlue};
  border: ${({ theme }) => `1px solid ${theme.colors.inkBlue}`};
  border-radius: 100px;
  flex: 0 0 calc(50% - 5px);
  height: 38px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 0 9px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  ${({ $isSelected, theme }) =>
    $isSelected &&
    `
      background: ${theme.colors.oathBlue};
      color: ${theme.colors.white};
      border: none;
    `}
  &:hover {
    ${({ $isSelected, theme }) =>
      !$isSelected &&
      `
          border-color: ${theme.colors.oathBlue};
          color: ${theme.colors.oathBlue};
        }
      `}
  }
`;

export const CustomTipSpan = styled.span<{ $isSelected: boolean; children?: React.ReactNode }>`
  border-bottom: 1px solid;
  border-color: inherit;
  display: inline-block;
  ${({ $isSelected, theme }) => $isSelected && `border-color: ${theme.colors.white}`};
  ${({ children }) =>
    !children &&
    `
      width: 48px; 
      height: 18px;
  `};
`;

export const InputCustomTip = styled.input`
  border: 1px solid;
  border-radius: 4px;
  height: 26px;
  margin-left: 2px;
  max-width: 60%;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const CustomTipRow = styled(Row)`
  align-items: center;
  justify-content: center;
`;
