import { FC, useEffect, useState } from 'react';
import { animate, AnimatePresence, useMotionValue, useTransform } from 'framer-motion';
import { ENV_CONFIG } from 'config/environment';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { DEFAULT_FALLBACKS } from 'components/Image';
import LottieStory from 'components/LottieElements/Story';
import { ISlideProps } from '.';
import { formatNumber } from 'helpers/utils';
import { DURATION_1, OPACITY_0, OPACITY_1 } from './animations';
import {
  BigCounter,
  CandidatesRow,
  Container,
  GiveSmarterText,
  MotionCandidateImage,
  MotionText,
} from './styled';
import { BodyBig } from 'components/Typography';
import theme from 'config/theme';

export const Slide2: FC<ISlideProps> = ({ isPaused, impactReportData }) => {
  const [showFirstLayer, setShowFirstLayer] = useState(true);
  const [showSecondLayer, setShowSecondLayer] = useState(false);
  const [showThirdLayer, setShowThirdLayer] = useState(false);
  const [showFourthLayer, setShowFourthLayer] = useState(false);
  const [showLastLayer, setShowLastLayer] = useState(false);
  const [imgSrcList, setImgSrcList] = useState<any>([]);

  const amountSupported = useMotionValue(0);
  const amountSupportedCount = useTransform(amountSupported, latest => Math.round(latest));
  const amountAverage = useMotionValue(0);
  const amountAverageCount = useTransform(amountAverage, latest => latest.toFixed(1));
  const timerCandidatesLayer = useMotionValue(0);
  useTransform(timerCandidatesLayer, latest => Math.round(latest));

  useEffect(() => {
    if (showFirstLayer) {
      const controls = animate(amountSupported, impactReportData.recipient_count, {
        duration: 2,
      });
      if (isPaused) controls.pause();
    }
  }, [showFirstLayer, isPaused]);

  useEffect(() => {
    const unsubscribe = amountSupported.on('change', latest => {
      if (Math.round(latest) >= impactReportData.recipient_count && !isPaused) {
        setTimeout(() => {
          setShowFirstLayer(false);
          setTimeout(() => {
            setShowSecondLayer(true);
          }, 1000);
        }, 3000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [amountSupported, isPaused]);

  useEffect(() => {
    if (showSecondLayer) {
      const controls = animate(
        amountAverage,
        parseFloat(impactReportData.recipient_impact_score_average),
        {
          duration: 3,
        }
      );
      if (isPaused) controls.pause();
    }
  }, [showSecondLayer, isPaused]);

  useEffect(() => {
    const unsubscribe = amountAverage.on('change', latest => {
      if (
        Math.round(latest) >=
          Math.round(parseFloat(impactReportData.recipient_impact_score_average)) &&
        !isPaused
      ) {
        setTimeout(() => {
          setShowSecondLayer(false);
          setTimeout(() => {
            setShowThirdLayer(true);
          }, 1000);
        }, 3000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [amountAverage, isPaused]);

  useEffect(() => {
    if (showThirdLayer) {
      const controls = animate(timerCandidatesLayer, 8, {
        duration: 8,
      });
      if (isPaused) controls.pause();
    }
  }, [showThirdLayer, isPaused]);

  useEffect(() => {
    const unsubscribe = timerCandidatesLayer.on('change', latest => {
      if (Math.round(latest) >= 8 && !isPaused) {
        setTimeout(() => {
          setShowThirdLayer(false);
          setTimeout(() => {
            setShowFourthLayer(true);
          }, 1000);
        }, 1000);
        unsubscribe();
      }
    });

    return () => {
      unsubscribe();
    };
  }, [timerCandidatesLayer, isPaused]);

  useEffect(() => {
    if (showFourthLayer && !isPaused) {
      setTimeout(() => {
        setShowFourthLayer(false);
        setTimeout(() => {
          setShowLastLayer(true);
        }, 1000);
      }, 4000);
    }
  }, [showFourthLayer, isPaused]);

  const groupedCandidates = impactReportData.candidates.reduce<
    Record<string, { recipients: string[]; type: string }>
  >((acc, candidate) => {
    const { type_display, recipient_id, type } = candidate;

    if (!acc[type_display]) {
      acc[type_display] = { recipients: [], type };
    }

    acc[type_display].recipients.push(recipient_id);
    return acc;
  }, {});

  const getElectionMarginValue = () =>
    impactReportData.election_margin_min.count < 1000
      ? formatNumber(impactReportData.election_margin_min.count)
      : parseFloat(impactReportData.election_margin_min.percentage).toFixed(1);

  const onError = (id: string) => {
    setImgSrcList(prevList => [...prevList, id]);
  };

  return (
    <>
      <GiveSmarterText>GIVE SMARTER @OATH.VOTE</GiveSmarterText>
      <LottieStory slide="blueOrbs" isPaused={isPaused}>
        <AnimatePresence>
          {showFirstLayer && (
            <Container
              key="firstLayer"
              initial={OPACITY_0}
              animate={OPACITY_1}
              exit={{ ...OPACITY_1, y: -200 }}
              transition={DURATION_1}
            >
              <BigCounter>{amountSupportedCount}</BigCounter>
              <Spacing $size={16} />
              <MotionText
                initial={OPACITY_0}
                animate={OPACITY_1}
                transition={{ ...DURATION_1, delay: 2 }}
              >
                You supported {impactReportData.recipient_count} race
                {impactReportData.recipient_count !== 1 && 's'} this year.
              </MotionText>
            </Container>
          )}
          {showSecondLayer && (
            <Container
              key="secondLayer"
              exit={OPACITY_0}
              transition={DURATION_1}
              $alignContent="space-around"
            >
              <div>
                <BigCounter initial={OPACITY_1}>{amountSupported}</BigCounter>
                <Spacing $size={16} />
                <MotionText initial={OPACITY_0} animate={OPACITY_1} transition={DURATION_1}>
                  You supported {amountSupportedCount.get()} race
                  {amountSupportedCount.get() !== 1 && 's'} this year.
                </MotionText>
              </div>
              <div>
                <BigCounter initial={OPACITY_0} animate={OPACITY_1} transition={DURATION_1}>
                  {amountAverageCount}
                </BigCounter>
                <Spacing $size={16} />
                <MotionText
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  transition={{ ...DURATION_1, delay: 2 }}
                >
                  Which had an average Impact Score of{' '}
                  {parseFloat(impactReportData.recipient_impact_score_average).toFixed(1)} out of 10
                </MotionText>
              </div>
            </Container>
          )}
          {showThirdLayer && (
            <Container key="thirdLayer" exit={OPACITY_0}>
              <MotionText
                $size={16}
                initial={{
                  ...OPACITY_0,
                  position: 'absolute',
                  top: Object.entries(groupedCandidates).length === 4 ? 0 : 30,
                }}
                animate={OPACITY_1}
                transition={DURATION_1}
              >
                You Supported
              </MotionText>
              {Object.entries(groupedCandidates).map(([type_display, { recipients, type }]) => (
                <Column key={type_display}>
                  <MotionText
                    initial={OPACITY_0}
                    animate={OPACITY_1}
                    transition={{ ...DURATION_1, delay: 2 }}
                  >
                    {impactReportData.recipient_counts[type]} {type_display}
                  </MotionText>
                  <Spacing $size={8} />
                  <CandidatesRow $justifyContent="center" $alignItems="center">
                    {recipients.map((recipient, index) => (
                      <MotionCandidateImage
                        key={recipient}
                        src={
                          imgSrcList.find(id => id === recipient)
                            ? DEFAULT_FALLBACKS.candidate
                            : `${ENV_CONFIG().CLOUD_STORAGE.URL}/recipients/rec_${recipient}.jpg`
                        }
                        onError={() => onError(recipient)}
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={{ ...DURATION_1, delay: 3 + index * 0.1 }}
                      />
                    ))}
                    {impactReportData.recipient_counts[type] - recipients.length > 0 && (
                      <MotionText
                        $size={12}
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={{ ...DURATION_1, delay: 3 + recipients.length * 0.1 }}
                      >
                        +{impactReportData.recipient_counts[type] - recipients.length} others
                      </MotionText>
                    )}
                  </CandidatesRow>
                  <Spacing $size={24} />
                </Column>
              ))}
            </Container>
          )}
          {showFourthLayer && (
            <Container key="fourthLayer" exit={OPACITY_0} transition={DURATION_1}>
              <MotionText
                $size={16}
                initial={{ ...OPACITY_0, position: 'absolute', top: 30 }}
                animate={OPACITY_1}
                transition={DURATION_1}
              >
                Out of the {amountSupportedCount.get()} candidates
              </MotionText>
              {impactReportData.election_margin_min ? (
                <>
                  <BigCounter
                    initial={OPACITY_0}
                    animate={OPACITY_1}
                    transition={{ ...DURATION_1, delay: 1 }}
                  >
                    {impactReportData.recipient_ids_winners.length}
                  </BigCounter>
                  <Spacing $size={16} />
                  <MotionText
                    initial={OPACITY_0}
                    animate={OPACITY_1}
                    transition={{ ...DURATION_1, delay: 1 }}
                  >
                    of your candidates won{impactReportData.recipient_ids_winners.length && '!'}
                  </MotionText>
                </>
              ) : (
                <MotionText
                  $size={25}
                  initial={OPACITY_0}
                  animate={OPACITY_1}
                  transition={{ ...DURATION_1, delay: 1 }}
                >
                  Election results are still pending. Please come back to see which of your
                  candidates won.
                </MotionText>
              )}
            </Container>
          )}
          {showLastLayer && (
            <Container
              key="lastLayer"
              initial={OPACITY_0}
              animate={OPACITY_1}
              transition={DURATION_1}
            >
              {impactReportData.election_margin_min ? (
                <>
                  <CandidatesRow $justifyContent="center" $alignItems="center">
                    {impactReportData.recipient_ids_winners.slice(0, 14).map((recipient, index) => (
                      <MotionCandidateImage
                        key={recipient}
                        src={
                          imgSrcList.find(id => id === recipient)
                            ? DEFAULT_FALLBACKS.candidate
                            : `${ENV_CONFIG().CLOUD_STORAGE.URL}/recipients/rec_${recipient}.jpg`
                        }
                        onError={() => onError(recipient)}
                        initial={OPACITY_0}
                        animate={OPACITY_1}
                        transition={{ ...DURATION_1, delay: index * 0.1 }}
                      />
                    ))}
                  </CandidatesRow>
                  <Spacing $size={100} />
                  <BigCounter
                    $size={110}
                    initial={OPACITY_0}
                    animate={OPACITY_1}
                    transition={{ ...DURATION_1, delay: 1 }}
                  >
                    {getElectionMarginValue()}
                    {impactReportData.election_margin_min.count > 1000 && '%'}
                  </BigCounter>
                  <Spacing $size={24} />
                  <MotionText
                    $italic
                    $size={18}
                    initial={OPACITY_0}
                    animate={OPACITY_1}
                    transition={{ ...DURATION_1, delay: 1.1 }}
                  >
                    {impactReportData.election_margin_min.recipient_name} (
                    {impactReportData.election_margin_min.election_name})
                  </MotionText>
                  <Spacing $size={24} />
                  <MotionText
                    initial={OPACITY_0}
                    animate={OPACITY_1}
                    transition={{ ...DURATION_1, delay: 1.2 }}
                  >
                    Your closest race was decided by only{' '}
                    <MotionText $italic>
                      {getElectionMarginValue()}
                      {impactReportData.election_margin_min.count < 1000 ? ' votes' : '%'}!
                    </MotionText>
                  </MotionText>
                  <Spacing $size={40} />
                  <MotionText
                    $size={16}
                    initial={OPACITY_0}
                    animate={OPACITY_1}
                    transition={{ ...DURATION_1, delay: 2 }}
                  >
                    This means every dollar mattered.
                  </MotionText>
                </>
              ) : (
                <BodyBig $color={theme.colors.white}>
                  Election results are still pending. Please come back to see which election had the
                  closest margin.
                </BodyBig>
              )}
            </Container>
          )}
        </AnimatePresence>
      </LottieStory>
    </>
  );
};
