import { RWebShare } from 'react-web-share';
import Spacing from 'components/Spacing';
import { PopupModal } from 'components/PopupModal';
import { ButtonBrand } from 'components/ButtonBrand';
import { useAppSelector } from 'helpers/hooks';
import { useShareUrl } from 'hooks/useShareUrl';
import { getSetsData } from 'selectors/sets';
import { getUserState } from 'selectors/user';
import { Container, Description, MaximizeColumn, ShareContainer, Title } from './styled';

export const MaximizeImpact = () => {
  const { userState, setsData } = useAppSelector(state => ({
    userState: getUserState(state),
    setsData: getSetsData(state),
  }));
  const { handleShare, urlWithRefCode, isPopupOpen } = useShareUrl(userState.data?.ref_code, true);

  const ShareButton = () => {
    const content = (
      <ButtonBrand onClick={handleShare}>
        {setsData?.set_misc?.share_custom_1 || 'Share with friends'}
      </ButtonBrand>
    );
    return (
      <>
        <ShareContainer $showOnMobile={false}>{content}</ShareContainer>
        <ShareContainer $showOnMobile={true}>
          <RWebShare
            data={{
              url: urlWithRefCode,
              title: 'Refer your friends',
            }}
            onClick={() => console.log('shared successfully!')}
          >
            {content}
          </RWebShare>
        </ShareContainer>
      </>
    );
  };

  return (
    <>
      <PopupModal autoClose={true} isSelected={isPopupOpen} title="Copied to Clipboard" />
      <Container>
        <MaximizeColumn $textAlign="center">
          <Title>{setsData?.set_title_3}</Title>
          <Spacing $size={15} />
          <Description>{setsData?.set_desc_3}</Description>
          <Spacing $size={15} />
          <ShareButton />
        </MaximizeColumn>
      </Container>
    </>
  );
};
