import { FC } from 'react';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { Body, UtilityText } from 'components/Typography';
import { abbreviateNum } from 'helpers/utils';
import { FundraisingTransaction } from 'store/sets/types';
import { DonorsContainer, DonorsContent, StyledImage } from './styled';

interface Props {
  latestDonors: FundraisingTransaction[] | undefined;
  donationCount: number | undefined;
  issueId?: number;
}

export const LatestDonors: FC<Props> = ({ latestDonors, donationCount, issueId }) => {
  return (
    <DonorsContainer>
      <Row $alignItems="center">
        <StyledImage
          alt="donors_img"
          src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/tags/icon/tag_${issueId}.png`}
        />
        <Spacing $isVertical $size={8} />
        <Body>{donationCount} people have donated</Body>
      </Row>
      {latestDonors?.map((donor, i) => (
        <DonorsContent key={i}>
          <Row $alignItems="center">
            <StyledImage
              alt="recent_donor_img"
              src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/tags/icon/tag_${issueId}.png`}
            />
            <Spacing $isVertical $size={8} />
            <Column>
              <Body>{donor.city}</Body>
              <UtilityText $color={theme.shadows.black(0.5)}>
                ${abbreviateNum(parseInt(donor.amount))}
              </UtilityText>
            </Column>
          </Row>
        </DonorsContent>
      ))}
    </DonorsContainer>
  );
};
