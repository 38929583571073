import { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import theme from 'config/theme';
import Spacing from 'components/Spacing';
import Icon, { IconNames } from 'components/Icon';
import { ClipboardModal, Title, Description, MotionButtonPopup } from './styled';

interface Props {
  title: string;
  description?: string;
  isSelected?: boolean;
  autoClose: boolean;
  closePopup?: () => void;
}

export const PopupModal: FC<Props> = ({
  isSelected,
  title,
  description,
  autoClose,
  closePopup,
}) => {
  return (
    <AnimatePresence>
      {isSelected && (
        <ClipboardModal
          $autoClose={autoClose}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {closePopup && (
            <MotionButtonPopup whileTap={{ scale: 0.9 }}>
              <Icon
                name={IconNames.XClose}
                onClick={closePopup}
                color={theme.colors.white}
                size={26}
              />
            </MotionButtonPopup>
          )}
          <Title>{title}</Title>
          <Spacing $size={8} />
          {description && (
            <>
              <Description>{description}</Description>
              <Spacing $size={8} />
            </>
          )}
          {autoClose && <Icon name={IconNames.Share} color={theme.colors.white} size={26} />}
        </ClipboardModal>
      )}
    </AnimatePresence>
  );
};
