import { useEffect, useState } from 'react';
import theme from 'config/theme';
import { FAQs } from 'components/Faqs';
import Spacing from 'components/Spacing';
import { Toast } from 'components/Toast';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { RowToGrid } from 'components/Row';
import { Body, H4 } from 'components/Typography';
import { DonationCard } from 'components/Cards/DonationCard';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { getUrlParams, isKeyInObject } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { democracyEngineCheckoutRedirect, validateDonation } from 'helpers/donate';
import { useAuth } from 'hooks/auth';
import { getFaqsData } from 'selectors/faqs';
import { getTagsState } from 'selectors/tags';
import { getUserState } from 'selectors/user';
import { getRecipientsState } from 'selectors/recipients';
import { clearAccessToken, getSessionID } from 'services/storage';
import { getTags } from 'store/tags/actions';
import { getFaqs } from 'store/faqs/actions';
import { getUserProfile } from 'store/user/actions';
import { getRecipients } from 'store/recipients/actions';
import { tabIssues } from './constants';
import { HowItWorks } from './HowItWorks';
import { OtherExperiences } from './OtherExperiences';
import { MonthlyCandidates } from './MonthlyCandidates';
import {
  BGContainer,
  HeaderText,
  ItemTitle,
  MainTitle,
  MonthlyContainer,
  TextsContainer,
  FAQsContainer,
} from './styled';
import { useOathCheckout } from 'hooks/donate';
import { ProcessingRedirectOptions } from 'store/tags/constants';

export const MonthlyScreen = () => {
  const dispatch = useAppDispatch();
  const { urlIssue, urlAmount } = getUrlParams();
  const oathCheckout = useOathCheckout();
  const [tabPressed, setTabPressed] = useState<string>(
    urlIssue && isKeyInObject(urlIssue, tabIssues) ? urlIssue : 'infra'
  );
  const [selectedDonationAmount, setSelectedDonationAmount] = useState<number | undefined>(
    Number(urlAmount) || undefined
  );
  const { userState, recipientsState, tagsState, faqsData } = useAppSelector(state => ({
    userState: getUserState(state),
    recipientsState: getRecipientsState(state),
    tagsState: getTagsState(state),
    faqsData: getFaqsData(state),
  }));

  useAuth(() => {
    dispatch(getUserProfile());
  });

  useEffect(() => {
    dispatch(getFaqs('monthly'));
    logFirebaseEvent('land_monthly', {
      timestamp: new Date().toString(),
      session_id: getSessionID(),
    });
  }, []);

  useEffect(() => {
    if (userState.error) clearAccessToken();
  }, [userState]);

  useEffect(() => {
    dispatch(getRecipients({ amount: 5, query_str: tabIssues[tabPressed].query_str }));
    dispatch(getTags({ urlPartner: tabIssues[tabPressed].query_str }));
  }, [tabPressed]);

  const handleSubmit = () => {
    const selectedCandidates = recipientsState.data.recipients;
    const isDonationValid = validateDonation(selectedCandidates, selectedDonationAmount);
    const isOathCheckoutEnabled =
      tagsState.data?.tags?.length &&
      tagsState.data.tags[0].processing_redirect === ProcessingRedirectOptions.DE_OATH;
    if (!isDonationValid || !selectedDonationAmount) {
      return;
    }
    logFirebaseEvent('donate_intent_monthly', {
      timestamp: new Date().toString(),
      session_id: getSessionID(),
      total_amount: selectedDonationAmount,
      tag_id: tagsState.data.tags[0].id,
      ref_code: userState.data?.ref_code,
    });
    if (isOathCheckoutEnabled) {
      oathCheckout(
        selectedCandidates.map((candidate, index) => ({ ...candidate, index, isSelected: true })),
        selectedDonationAmount,
        undefined,
        tabIssues[tabPressed].query_str,
        tabPressed
      );
    } else {
      democracyEngineCheckoutRedirect(
        selectedCandidates,
        selectedDonationAmount,
        tabIssues[tabPressed].query_str
      );
    }
  };

  return (
    <>
      <Toast />
      <Header hasIssues />
      <BGContainer $isHeaderBG>
        <RowToGrid $justifyContent="space-between">
          <HeaderText $width="40%" $alignSelf="center">
            <MainTitle $color={theme.colors.white}>Give monthly to maximize impact</MainTitle>
            <Spacing $size={20} />
            <H4 $color={theme.colors.white}>
              Oath makes it easy to make a difference. Set up a recurring contribution and Oath will
              help you direct it in the most impactful way every month.
            </H4>
          </HeaderText>
          <HeaderText $width="60%" $alignSelf="flex-start">
            <DonationCard
              tabPressed={tabPressed}
              setTabPressed={setTabPressed}
              donationAmounts={
                tagsState.data?.tags?.length ? tagsState.data.tags[0].donation_amounts : []
              }
              selectedDonationAmount={selectedDonationAmount}
              setSelectedDonationAmount={setSelectedDonationAmount}
              buttonText="START MONTHLY NOW"
              handleSubmit={handleSubmit}
              keyCard={1}
              borderType="top"
            />
          </HeaderText>
        </RowToGrid>
        <Spacing $size={40} />
        <TextsContainer $textAlign="center">
          <ItemTitle $color={theme.colors.white}>
            {tabPressed !== 'infra' ? 'The first five recipients:' : 'Critical Infrastructure Orgs'}
          </ItemTitle>
          <Spacing $size={4} />
          <Body
            $color={theme.colors.white}
            dangerouslySetInnerHTML={{
              __html: tagsState.data?.tags?.length ? tagsState.data?.tags[0].desc : '',
            }}
          />
        </TextsContainer>
        <Spacing $size={50} />
      </BGContainer>
      <MonthlyCandidates recipients={recipientsState.data?.recipients} />
      <HowItWorks tabPressed={tabPressed} />
      <MonthlyContainer>
        <DonationCard
          tabPressed={tabPressed}
          setTabPressed={setTabPressed}
          donationAmounts={
            tagsState.data?.tags?.length ? tagsState.data.tags[0].donation_amounts : []
          }
          selectedDonationAmount={selectedDonationAmount}
          setSelectedDonationAmount={setSelectedDonationAmount}
          buttonText="GET STARTED"
          borderType="full"
          handleSubmit={handleSubmit}
          keyCard={2}
        />
      </MonthlyContainer>
      <OtherExperiences />
      {faqsData?.length > 0 && (
        <FAQsContainer>
          <FAQs />
        </FAQsContainer>
      )}
      <Footer />
    </>
  );
};
