export interface IEmailCadence {
  weekly: string;
  monthly: string;
  quarterly: string;
  rapid_response: string;
}

export const emailCadenceDictionary = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
  rapid_response: 'Crisis Moments Only',
};
