import { FC, useState } from 'react';
import {
  Bold,
  Candidate,
  CandidateButtonLink,
  CandidatesColumn,
  CandidatesColumns,
  CandidatesFooter,
  CandidatesGrid,
  CandidatesSection,
  TagIdentifier,
} from '../styled';
import { EditCandidatesModal } from './EditCandidatesModal';
import { CandidateImage } from './CandidateImage';
import ButtonLink from 'components/ButtonLink';
import { IRecipient } from 'store/recipients/types';
import { UtilityText } from 'components/Typography';
import { getUrlParams } from 'helpers/utils';
import { getArePartnerTagsEnabled, getIsCandidateTagVisible } from './utils';
import classNames from 'classnames';

const MAX_CANDIDATES_PER_PAGE_DESKTOP = 12;
const MAX_CANDIDATES_PER_PAGE_MOBILE = 4;

const getNumSelectedCandidates = (candidates: CandidateType[]) =>
  candidates.filter(candidate => candidate.isSelected).length;

export interface CandidateType extends IRecipient {
  donationAmount?: number;
  index: number;
  isSelected: boolean;
}

interface CandidatesProps {
  candidates: CandidateType[];
  setCandidates: (updatedCandidates: CandidateType[]) => void;
  recipientCount: number;
}

export const Candidates: FC<CandidatesProps> = ({ candidates, recipientCount, setCandidates }) => {
  const { urlPartner } = getUrlParams();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const numSelectedCandidates = getNumSelectedCandidates(candidates);
  const hasOneRecipientOnly = recipientCount === 1;

  const onEditCandidatesButtonClick = () => {
    setIsEditModalOpen(true);
  };

  // Sort candidates to have selected ones at the top
  const sortedCandidates = [...candidates].sort((a, b) => {
    if (a.isSelected === b.isSelected) {
      return 0;
    }
    return a.isSelected ? -1 : 1;
  });

  return (
    <>
      <div>
        <span>
          Your contribution will {numSelectedCandidates === 1 ? 'go to' : 'be split between'}{' '}
        </span>
        <Bold>
          {hasOneRecipientOnly && candidates.length > 0
            ? candidates[0].recipient_name
            : `${numSelectedCandidates} recipient${numSelectedCandidates !== 1 ? 's' : ''}.`}
        </Bold>
      </div>
      {numSelectedCandidates > 1 && (
        <div style={{ marginTop: '-4px' }}>
          <UtilityText>The split will be even unless customized below.</UtilityText>
        </div>
      )}
      <CandidatesSection>
        {recipientCount > 1 && (
          <div>
            {`${getNumSelectedCandidates(candidates)} out of ${
              candidates.length
            } recipients selected:`}
          </div>
        )}
        <CandidatesGrid
          className={classNames({ 'single-recipient': hasOneRecipientOnly })}
          $hideForSmall={true}
        >
          {sortedCandidates.slice(0, MAX_CANDIDATES_PER_PAGE_DESKTOP).map(candidate => (
            <Candidate
              key={candidate.recipient_id}
              className={classNames({ 'single-recipient': hasOneRecipientOnly })}
              $alignItems="center"
              $isSelected={candidate.isSelected}
              $whiteBackground={getArePartnerTagsEnabled(urlPartner)}
            >
              <CandidateImage recipientId={candidate.recipient_id} />
              <div>
                <div>{candidate.recipient_name}</div>
                <div>{candidate.display_info}</div>
                {getIsCandidateTagVisible(candidate.recipient_id, urlPartner) && (
                  <TagIdentifier>LGBTQ+</TagIdentifier>
                )}
              </div>
            </Candidate>
          ))}
        </CandidatesGrid>
        <CandidatesColumns $hideForLarge={true}>
          <CandidatesColumn>
            {sortedCandidates.slice(0, MAX_CANDIDATES_PER_PAGE_MOBILE).map(candidate => (
              <Candidate
                key={candidate.recipient_id}
                className={classNames({ 'single-recipient': hasOneRecipientOnly })}
                $alignItems="center"
                $isSelected={candidate.isSelected}
                $whiteBackground={getArePartnerTagsEnabled(urlPartner)}
              >
                <CandidateImage recipientId={candidate.recipient_id} />
                <div>
                  <div>{candidate.recipient_name}</div>
                  <div>{candidate.display_info}</div>
                  {getIsCandidateTagVisible(candidate.recipient_id, urlPartner) && (
                    <TagIdentifier>LGBTQ+</TagIdentifier>
                  )}
                </div>
              </Candidate>
            ))}
          </CandidatesColumn>
        </CandidatesColumns>
        {!hasOneRecipientOnly && (
          <CandidatesFooter>
            <CandidateButtonLink
              $numCandidates={candidates.length}
              onClick={onEditCandidatesButtonClick}
            >
              See all {candidates.length} recipients
            </CandidateButtonLink>
            <ButtonLink onClick={onEditCandidatesButtonClick}>Edit recipients</ButtonLink>
          </CandidatesFooter>
        )}
      </CandidatesSection>
      <EditCandidatesModal
        candidates={candidates}
        isOpen={isEditModalOpen}
        setCandidates={setCandidates}
        setIsOpen={setIsEditModalOpen}
      />
    </>
  );
};
