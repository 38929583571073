import axios from 'axios';
import { Dispatch } from 'redux';
import TRANSACTIONS_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getSiteAccessToken } from 'services/storage';
import { datadogLogs } from '@datadog/browser-logs';
import { getMessageFromAxiosError } from 'helpers/errors';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getTransactions =
  (user_ref_code: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(TRANSACTIONS_TYPES.transactionsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_TRANSACTIONS(user_ref_code)}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
      });
      dispatch(TRANSACTIONS_TYPES.transactionsSuccess(response.data));
    } catch (error: any) {
      dispatch(TRANSACTIONS_TYPES.transactionsError(error.message));
      datadogLogs.logger.warn(
        'Error occured fetching transactions on profile page',
        {},
        new Error(getMessageFromAxiosError(error))
      );
    }
  };
