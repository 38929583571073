import { FC, InputHTMLAttributes, ReactNode } from 'react';
import { InputWithFloatingLabelContainer } from './styled';
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  className?: string;
  error?: string;
  label: ReactNode;
}

export const InputWithFloatingLabel: FC<Props> = ({
  children,
  className = '',
  error,
  label,
  name,
  type = 'text',
  ...inputProps
}) => {
  return (
    <InputWithFloatingLabelContainer
      className={classNames(className, { filled: inputProps.value, withLabel: label })}
    >
      {children || <input id={name} name={name} type={type} {...inputProps} />}
      <label htmlFor={name}>
        {label}
        {error && ` (${error})`}
      </label>
    </InputWithFloatingLabelContainer>
  );
};
