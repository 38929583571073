import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ $width?: string; $withShadow?: boolean; $overflow?: string }>`
  overflow: ${({ $overflow }) => $overflow || 'hidden'};
  border-radius: 10px;
  width: ${({ $width }) => $width || '100%'};
  height: fit-content;
  box-shadow: ${({ theme, $withShadow }) =>
    $withShadow ? `0px 0px 10px 0px ${theme.shadows.black(0.08)}` : 'none'};

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const OuterGradientBox = styled.div<{
  $margin?: string;
  $borderSize?: number;
  $gradient?: string;
}>`
  background: ${({ theme, $gradient }) =>
    $gradient
      ? `linear-gradient(${$gradient})`
      : `linear-gradient(${theme.gradients.fullGradient({})})`};
  border-radius: 10px;
  padding: ${({ $borderSize }) => ($borderSize ? `${$borderSize}px` : '3px')};

  ${({ $margin }) =>
    $margin
      ? `
        margin: ${$margin};

        @media (max-width: 1300px) {
          margin: 25px 15px;
          margin-top: -75px;
        }
      `
      : `
        margin: auto;
      `}
`;

export const Container = styled.div<{ $borderSize?: number; $gradient?: string }>`
  text-align: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: ${({ theme }) => `0px 0px 10px 0px ${theme.shadows.black(0.08)}`};
  position: relative;
  border-width: ${({ $borderSize }) => ($borderSize ? `${$borderSize}px 0 0 0` : '6px 0 0 0')};
  border-image: ${({ theme, $gradient }) =>
    $gradient
      ? `linear-gradient(${$gradient})`
      : `linear-gradient(${theme.gradients.oathToElectricGradient({})})`};
  border-image-slice: 1;
  border-style: solid;
`;
