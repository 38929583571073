import dayjs from 'dayjs';

export const MS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;

//IN: Date
//OUT: format... 'DD/MM/YYYY' | 'HH:mm' | 'YYYY-MM-DD HH:MM:ss'
export const formatDate = (date, format) => dayjs(date).format(format);

export const localizedDate = date => formatDate(date, 'MMM D, YYYY');

export const primaryDate = date => formatDate(date, 'MMM D');

export const regularDate = date => formatDate(date, 'MM/DD/YY');

export const formatTime = date => formatDate(date, 'hh:mm a');

export const galleryDate = date => formatDate(date, 'MMMM YYYY');
