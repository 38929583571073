import { FC } from 'react';
import Spacing from 'components/Spacing';
import { H4 } from 'components/Typography';
import { ButtonBrand } from 'components/ButtonBrand';
import { GradientBorderCard } from '../GradientBorderCard';
import { InnerGradientBox } from './styled';

interface Props {
  title: string;
  cta: string;
  link: string;
}

export const JoinCommunityCard: FC<Props> = ({ title, cta, link }) => {
  return (
    <GradientBorderCard borderType="full" width="600px" borderSize={1}>
      <InnerGradientBox>
        <H4>{title}</H4>
        <Spacing $size={24} />
        <ButtonBrand href={link} $width="fit-content">
          {cta}
        </ButtonBrand>
      </InnerGradientBox>
    </GradientBorderCard>
  );
};
