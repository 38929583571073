import { FC } from 'react';
import { getTagColor } from './ColorMap';
import { TagValue, TagContainer } from './styled';

interface Props {
  text: string;
}

export const HvpTag: FC<Props> = ({ text }) => {
  const getParsedText = (text: string) => {
    if (text.startsWith('D+') || text.startsWith('R+')) {
      const numberPart = parseInt(text.slice(2));
      return numberPart > 6 ? `${text.slice(0, 2)}6` : text.slice(0, 3);
    }
    return text;
  };

  const tagColor = getTagColor(getParsedText(text));

  return (
    <TagContainer color={tagColor}>
      <TagValue>{text}</TagValue>
    </TagContainer>
  );
};
