import axios from 'axios';
import { Dispatch } from 'redux';
import PAYMENTS_TYPES, { PaymentSaveRequestData } from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getSiteAccessToken } from 'services/storage';
import { datadogLogs } from '@datadog/browser-logs';
import { getMessageFromAxiosError } from 'helpers/errors';
import { noop } from 'helpers/utils';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getPayments =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(PAYMENTS_TYPES.paymentsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAYMENTS}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
      });
      dispatch(PAYMENTS_TYPES.paymentsSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(PAYMENTS_TYPES.paymentsError(error.message));
      try {
        datadogLogs.logger.warn(
          'Error occured calling GET /payments',
          {},
          new Error(getMessageFromAxiosError(error))
        );
      } catch (e) {
        noop();
      }
      return Promise.reject(error);
    }
  };

export const postPayment =
  (body: PaymentSaveRequestData) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(PAYMENTS_TYPES.paymentsSaveStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAYMENTS}`;

    try {
      const response = await axios.post(url, body, {
        headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
      });
      dispatch(PAYMENTS_TYPES.paymentsSaveSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(PAYMENTS_TYPES.paymentsSaveError(error.message));
      try {
        datadogLogs.logger.warn(
          'Error occured calling POST /payments',
          {},
          new Error(getMessageFromAxiosError(error))
        );
      } catch (e) {
        noop();
      }
      return Promise.reject(error);
    }
  };

export const postPaymentLoggedOut =
  (body: PaymentSaveRequestData) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(PAYMENTS_TYPES.paymentsSaveStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.PAYMENTS}/logged_out`;

    try {
      const response = await axios.post(url, body, {
        headers,
      });
      dispatch(PAYMENTS_TYPES.paymentsSaveSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(PAYMENTS_TYPES.paymentsSaveError(error.message));
      try {
        datadogLogs.logger.warn(
          'Error occured calling POST /payments/logged_out',
          {},
          new Error(getMessageFromAxiosError(error))
        );
      } catch (e) {
        noop();
      }
      return Promise.reject(error);
    }
  };
