import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { QuestionsData, QuestionsState } from 'store/questions/types';

export const getQuestionsState = (state: RootState): QuestionsState => state.questions;

export const getQuestionsData = createSelector(
  [getQuestionsState],
  (state: QuestionsState): QuestionsData[] => state?.data
);
