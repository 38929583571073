import { FC } from 'react';
import { Row } from 'components/Row';
import { Label, MotionContainer } from './styled';

interface Props {
  label: string;
  onclick?: () => void;
  lastone?: boolean;
  selected?: boolean;
}

export const ActionListItem: FC<Props> = ({ label, onclick, selected, lastone }) => {
  return (
    <MotionContainer
      whileTap={{ scale: 0.95 }}
      onClick={onclick}
      $selected={selected}
      $lastone={lastone}
    >
      <Row $justifyContent="space-between" $alignItems="center">
        <Row $alignItems="center">
          <Label $selected={selected}>{label}</Label>
        </Row>
      </Row>
    </MotionContainer>
  );
};
