import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const recipientsStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const recipientsSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const recipientsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const recipientsDetailedSuccess = (state, { data }) => ({
  ...state,
  detailed: data,
  isLoading: false,
});

export const recipientsDetailedError = (state, { error }) => ({
  ...state,
  error: {
    ...state.error,
    detailedError: error,
  },
  isLoading: false,
});

export const recipientDetailedByIdStart = state => ({
  ...state,
  detailedById: {
    ...state.detailedById,
    isLoading: true,
    error: null,
  },
});

export const recipientDetailedByIdSuccess = (state, { data }) => ({
  ...state,
  detailedById: {
    data: {
      ...state.detailedById.data,
      [data.recipient.recipient_id]: data.recipient,
    },
    isLoading: false,
    error: null,
  },
});

export const recipientDetailedByIdError = (state, { error }) => ({
  ...state,
  detailedById: {
    ...state.detailedById,
    isLoading: false,
    error,
  },
});

export const recipientsCleanUp = () => INITIAL_STATE;

export const recipientsFinishLoading = state => ({
  ...state,
  isLoading: false,
});

export const HANDLERS = {
  [Types.RECIPIENTS_START]: recipientsStart,
  [Types.RECIPIENTS_SUCCESS]: recipientsSuccess,
  [Types.RECIPIENTS_ERROR]: recipientsError,
  [Types.RECIPIENTS_DETAILED_SUCCESS]: recipientsDetailedSuccess,
  [Types.RECIPIENTS_DETAILED_ERROR]: recipientsDetailedError,
  [Types.RECIPIENTS_CLEAN_UP]: recipientsCleanUp,
  [Types.RECIPIENT_DETAILED_BY_ID_START]: recipientDetailedByIdStart,
  [Types.RECIPIENT_DETAILED_BY_ID_SUCCESS]: recipientDetailedByIdSuccess,
  [Types.RECIPIENT_DETAILED_BY_ID_ERROR]: recipientDetailedByIdError,
};

export default createReducer(INITIAL_STATE, HANDLERS);
