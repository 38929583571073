import { FC, useEffect } from 'react';
import { ErrorContainer } from './styled';
import { datadogLogs } from '@datadog/browser-logs';

interface Props {
  children: React.ReactNode;
  data: {
    component: string;
    [key: string]: any;
  };
  errorMsg?: string;
  shouldAlert?: boolean;
}

export const ErrorComponent: FC<Props> = ({ children, data, shouldAlert, errorMsg = '' }) => {
  useEffect(() => {
    datadogLogs.logger.info(
      `Error component rendered. Browser issue caught in ${data.component} component`,
      data
    );
    if (shouldAlert) {
      datadogLogs.logger.warn(`Browser error occurred: ${errorMsg}`, {}, new Error(errorMsg));
    }
  }, []);

  return <ErrorContainer>{children}</ErrorContainer>;
};

export default ErrorComponent;
