import { useEffect } from 'react';
import { ENV_CONFIG } from 'config/environment';
import theme from 'config/theme';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Footer } from 'components/Footer';
import { Bar } from 'components/Header/Bar';
import { EllipseText } from 'components/EllipseText';
import { Body, Eyebrow, H2Text } from 'components/Typography';
import { JoinCommunityCard } from 'components/Cards/JoinCommunityCard';
import { getPathWithRef, scrollToTop } from 'helpers/utils';
import { Container, EllipsesContainer } from './styled';

export const HelpScreen = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Bar />
      <Container>
        <Eyebrow>How can I help?</Eyebrow>
        <Spacing $size={16} />
        <H2Text>
          The best way to help is to{' '}
          <H2Text $italic $color={theme.colors.oathBlue}>
            spread the word
          </H2Text>{' '}
          about Oath’s platform.
        </H2Text>
        <Spacing $size={16} />
        <Body $color={theme.colors.black}>
          Oath is designed to help you maximize the impact of your political donations. We work with
          donors giving anywhere from $5 to $100K+ and every donation counts. The best way to help
          is to spread the word about this exciting new platform to help your friends and family
          maximize their impact and eliminate wasted donations. You can also follow us and share our
          content on Instagram, TikTok, and Twitter.
        </Body>
        <Spacing $size={16} />
        <Eyebrow>Oath never sells your personal data.</Eyebrow>
        <Spacing $size={16} />
        <Body $color={theme.colors.black}>
          We rely on voluntary support from our users to continue offering our expert analysis free
          of charge. If you want to support Oath’s mission to democratize political data for all,
          you can start a monthly contribution here.
        </Body>
        <Spacing $size={16} />
        <Body $color={theme.colors.black}>
          If you would like to suggest a candidate to be included on Oath, you can submit them{' '}
          <Link
            href="https://airtable.com/appPQgaEeAQXJmjVx/shrzkMbzZsnYN7lLl"
            target="_blank"
            $fontSize="inherit"
          >
            here
          </Link>
          .
        </Body>
        <Spacing $size={32} />
        <Eyebrow>Other ways to make a difference:</Eyebrow>
        <Spacing $size={16} />
        <EllipsesContainer>
          <EllipseText
            text="Check your voter registration here."
            image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/voter_registration.png`}
            onPress={() => window.open('https://www.vote.org/', '_blank')}
          />
          <Spacing $size={16} />
          <EllipseText
            text="Write letters to voters. Get started here."
            image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/write_letters.png`}
            onPress={() => window.open('https://www.votefwd.org/', '_blank')}
          />
          <Spacing $size={16} />
          <EllipseText
            text="Vote informed in every election. Learn more here."
            image={`${ENV_CONFIG().CLOUD_STORAGE.URL}/impact_score_page/vote_informed.png`}
            onPress={() => window.open('https://www.ballotready.org/', '_blank')}
          />
        </EllipsesContainer>
        <JoinCommunityCard
          title="Join our Oath community now!"
          cta="Join now"
          link={getPathWithRef('/login')}
        />
      </Container>
      <Footer />
    </>
  );
};
