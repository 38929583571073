import { ENV_CONFIG } from 'config/environment';
import Spacing from 'components/Spacing';
import { H2Text, H3, H4 } from 'components/Typography';
import {
  ContentImage,
  ContentRow,
  ContentText,
  MarketingContainer,
  StyledImage,
  StyledVideo,
} from './styled';

export const Marketing = () => {
  return (
    <MarketingContainer $justifyItems="center" $textAlign="center">
      <H2Text>Oath makes it easy to make a difference.</H2Text>
      <H4>
        If you are feeling panicked about 2024, Oath can help you turn your anxiety into action!
      </H4>
      <Spacing $size={40} />
      <ContentRow $justifyContent="space-between" $alignItems="center">
        <ContentImage>
          <StyledVideo autoPlay loop muted id="Zero Spam" controls>
            <source
              src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/getstarted/video_zerospam.mp4`}
              type="video/mp4"
            />
          </StyledVideo>
        </ContentImage>
        <ContentText>
          <H3>Zero Spam</H3>
          <H4>
            Unlike other donation platforms, Oath never sells or shares your data. We promise no
            annoying texts or emails.
          </H4>
        </ContentText>
      </ContentRow>
      <ContentRow $justifyContent="space-between" $alignItems="center">
        <ContentText>
          <H3>Free Political Advising</H3>
          <H4>
            Oath is free! We don’t charge a platform fee and have lowest credit card processing in
            the industry.
          </H4>
        </ContentText>
        <ContentImage>
          <StyledImage
            alt="Free Political Advising"
            src={`${ENV_CONFIG().CLOUD_STORAGE.URL}/static_content/getstarted/image_free.png`}
          />
        </ContentImage>
      </ContentRow>
      <ContentRow $justifyContent="space-between" $alignItems="center">
        <ContentImage>
          <StyledImage
            alt="Measure Your Impact"
            src={`${
              ENV_CONFIG().CLOUD_STORAGE.URL
            }/static_content/getstarted/image_measureimpact.png`}
          />
        </ContentImage>
        <ContentText>
          <H3>Measure Your Impact</H3>
          <H4>Once you’ve signed up, Oath helps tracks your donations and annual impact.</H4>
        </ContentText>
      </ContentRow>
    </MarketingContainer>
  );
};
