import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

export const Row = styled(motion.div)<{
  $justifyContent?: string;
  $justifySelf?: string;
  $alignItems?: string;
  $pressable?: boolean;
  $width?: string;
}>`
  ${({ $width }) => $width && `width: ${$width}`};
  justify-content: ${({ $justifyContent }) => $justifyContent || 'inherit'};
  justify-self: ${({ $justifySelf }) => $justifySelf || 'inherit'};
  align-items: ${({ $alignItems }) => $alignItems || 'inherit'};
  ${({ $pressable }) => $pressable && 'cursor: pointer;'};
  display: flex;
`;

export const RowToGrid = styled(Row)<{ $switchToGrid?: number }>`
  ${({ $switchToGrid }) => `
    @media (max-width: ${$switchToGrid || 1000}px) {
      display: grid;
    }
  `}
`;
