import { H2Text, H4 } from 'components/Typography';
import { CommunityContainer, ListExperiences } from './styled';
import { QuoteCard } from 'components/Cards/QuoteCard';

export const Community = () => {
  const quotes = [
    {
      title: 'No spam, ever!',
      description:
        'SO grateful to Oath for not adding me to new lists when I donate through your platform!',
      author: 'Ryan',
      location: 'New York',
    },
    {
      title: 'Donate where it matters most',
      description:
        'Oath is the smartest way to protect my sister’s marriage to her wife, my own access to reproductive agency, my children... and so much more.',
      author: 'Nicole',
      location: 'Texas',
    },
    {
      title: 'Smart and simple',
      description:
        'I LOVE the whole concept of Oath You’ve gotten me off the whole treadmill, rat race of political donations and the stress of trying to pick what makes a difference.',
      author: 'Liz',
      location: 'Wisconsin',
    },
  ];

  return (
    <CommunityContainer $justifyItems="center" $textAlign="center">
      <H2Text>Our community has already given more than 150K donations</H2Text>
      <H4>Find out why our donors are choosing Oath.</H4>
      <ListExperiences>
        {quotes.map((quote, i) => (
          <QuoteCard
            key={i}
            title={quote.title}
            description={quote.description}
            author={quote.author}
            location={quote.location}
          />
        ))}
      </ListExperiences>
    </CommunityContainer>
  );
};
