import { createActions } from 'reduxsauce';

export interface UserState {
  data: IUserData;
  isLoading: boolean;
  error: string;
}

export interface IUserData {
  phone_number: string;
  first_name: string;
  last_name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  employer: string;
  employer_address1: string;
  employer_address2: string;
  employer_city: string;
  employer_country: string;
  employer_state: string;
  employer_zipcode: string;
  occupation: string;
  created_at: string;
  id: string;
  ref_code: string;
  auth0_id: string;
  email: string;
  email_cadence: string;
  monthly_budget: number;
  is_onboarded: boolean;
  issues: string[];
}

export interface UserTypes {
  USER: 'USER';
  USER_START: 'USER_START';
  USER_SUCCESS: 'USER_SUCCESS';
  USER_ERROR: 'USER_ERROR';
  USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS';
  USER_PROFILE_ERROR: 'USER_PROFILE_ERROR';
  USER_CLEAN_UP: 'USER_CLEAN_UP';
}

const { Types, Creators } = createActions<UserTypes>({
  user: ['data'],
  userStart: null,
  userSuccess: ['data'],
  userError: ['error'],
  userProfileSuccess: ['data'],
  userProfileError: ['error'],
  userCleanUp: null,
});

export { Types };

export default Creators;
