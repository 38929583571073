import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const paymentsStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const paymentsSaveStart = state => ({
  ...state,
  saveNewPaymentIsLoading: true,
  saveNewPaymentError: null,
});

export const paymentsSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    existingPayments: data,
  },
  isLoading: false,
});

export const paymentsSaveSuccess = (state, { data }) => ({
  ...state,
  data: {
    ...state.data,
    newPaymentsById: {
      ...state.data.newPaymentsById,
      [data.payment_authorization_token]: data,
    },
  },
  saveNewPaymentIsLoading: false,
});

export const paymentsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const paymentsSaveError = (state, { error }) => ({
  ...state,
  saveNewPaymentError: error,
  saveNewPaymentIsLoading: false,
});

export const paymentsCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.PAYMENTS_START]: paymentsStart,
  [Types.PAYMENTS_SUCCESS]: paymentsSuccess,
  [Types.PAYMENTS_ERROR]: paymentsError,
  [Types.PAYMENTS_CLEAN_UP]: paymentsCleanUp,
  [Types.PAYMENTS_SAVE_SUCCESS]: paymentsSaveSuccess,
  [Types.PAYMENTS_SAVE_START]: paymentsSaveStart,
  [Types.PAYMENTS_SAVE_ERROR]: paymentsSaveError,
};

export default createReducer(INITIAL_STATE, HANDLERS);
