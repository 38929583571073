import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const galleryStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const gallerySuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const galleryError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const galleryCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.GALLERY_START]: galleryStart,
  [Types.GALLERY_SUCCESS]: gallerySuccess,
  [Types.GALLERY_ERROR]: galleryError,
  [Types.GALLERY_CLEAN_UP]: galleryCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
