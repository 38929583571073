import axios from 'axios';
import { Dispatch } from 'redux';
import QUESTIONS_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getSiteAccessToken } from 'services/storage';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getQuestions =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(QUESTIONS_TYPES.questionsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_QUESTIONS}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, 'X-API-Key': '3a4d491f-058f-4b53-a246-85a8c12f9bd4' },
      });
      dispatch(QUESTIONS_TYPES.questionsSuccess(response.data));
    } catch (error: any) {
      dispatch(QUESTIONS_TYPES.questionsError(error.message));
    }
  };

export const answerQuestion =
  (key, answer) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(QUESTIONS_TYPES.questionsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.ANSWER_QUESTION}`;

    try {
      const response = await axios.post(
        url,
        {
          key_question: key,
          response: answer,
        },
        {
          headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
        }
      );
      dispatch(QUESTIONS_TYPES.questionAnswerSuccess(response.data));
    } catch (error: any) {
      dispatch(QUESTIONS_TYPES.questionAnswerError(error.message));
    }
  };
