import { FC } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { ToggleFieldLabel } from './styled';

interface Props extends FieldProps<string, FieldRenderProps<any>> {
  children: React.ReactNode;
}

export const ToggleField: FC<Props> = ({ children, ...fieldProps }) => {
  return (
    <ToggleFieldLabel>
      <Field component="input" type="radio" {...fieldProps} />
      <div className="toggle-option">{children}</div>
    </ToggleFieldLabel>
  );
};
