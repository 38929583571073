import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Spacing from 'components/Spacing';
import Icon, { IconNames } from 'components/Icon';
import { PopupModal } from 'components/PopupModal';
import { MotionButton } from 'components/Header/styled';
import logFirebaseEvent from 'helpers/logFirebaseEvent';
import { getUrlParams, getPathWithRef } from 'helpers/utils';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useShareUrl } from 'hooks/useShareUrl';
import { getTopTags } from 'selectors/tags';
import { getUserState } from 'selectors/user';
import { clearDonationSelections, getSessionID, setRefValue } from 'services/storage';
import { ITag } from 'store/tags/types';
import { getRecipients } from 'store/recipients/actions';
import {
  Label,
  Container,
  TagContainer,
  Title,
  Line,
  DescContainer,
  Description,
  DescriptionCTA,
  ShareContainer,
  HiddenContainer,
} from './styled';
import { removeRepeatedItems } from 'screens/Checkout/Form/utils';
import { getDonateConfig } from 'selectors/siteConfig';

// TIP: When we want to search by tags and searchValue too
export const ListTags = ({
  setSelectedTagsDynSearch,
  nameDynSearch,
  clearTags,
  urlPartner,
  refWidthValue,
  clearInput,
  allTags,
}) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tagsModified, setTagsModified] = useState(false);
  const [tagsRequested, setTagsRequested] = useState(true);
  const [isOrdering, setIsOrdering] = useState(false);
  const [selectedTags, setSelectedTags] = useState(Array<string>());
  const [orderedTags, setOrderedTags] = useState(Array<ITag>());
  const [popupType, setPopupType] = useState('');
  const { topTags, userState, donateFilterBarConfig } = useAppSelector(state => ({
    topTags: getTopTags(state),
    userState: getUserState(state),
    donateFilterBarConfig: getDonateConfig(state, 'donate_filter_bar'),
  }));

  const { handleShare, isPopupOpen, setIsPopupOpen } = useShareUrl(
    userState.data?.ref_code,
    true,
    !urlPartner ? removeRepeatedItems(selectedTags) : []
  );

  const handleTags = (tags: Array<string>, fromUrl: boolean) => {
    if (!fromUrl) {
      clearDonationSelections();
      if (!selectedTags.includes(tags[0])) {
        //TIP: Tell the user that he can't select more than 3 tags
        if (removeRepeatedItems(selectedTags).length === 3) {
          setPopupType('TAGS');
          setIsPopupOpen(true);
        } else {
          setTagsModified(true);
          setSelectedTags(selected => [...selected, tags[0]]);
        }
      } else {
        setTagsModified(true);
        setSelectedTags(selectedTags.filter(item => item !== tags[0]));
      }
      logFirebaseEvent(!selectedTags.includes(tags[0]) ? 'land_select_tag' : 'land_unselect_tag', {
        timestamp: new Date().toString(),
        tags: tags[0],
        session_id: getSessionID(),
      });
      const element: any = ref.current;
      if (element) element.scrollLeft = 0;
    } else {
      setTagsModified(true);
      setSelectedTags(selected => [...selected, ...tags]);
    }
    clearInput();
  };

  useEffect(() => {
    if (clearTags) {
      setSelectedTags([]);
    }
  }, [clearTags]);

  useEffect(() => {
    if (tagsModified && !urlPartner) {
      // TIP: When we want to search by tags and searchValue too
      setSelectedTagsDynSearch(selectedTags);
      setOrderedTags([
        ...allTags.filter(tag => selectedTags.includes(tag.value)), //SELECTED
        ...topTags.filter(tag => !selectedTags.includes(tag.value)), //TOP TAGS NOT SELECTED
      ]);
      dispatch(
        getRecipients({
          amount: urlPartner ? 200 : 6,
          tags: selectedTags,
          query_str: urlPartner || '',
        })
      );
      // dispatch(getRecipients({ amount: 6, tags: selectedTags, search_str: nameDynSearch }));
      setTagsModified(false);
      setIsOrdering(true);
    }
  }, [tagsModified]);

  useEffect(() => {
    if (allTags && isOrdering) {
      setOrderedTags(ordered => [...ordered, ...allTags.filter(tag => !ordered.includes(tag))]); //REST OF TAGS
      setIsOrdering(false);
    }
  }, [isOrdering]);

  useEffect(() => {
    if (allTags && tagsRequested) {
      const { urlTags, urlRef, urlPartner } = getUrlParams();
      if (urlRef) setRefValue(urlRef);
      urlTags.forEach((paramTag, i) => {
        if (!allTags.find(tag => tag.value === paramTag)) {
          urlTags.splice(i, 1);
        }
      });
      //TIP: This is the tag value returned from the partner url
      if (urlPartner) {
        if (allTags?.length === 0) navigate(getPathWithRef('/error'));
        if (allTags?.length > 0) handleTags([allTags[0].value], true);
      } else if (urlTags.length > 0) handleTags(urlTags, true);
      else {
        setIsOrdering(true);
      }
      setTagsRequested(false);
    }
  }, [allTags]);

  const handleShareClick = () => {
    setPopupType('COPY');
    handleShare();
  };

  return (
    <div style={{ backgroundColor: theme.colors.white }}>
      {!urlPartner && donateFilterBarConfig?.donate_filter_bar_config !== 'off' && (
        <Container ref={ref} $refWidthValue={refWidthValue}>
          <Label $isFixed>Filter</Label>
          {orderedTags?.map((tag: ITag, index: number) => (
            <TagContainer
              onClick={() => handleTags([tag.value], false)}
              key={index}
              $isLastOne={orderedTags.length === index + 1}
              $isSelected={selectedTags.includes(tag.value)}
            >
              <Label $color="inherit">{tag.value}</Label>
            </TagContainer>
          ))}
        </Container>
      )}
      {popupType === 'COPY' ? (
        <PopupModal autoClose={true} isSelected={isPopupOpen} title="Copied to Clipboard" />
      ) : popupType === 'TAGS' ? (
        <PopupModal
          autoClose={false}
          closePopup={() => setIsPopupOpen(false)}
          isSelected={isPopupOpen}
          title="You’ve reached the maximum number of tags!"
          description="Please unselect at least one tag before continuing."
        />
      ) : (
        <PopupModal
          autoClose={false}
          closePopup={() => setIsPopupOpen(false)}
          isSelected={isPopupOpen}
          title="What’s the Impact Score?"
          description="Oath’s Impact Score is calculated from dozens of data points across three metrics: Competitiveness, Stakes, and Financial Need."
        />
      )}
      {allTags?.length > 0 && (
        <DescContainer>
          <HiddenContainer $hiddenOnMobile={!!urlPartner}>
            <Row $justifyContent="space-between" $alignItems="center">
              {!urlPartner ? (
                selectedTags.length <= 1 && !nameDynSearch ? (
                  <Title>
                    Top{' '}
                    <Title $isFocus>
                      {selectedTags.length === 1 ? selectedTags[0] : 'Max Impact'}
                    </Title>{' '}
                    Recipients
                  </Title>
                ) : (
                  <Title>Top Results:</Title>
                )
              ) : (
                <Title $smallOnly={true}>{allTags[0].value}</Title>
              )}
              <ShareContainer $isSmallOnly={!!urlPartner}>
                <MotionButton whileTap={{ scale: 0.9 }} style={{ padding: 0 }}>
                  <Icon
                    name={IconNames.Share}
                    onClick={handleShareClick}
                    color={theme.colors.silver}
                    size={26}
                  />
                </MotionButton>
              </ShareContainer>
            </Row>
          </HiddenContainer>
          {(selectedTags.length === 1 || urlPartner) && (
            <HiddenContainer $hiddenOnMobile={!!urlPartner}>
              <Spacing $size={8} />
              {urlPartner ? (
                <>
                  {allTags[0].desc && (
                    <DescriptionCTA
                      $isCta={false}
                      dangerouslySetInnerHTML={{ __html: allTags[0].desc }}
                    />
                  )}
                  {allTags[0].tag_cta && (
                    <DescriptionCTA
                      $isCta={true}
                      dangerouslySetInnerHTML={{ __html: allTags[0].tag_cta }}
                    />
                  )}
                </>
              ) : (
                <>
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: allTags.find(tag => tag.value === selectedTags[0])?.desc,
                    }}
                  />
                  <Spacing $size={16} />
                </>
              )}
            </HiddenContainer>
          )}
          {selectedTags.length === 0 && (
            <>
              <Spacing $size={8} />
              <Description>
                Oath analyzes thousands of races to help you maximize your impact. The higher the
                Impact Score, the greater the value of your donation in that race.
              </Description>
            </>
          )}
          <Spacing $size={16} />
          {(!urlPartner || (urlPartner && allTags[0].impact_score_visibility)) && (
            <Row $justifyContent="space-between">
              <Title>Recipient</Title>
              <Row>
                <Title>Impact Score</Title>
                <Spacing $isVertical $size={8} />
                <Icon
                  name={IconNames.HelpCircle}
                  size={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setPopupType('IMPACT');
                    setIsPopupOpen(true);
                  }}
                />
              </Row>
            </Row>
          )}
          <Line />
        </DescContainer>
      )}
    </div>
  );
};
