import { Body, H3 } from 'components/Typography';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

export const Container = styled.div<{ $align?: string }>`
  padding: 60px 0;

  @media (max-width: 1000px) {
    padding: 35px 0;
  }
`;

export const Accordion = styled.div`
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.silverChalise};
`;

export const IconContainer = styled.div`
  width: 40px;
  margin-left: 24px;
  text-align: right;
`;

export const MotionContainer = styled(motion.div)`
  padding-top: 16px;
  padding-right: 60px;
  display: grid;
  text-align: left;
`;

export const CircleNumber = styled.div<{ $selected?: boolean }>`
  min-width: 30px;
  height: 30px;
  align-content: center;
  text-align: center;
  background: ${({ theme, $selected }) =>
    $selected ? theme.colors.oathBlue : theme.colors.inkBlue};
  border-radius: 22px;
`;

export const Number = styled(Body)`
  color: ${({ theme }) => theme.colors.white};
`;

export const Title = styled(H3)`
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 27px;
  }
`;
