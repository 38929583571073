import styled from 'styled-components/macro';

export const Input = styled.input<{ $hasError?: boolean }>`
  border-color: ${({ theme, $hasError }) =>
    $hasError ? `${theme.colors.errorRed} !important` : 'inherit'};
`;

export const ErrorText = styled.div`
  color: ${({ theme }) => theme.colors.errorRed};
`;

export const RadioFieldLabel = styled.label`
  display: flex;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    border: ${({ theme }) => `1px solid ${theme.shadows.black(0.7)}`};
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
  }

  &:hover input ~ .checkmark {
    border: ${({ theme }) => `1px solid ${theme.colors.oathBlue}`};
  }

  input:checked ~ .checkmark {
    border: ${({ theme }) => `1px solid ${theme.colors.inkBlue}`};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.oathBlue};
  }
`;

export const ToggleFieldLabel = styled.label`
  display: flex;
  flex: 1;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .toggle-option {
    background: ${({ theme }) => theme.colors.softGray};
    color: ${({ theme }) => theme.colors.inkBlue};
    padding: 3px;
    text-align: center;
    width: 100%;
  }

  &:nth-child(1) .toggle-option {
    border-radius: 3px 0 0 3px;
  }

  &:nth-last-child(1) .toggle-option {
    border-radius: 0 3px 3px 0;
  }

  &:hover input ~ .toggle-option {
    background: ${({ theme }) => theme.colors.oathBlue};
    color: ${({ theme }) => theme.colors.white};
  }

  input:checked ~ .toggle-option {
    background: ${({ theme }) => theme.colors.inkBlue};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const SelectFieldContainer = styled.div`
  .input {
    height: 18px;
    text-align: left;
  }
  .active .input {
    border-bottom: none !important;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const SelectOptionsContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.oathBlue}`};
  border-top: none;
  border-radius: 0px 0px 5px 5px;
  max-height: 250px;
  overflow: scroll;
  padding-top: 1px;
  position: absolute;
  width: -webkit-fill-available;
  text-align: left;
  z-index: 1;
  .select-option {
    cursor: pointer;
    margin: 0 12px 8px;
    &:hover {
      color: ${({ theme }) => theme.colors.electricBlue};
    }
  }
`;
