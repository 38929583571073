import axios from 'axios';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getFundingGapAPI = async () => {
  const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.FUNDING_GAP}`;
  try {
    const response = await axios.get(url, {
      headers: { ...headers, 'X-API-Key': process.env.REACT_APP_API_KEY },
    });
    return Promise.resolve(response);
  } catch (error: any) {
    return Promise.reject(error);
  }
};
