import { FC } from 'react';
import theme from 'config/theme';
import { Link } from 'components/Link';
import Spacing from 'components/Spacing';
import { Wrapper, Title, ButtonContainer, Description } from './styled';

interface Props {
  text: string;
  linkText?: string;
  backgroundColor?: string;
  amount?: number;
  disabled?: boolean;
  inverted?: boolean;
  onPress: () => void;
  onLinkPress?: () => void;
  withShadow?: boolean;
  withRecipients?: boolean;
  textColor?: string;
  maxWidth?: number;
  totalRecipients?: number;
}

const Button: FC<Props> = ({
  text,
  linkText,
  backgroundColor,
  amount,
  onPress,
  onLinkPress,
  disabled = false,
  withRecipients,
  withShadow,
  inverted,
  textColor,
  maxWidth,
  totalRecipients = 0,
}) => (
  <div style={{ display: 'contents' }}>
    <Wrapper
      $disabled={disabled}
      onClick={onPress}
      $withRecipients={withRecipients}
      whileTap={disabled ? { scale: 1 } : { scale: 0.9 }}
      $withShadow={withShadow}
      $inverted={inverted}
      $backgroundColor={backgroundColor}
      $maxWidth={maxWidth}
    >
      <ButtonContainer>
        <Title $inverted={inverted} $textColor={textColor}>
          {text}
        </Title>
        {withRecipients && totalRecipients > 0 && (
          <Description $inverted={inverted} $textColor={textColor}>
            {amount === 0 && 'Select at least one recipient'}
            {amount !== 0 && totalRecipients > 1 && `${amount} selected`}
          </Description>
        )}
      </ButtonContainer>
    </Wrapper>
    {linkText && (
      <>
        <Spacing $size={12} />
        <Link $color={theme.colors.inkBlue} onClick={onLinkPress} $textAlign="center">
          {linkText}
        </Link>
      </>
    )}
  </div>
);

export default Button;
