import { createActions } from 'reduxsauce';

export interface QuestionsState {
  data: QuestionsData[];
  isLoading: boolean;
  error: string;
}

export type QuestionsData = {
  id: string;
  component_type: string;
  is_multiple_choice: boolean;
  key_question: string;
  optional_detail: boolean;
  options: IOption[];
  order: number;
  question_type: string;
  subtitle: string;
  title: string;
};

export type IOption = {
  key: string;
  label: string;
};

export interface QuestionsTypes {
  QUESTIONS: 'QUESTIONS';
  QUESTIONS_START: 'QUESTIONS_START';
  QUESTIONS_SUCCESS: 'QUESTIONS_SUCCESS';
  QUESTIONS_ERROR: 'QUESTIONS_ERROR';
  QUESTION_ANSWER_SUCCESS: 'QUESTION_ANSWER_SUCCESS';
  QUESTION_ANSWER_ERROR: 'QUESTION_ANSWER_ERROR';
  QUESTIONS_CLEAN_UP: 'QUESTIONS_CLEAN_UP';
}

const { Types, Creators } = createActions<QuestionsTypes>({
  questions: ['data'],
  questionsStart: null,
  questionsSuccess: ['data'],
  questionsError: ['error'],
  questionAnswerSuccess: ['data'],
  questionAnswerError: ['error'],
  questionsCleanUp: null,
});

export { Types };

export default Creators;
