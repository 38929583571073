import { FC, useEffect, useState } from 'react';
import Slider from 'rc-slider';
import theme from 'config/theme';
import { Row } from 'components/Row';
import Column from 'components/Column';
import Spacing from 'components/Spacing';
import { UtilityText } from 'components/Typography';
import { HvpTag } from 'screens/Summary/HvpTag';
import {
  CandidateDetailsColumn,
  AlignTop,
  NoImpactScore,
  GhostLabel,
  GhostContent,
} from './styled';
import { IRecipientDetailed } from 'store/recipients/types';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { getRecipientDetailedById } from 'store/recipients/actions';
import { getRecipientDetailedByIdData } from 'selectors/recipients';

const getFinancialsDate = dateStr => {
  const apiDate = new Date(dateStr);
  const todaysDate = new Date();

  // add two days to apiDate
  apiDate.setDate(apiDate.getDate() + 2);
  const date = apiDate < todaysDate ? apiDate : todaysDate;
  return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
};

interface Props {
  recipientId: number;
}

const sliderStyles = {
  rail: {
    height: 12,
    borderRadius: 12,
  },
  track: {
    background: theme.colors.darkGreen,
    height: 12,
  },
  handle: {
    display: 'none',
  },
};

export const Details: FC<Props> = ({ recipientId }) => {
  const dispatch = useAppDispatch();
  const recipientDetailedById = useAppSelector(getRecipientDetailedByIdData);
  const item: IRecipientDetailed = recipientDetailedById[recipientId];
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!recipientDetailedById[recipientId]) {
      dispatch(getRecipientDetailedById(recipientId)).catch(() => {
        setError(true);
      });
    }
  }, []);

  if (error) {
    return (
      <NoImpactScore>
        <UtilityText>Details for this recipient are not available.</UtilityText>
      </NoImpactScore>
    );
  }

  if (!item) {
    return (
      <CandidateDetailsColumn $noWrap $alignContent="space-between">
        <div className="ghost-overlay"></div>
        <GhostLabel />
        <GhostContent />
        <GhostLabel />
        <GhostContent />
        <GhostLabel />
        <GhostContent />
      </CandidateDetailsColumn>
    );
  }

  const amount_raised = Math.round(
    (parseInt(item.raised) / parseInt(item.fundraising_target)) * 100
  );

  return (
    <>
      {!item.impact_score ? (
        <NoImpactScore>
          <UtilityText>
            Impact Score not available for this recipient. Oath's Impact Score does not currently
            score Organizations or hyper local races where sufficient data is not available.
          </UtilityText>
        </NoImpactScore>
      ) : (
        <CandidateDetailsColumn $noWrap $alignContent="space-between">
          {item.ratings?.length > 0 && item.ratings[4] && (
            <>
              <UtilityText>Competitiveness:</UtilityText>
              <HvpTag text={item.ratings[4]} />
            </>
          )}
          {item.election?.election_display_info && (
            <>
              <UtilityText>Seat Status:</UtilityText>
              <HvpTag text={item.election.election_display_info} />
            </>
          )}
          {!isNaN(amount_raised) && (
            <>
              <AlignTop>
                <UtilityText>Amount Raised:</UtilityText>
              </AlignTop>
              <AlignTop>
                <Column>
                  <Row>
                    <UtilityText>{amount_raised > 200 ? '+200' : amount_raised}%</UtilityText>
                    <Spacing $isVertical $size={8} />
                    <Slider
                      min={0}
                      max={100}
                      value={amount_raised}
                      styles={sliderStyles}
                      className="slider-card"
                    />
                  </Row>
                  {item?.financials_updated_at && (
                    <UtilityText $color={theme.colors.gray}>
                      Financials as of {getFinancialsDate(item.financials_updated_at)}
                    </UtilityText>
                  )}
                </Column>
              </AlignTop>
            </>
          )}
          {/* {item?.campaign_website && (
                    <UtilityText>
                      Learn more on {item?.recipient_name}'s{' '}
                      <Link href={item.campaign_website} target="_blank" $fontSize="inherit">
                        Campaign Website
                      </Link>
                    </UtilityText>
                  )} */}
        </CandidateDetailsColumn>
      )}
    </>
  );
};
