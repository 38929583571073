import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/create-store';
import { RecommendationsData, RecommendationsState } from 'store/recommendations/types';

export const getRecommendationsState = (state: RootState): RecommendationsState =>
  state.recommendations;

export const getRecommendationsData = createSelector(
  [getRecommendationsState],
  (state: RecommendationsState): RecommendationsData[] => state?.data
);
