const DATADOG_LOGS_INIT_BASE = {
  clientToken: 'pub656a000b47d109fa06242c68cd195799',
  site: 'datadoghq.com',
  forwardErrorsToLogs: false,
  service: 'browser-oath-site',
  sessionSampleRate: 100,
};

const PROD_CONFIG = {
  MODE: 'PRODUCTION',
  SITE_URL: 'https://app.oath.vote',
  ENGAGE_URL: 'https://contribute.oath.vote/checkout',
  DONATE_URL: 'https://donate.oath.vote/page/',
  CLOUD_STORAGE: {
    URL: 'https://storage.googleapis.com/oath_1',
    TAGS: 'tags',
  },
  AUTH: {
    DOMAIN: 'login-oath-vote.us.auth0.com',
    CLIENT_ID: 'OCSh8SNYKiOjoWubWoZ5FR7khnaM87k5',
  },
  DEMOCRACY_ENGINE: {
    CHECKOUT_URL: 'https://donate.oath.vote/page/secure/checkout',
    DONATE_API_BASE_URL: 'https://donate.democracyengine.com',
  },
  API: {
    BASE_URL: 'https://api-prod-0fto.onrender.com',
  },
  DATADOG_LOGS_INIT: {
    ...DATADOG_LOGS_INIT_BASE,
    env: 'prod',
  },
};

const STAG_CONFIG = {
  MODE: 'STAGING',
  SITE_URL: 'https://test.oath.vote',
  // SITE_URL: 'https://oath.vote.local:3000',
  ENGAGE_URL: 'https://test.engageraise.com/checkout',
  DONATE_URL: 'https://donate.oath.vote/page/',
  CLOUD_STORAGE: {
    URL: 'https://storage.googleapis.com/oath_1',
    TAGS: 'test_tags',
  },
  AUTH: {
    DOMAIN: 'oath-users.us.auth0.com',
    CLIENT_ID: 'VJxmlgnmykt2s3SDcJsIdtneGLBZXpAg',
  },
  DEMOCRACY_ENGINE: {
    CHECKOUT_URL: 'https://donate.oath.vote/page/secure/checkout',
    DONATE_API_BASE_URL: 'https://clientstage-donate.democracyengine.com',
  },
  API: {
    BASE_URL: 'https://api-staging-9sw6.onrender.com',
  },
  DATADOG_LOGS_INIT: {
    ...DATADOG_LOGS_INIT_BASE,
    env: 'staging',
  },
};

export const ENV_CONFIG = () => {
  if (window.location.origin === 'https://app.oath.vote') return PROD_CONFIG;
  else return STAG_CONFIG;
};
