import { Row } from 'components/Row';
import Column from 'components/Column';
import styled from 'styled-components/macro';
import { Image } from 'components/Image';
import { Body, BodySmall, UtilityText } from 'components/Typography';

export const MainContainer = styled(Row)`
  height: 100%;
  clip-path: margin-box;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const Content = styled.div`
  background: ${({ theme }) => theme.colors.softGray};
  height: auto;
  width: 100%;
  z-index: 1;
  padding-bottom: 32px;
`;

export const CardContent = styled(Row)`
  padding: 8px;
`;

export const InlineRow = styled.div`
  display: -webkit-inline-box;
  padding: 4px 0;
`;

export const StyledImage = styled(Image)<{ $big?: boolean }>`
  width: ${({ $big }) => ($big ? '75px' : '55px')};
  height: ${({ $big }) => ($big ? '75px' : '55px')};
  border-radius: 3px;
`;

export const TextDetails = styled(Column)<{ $align?: string }>`
  padding: 0 8px;
  ${({ $align }) => $align && `align-content: ${$align}`};
`;

export const WhiteBox = styled.div`
  background: ${({ theme }) => theme.colors.white};
  height: 100px;
  width: -webkit-fill-available;
  z-index: -1;
  position: absolute;

  @media (max-width: 1300px) {
    position: inherit;
  }
`;

export const CardsContainer = styled.div`
  padding: 0 160px 32px 160px;

  @media (max-width: 1300px) {
    padding: 0 15px 32px 15px;
  }
`;

export const InnerGradientBox = styled(Column)`
  text-align: center;
  justify-items: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 15px 20px;
`;

export const Title = styled(Body)<{ $big?: boolean }>`
  font-size: ${({ $big }) => ($big ? '20px' : '15px')};
  padding-bottom: 4px;
`;

export const Description = styled(UtilityText)<{
  $isLight?: boolean;
  $isSmall?: boolean;
  $hiddenOnMobile?: boolean;
}>`
  color: ${({ theme, $isLight }) => ($isLight ? theme.colors.jumbo : theme.colors.inkBlue)};
  font-size: ${({ $isSmall }) => ($isSmall ? '11px' : '13px')};
  ${({ $hiddenOnMobile }) =>
    $hiddenOnMobile &&
    `
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @media (max-width: 500px) {
        display: none;
      }
    `};
`;

export const TagContainer = styled.div`
  padding: 2px 6px;
  background: ${({ theme, color }) => color || theme.colors.platinum};
  border-radius: 4px;
  align-self: center;
  width: fit-content;
`;

export const LabelContainer = styled.div`
  min-width: 100px;
`;

export const CircleIcon = styled.div`
  background: ${({ theme }) => theme.colors.platinum};
  border-radius: 10px;
  height: 16px;
  width: 16px;
  text-align: center;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TagValue = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ButtonContainer = styled.div`
  position: sticky;
  bottom: 16px;
  padding: 16px;
`;
