import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const userStart = state => ({
  ...state,
  isLoading: true,
  error: null,
  datesUpdated: false,
});

export const userSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const userError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const userProfileSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const userProfileError = (state, { error }) => ({
  ...state,
  error: {
    ...state.error,
    profileError: error,
  },
  isLoading: false,
});

export const userCleanUp = () => INITIAL_STATE;

export const HANDLERS = {
  [Types.USER_START]: userStart,
  [Types.USER_SUCCESS]: userSuccess,
  [Types.USER_ERROR]: userError,
  [Types.USER_PROFILE_SUCCESS]: userProfileSuccess,
  [Types.USER_PROFILE_ERROR]: userProfileError,
  [Types.USER_CLEAN_UP]: userCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
