import { createActions } from 'reduxsauce';

export interface PaymentsState {
  data: PaymentsStateData;
  isLoading: boolean;
  error: string;
  saveNewPaymentIsLoading: boolean;
  saveNewPaymentError: string;
}

export interface PaymentsStateData {
  existingPayments: PaymentsData[];
  newPaymentsById: { [token: string]: PaymentsData };
}

export type PaymentsData = {
  id: string;
  last_4: string;
  payment_authorization_display_name: string;
  payment_authorization_expiration_date: string;
  payment_authorization_method: 'CreditCard' | 'ElectronicCheck';
  payment_authorization_token: string;
  created_at: string;
  updated_at: string;
};

export interface PaymentSaveRequestData {
  first_name: string;
  last_name: string;
  email: string;
  payment_authorization_display_name: string;
  payment_authorization_expiration_date: string;
  payment_authorization_method: string;
  payment_authorization_token: string;
  last_4: string;
}

export interface PaymentsTypes {
  PAYMENTS: 'PAYMENTS';
  PAYMENTS_START: 'PAYMENTS_START';
  PAYMENTS_SUCCESS: 'PAYMENTS_SUCCESS';
  PAYMENTS_ERROR: 'PAYMENTS_ERROR';
  PAYMENTS_CLEAN_UP: 'PAYMENTS_CLEAN_UP';
  PAYMENTS_SAVE_SUCCESS: 'PAYMENTS_SAVE_SUCCESS';
  PAYMENTS_SAVE_START: 'PAYMENTS_SAVE_START';
  PAYMENTS_SAVE_ERROR: 'PAYMENTS_SAVE_ERROR';
}

const { Types, Creators } = createActions<PaymentsTypes>({
  payments: ['data'],
  paymentsStart: null,
  paymentsSaveStart: null,
  paymentsSuccess: ['data'],
  paymentsSaveSuccess: ['data'],
  paymentsError: ['error'],
  paymentsSaveError: ['error'],
  paymentsCleanUp: null,
});

export { Types };

export default Creators;
