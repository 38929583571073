import { createBrowserRouter, redirect } from 'react-router-dom';
import { HelpScreen } from 'screens/Help';
import { MainScreen } from 'screens/Main';
import { DonorScreen } from 'screens/Donor';
import { LoginScreen } from 'screens/Login';
import { StepsScreen } from 'screens/Steps';
import { ContactScreen } from 'screens/Contact';
import { OurTeamScreen } from 'screens/OurTeam';
import { ProfileScreen } from 'screens/Profile/Profile';
import { ProfileEditScreen } from 'screens/Profile/Edit';
import { ProfileScreen as ProfileRoot } from 'screens/Profile';
import { NotFoundScreen } from 'screens/NotFound';
import { ImpactScoreScreen } from 'screens/ImpactScore';
import { LandingScreen } from 'screens/Landing';
import { RecommendationsScreen } from 'screens/Recommendations';
import { SummaryScreen } from 'screens/Summary';
import { CheckoutScreen } from 'screens/Checkout';
import { SetScreen } from 'screens/Set';
import { MonthlyScreen } from 'screens/Monthly';
import { GalleryScreen } from 'screens/Gallery';
import { GetStartedScreen } from 'screens/GetStarted';
import { FormsScreen } from 'screens/Forms';
import { ImpactReportScreen } from 'screens/ImpactReport';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingScreen />,
    errorElement: <NotFoundScreen />,
  },
  {
    path: '/error',
    element: <NotFoundScreen />,
  },
  {
    path: '/impact_score',
    element: <ImpactScoreScreen />,
  },
  {
    path: '/login',
    element: <LoginScreen />,
  },
  {
    path: '/donor',
    element: <DonorScreen />,
  },
  {
    path: '/our_team',
    element: <OurTeamScreen />,
  },
  {
    path: '/help',
    element: <HelpScreen />,
  },
  {
    path: '/contact',
    element: <ContactScreen />,
  },
  {
    path: '/donate/',
    element: <MainScreen />,
    errorElement: <NotFoundScreen />,
  },
  {
    path: '/profile',
    element: <ProfileRoot />,
    children: [
      {
        path: '',
        element: <ProfileScreen />,
      },
      {
        path: 'edit',
        element: <ProfileEditScreen />,
      },
    ],
  },
  {
    path: '/questions',
    element: <StepsScreen />,
  },
  {
    path: '/recommendations',
    element: <RecommendationsScreen />,
  },
  {
    path: '/summary',
    element: <SummaryScreen />,
  },
  {
    path: '/set',
    element: <SetScreen />,
  },
  {
    path: '/monthly',
    element: <MonthlyScreen />,
  },
  {
    path: '/gallery',
    element: <GalleryScreen />,
  },
  {
    path: '/getstarted',
    element: <GetStartedScreen />,
  },
  {
    path: '/impact_report',
    element: <ImpactReportScreen />,
  },
  {
    path: '/forms/:form',
    element: <FormsScreen />,
  },
  {
    path: '/checkout/*',
    element: <CheckoutScreen />,
    children: [
      {
        path: '',
      },
      {
        path: 'confirmation',
      },
    ],
  },
  {
    path: '/landing',
    loader: () => redirect('/'),
  },
]);
