import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'config/theme';
import { ENV_CONFIG } from 'config/environment';
import FontStyles from 'config/theme/fontStyles';
import store from 'store/create-store';
import { router } from 'navigation/router';
import reportWebVitals from './reportWebVitals';
import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init(ENV_CONFIG().DATADOG_LOGS_INIT);

import 'assets/scss/site.scss';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={ENV_CONFIG().AUTH.DOMAIN}
      cacheLocation="localstorage"
      clientId={ENV_CONFIG().AUTH.CLIENT_ID}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizationParams={{
        redirect_uri: `${ENV_CONFIG().SITE_URL}/profile`,
        audience: `https://${ENV_CONFIG().AUTH.DOMAIN}/api/v2/`,
        scope: 'read:current_user update:current_user_metadata',
      }}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <FontStyles />
          <RouterProvider router={router} />
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
