import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import Column from 'components/Column';
import { Row } from 'components/Row';
import { Image } from 'components/Image';
import { Body } from 'components/Typography';

export const Container = styled.div`
  background: ${({ theme, color }) => color || theme.colors.catskillWhite};
`;

export const BarContainer = styled.div<{ $isOpen?: boolean }>`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  z-index: 90;
  ${({ $isOpen }) =>
    $isOpen &&
    `
      position: fixed;
      height: 100%;
    `}
`;

export const Content = styled(Column)<{ $isLanding?: boolean }>`
  padding: 16px;
  ${({ $isLanding }) => $isLanding && `justify-items: center; padding: 4px 10px;`}
`;

export const TopContent = styled(Content)`
  display: none;

  @media (max-width: 800px) {
    background: ${({ theme }) =>
      `linear-gradient(179.87deg, ${theme.colors.oathBlue} 9.51%, ${theme.colors.inkBlue} 106.69%)`};
    display: grid;
  }
`;

export const StyledImage = styled(Image)`
  height: 40px;
  cursor: pointer;
`;

export const RowHeader = styled(Row)`
  padding: 16px;

  @media (max-width: 800px) {
    padding: 0;
    padding-right: 16px;
  }
`;

export const RowLoginLargeOnly = styled(Row)`
  @media (max-width: 801px) {
    display: none;
  }
`;

export const RowLoginSmallOnly = styled(Row)`
  @media (min-width: 800px) {
    display: none;
  }
`;

export const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.alto};
  margin: 4px 0;
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const HeaderItemsContainer = styled.div`
  display: flex;
  gap: 40px;
  padding: 0 32px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const MotionButton = styled(motion.button)<{ $isFromSidebar?: boolean }>`
  background: ${({ theme }) => theme.colors.transparent};
  border: none;
  z-index: ${({ $isFromSidebar }) => ($isFromSidebar ? 3 : 1)};
  cursor: pointer;
  ${({ $isFromSidebar }) => $isFromSidebar && `display: none`};

  @media (max-width: 800px) {
    ${({ $isFromSidebar }) => $isFromSidebar && `display: block`};
  }
`;

export const MotionDiv = styled(motion.div)`
  position: fixed;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.inkBlue};
`;

export const InfoText = styled(Body)<{
  $isBold?: boolean;
  $isCentered?: boolean;
  onClick?: () => void;
}>`
  color: ${({ theme, color }) => color || theme.colors.inkBlue};
  ${({ theme, $isBold }) => ($isBold ? theme.fonts.semiBold : theme.fonts.regular)};
  line-height: 20px;
  text-align: center;
  ${({ $isCentered }) => $isCentered && 'align-self: center'};
  ${({ onClick }) => !!onClick && 'cursor: pointer'};
`;

export const HeaderName = styled(InfoText)`
  width: max-content;
  padding-right: 16px;
`;
