import { CSSProperties, FC, ReactNode, useState } from 'react';
import { Field, useField, useForm, FieldProps, FieldRenderProps } from 'react-final-form';
import theme from 'config/theme';
import { BodySmall } from 'components/Typography';
import Icon, { IconNames } from 'components/Icon';
import { SelectFieldContainer, SelectOptionsContainer } from './styled';
import { InputWithFloatingLabel } from 'components/Form/InputWithFloatingLabel';
import classNames from 'classnames';
import { Row } from 'components/Row';

const getToggleIconStyles = (showOptions: boolean) =>
  ({
    background: showOptions ? theme.colors.oathBlue : theme.colors.inkBlue,
    borderRadius: '30px',
    cursor: 'pointer',
    position: 'absolute',
    right: '7px',
    top: '8px',
  }) as CSSProperties;

const getGroupedOptions = (value: ValueType, options: Option[]) => {
  const activeOption = options.find(option => option.value === value);
  const inactiveOptions = options.filter(option => option.value !== value);
  return { activeOption, inactiveOptions };
};

type ValueType = string | number;

interface Option {
  label: ReactNode;
  value: ValueType;
}

interface Props extends FieldProps<HTMLInputElement, FieldRenderProps<any>> {
  label?: ReactNode;
  name: string;
  options: Option[];
}

export const SelectField: FC<Props> = ({
  label,
  name,
  options,
  validate,
  onChange,
  ...fieldProps
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const { change: changeForm } = useForm();
  const {
    input: { value },
    meta: { error, submitError, touched },
  } = useField(name, {
    subscription: { value: true, error: true, submitError: true, touched: true },
  });
  const { activeOption, inactiveOptions } = getGroupedOptions(value, options);

  const onSelectToggle = () => {
    setShowOptions(showOptions => !showOptions);
  };

  const getOnClickOption = (value: ValueType) => {
    return () => {
      if (onChange) onChange(value);
      changeForm(name, value);
      setShowOptions(false);
    };
  };

  return (
    <SelectFieldContainer>
      <Field component="select" name={name} hidden validate={validate} {...fieldProps}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>

      <InputWithFloatingLabel
        className={classNames({ active: showOptions, error: touched && (error || submitError) })}
        error={touched && (error || submitError)}
        label={label}
        value={activeOption?.value}
      >
        <Row onClick={onSelectToggle} $pressable={true} style={{ position: 'relative' }}>
          <BodySmall className="input" style={{ width: '100%' }}>
            {activeOption?.label || (!label && '--')}
          </BodySmall>
          <Icon
            name={showOptions ? IconNames.ChevronUp : IconNames.ChevronDown}
            color={theme.colors.white}
            size={30}
            style={getToggleIconStyles(showOptions)}
          />
        </Row>

        {showOptions && (
          <div>
            <SelectOptionsContainer>
              {inactiveOptions.map(({ label, value }) => (
                <div className="select-option" key={value} onClick={getOnClickOption(value)}>
                  <BodySmall $color="inherit">{label}</BodySmall>
                </div>
              ))}
            </SelectOptionsContainer>
          </div>
        )}
      </InputWithFloatingLabel>
    </SelectFieldContainer>
  );
};
