import Column from 'components/Column';
import { H2Text } from 'components/Typography';
import styled from 'styled-components/macro';

export const Container = styled(Column)<{ $lastContainer?: boolean }>`
  padding: ${({ $lastContainer }) => ($lastContainer ? '40px 40px 100px' : '40px')};
  text-align: center;
  max-width: 820px;
  margin: auto;
  justify-content: center;
  justify-items: center;

  @media (max-width: 800px) {
    padding: 32px 20px 20px;
    margin-bottom: 70px;
  }
`;

export const TopContainer = styled(Column)`
  padding: 32px 32px 57px;
  background: ${({ theme }) => theme.colors.softGray};

  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const EllipsesContainer = styled.div`
  margin: 20px 0;
  gap: 100px;
  display: flex;

  @media (max-width: 1000px) {
    gap: 30px;
  }
  @media (max-width: 800px) {
    display: grid;
  }
`;

export const JoinCommunityContainer = styled.div`
  margin-top: -100px;

  @media (max-width: 800px) {
    margin-top: -90px;
  }
`;

export const TextsContainer = styled.div`
  max-width: 850px;
`;

export const Title = styled(H2Text)`
  @media (max-width: 800px) {
    font-size: 30px;
    letter-spacing: -0.01em;
    line-height: 33px;
  }
`;
