import { createReducer } from 'reduxsauce';
import { Types } from './types';
import { INITIAL_STATE } from './initialState';

export const tagsStart = state => ({
  ...state,
  isLoading: true,
  error: null,
});

export const tagsSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

export const tagsError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
});

export const tagsFullListSuccess = (state, { data }) => ({
  ...state,
  fullListData: data,
  isLoading: false,
});

export const tagsFullListError = (state, { error }) => ({
  ...state,
  fullListError: error,
  isLoading: false,
});

export const tagsCleanUp = () => INITIAL_STATE;

export const tagsFinishLoading = state => ({
  ...state,
  isLoading: false,
});

export const HANDLERS = {
  [Types.TAGS_START]: tagsStart,
  [Types.TAGS_SUCCESS]: tagsSuccess,
  [Types.TAGS_ERROR]: tagsError,
  [Types.TAGS_FULL_LIST_SUCCESS]: tagsFullListSuccess,
  [Types.TAGS_FULL_LIST_ERROR]: tagsFullListError,
  [Types.TAGS_CLEAN_UP]: tagsCleanUp,
};

export default createReducer(INITIAL_STATE, HANDLERS);
