import Spacing from 'components/Spacing';
import { H4 } from 'components/Typography';
import { GradientBorderCard } from 'components/Cards/GradientBorderCard';
import { getPathWithRef } from 'helpers/utils';
import {
  InnerGradientBox,
  GetRecommendationsContainer,
  GetRecommendationsAbsolute,
  RegularText,
  RecommendationsButton,
  RecommendationsText,
} from './styled';

export const GetRecommendations = () => {
  return (
    <GetRecommendationsContainer>
      <GetRecommendationsAbsolute>
        <GradientBorderCard borderType="full" width="auto" borderSize={2}>
          <InnerGradientBox>
            <RecommendationsText $alignItems="flex-start">
              <H4>Not sure where to start?</H4>
              <Spacing $size={5} />
              <RegularText>
                Just 3 questions away from getting personalized donation recommendations
              </RegularText>
            </RecommendationsText>
            <RecommendationsButton href={getPathWithRef('/login')}>
              Get recommendations
            </RecommendationsButton>
          </InnerGradientBox>
        </GradientBorderCard>
      </GetRecommendationsAbsolute>
    </GetRecommendationsContainer>
  );
};
