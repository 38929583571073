import { createActions } from 'reduxsauce';

export interface RecipientsState {
  data: RecipientsData;
  isLoading: boolean;
  error: string;
  detailed: RecipientsDetailedData;
  detailedById: {
    data: IRecipientDetailed;
    isLoading: boolean;
    error: string;
  };
}

export type RecipientsData = {
  recipients: IRecipient[];
};

export type RecipientsDetailedData = {
  recipients: IRecipientDetailed[];
};

export type IRecipient = {
  de_api_id?: string;
  de_id: string;
  display_info: string;
  impact_score: number;
  recipient_id: number;
  external_id: number;
  recipient_name: string;
  state: string;
  contribution_limit?: string | null;
  employer_address?: boolean;
};

export type IRecipientDetailed = {
  campaign_website: string;
  coh: string;
  competitor?: {
    competitor_name: string;
    raised: string;
    coh: string;
    is_incumbent: boolean;
  };
  contribution_limit?: string | null;
  de_api_id?: string;
  de_id: string;
  display_info: string;
  election: {
    election_date: string;
    election_display_info: string;
    election_id: string;
    election_name: string;
    primary_election_date: string;
  };
  employer_address?: boolean;
  external_id: string;
  financials_updated_at: string;
  fundraising_target: string;
  impact_score: number;
  is_enabled: boolean;
  is_incumbent: boolean;
  presidential_2020: string;
  projected_cost: string;
  pvi: string;
  raised: string;
  ratings: string[];
  recipient_id: string;
  recipient_name: string;
  state: string;
};

export type getRecipientProps = {
  amount: number;
  tags?: string[];
  search_str?: string;
  query_str?: string;
};

export interface RecipientsTypes {
  RECIPIENTS: 'RECIPIENTS';
  RECIPIENTS_START: 'RECIPIENTS_START';
  RECIPIENTS_SUCCESS: 'RECIPIENTS_SUCCESS';
  RECIPIENTS_ERROR: 'RECIPIENTS_ERROR';
  RECIPIENTS_DETAILED_SUCCESS: 'RECIPIENTS_DETAILED_SUCCESS';
  RECIPIENTS_DETAILED_ERROR: 'RECIPIENTS_DETAILED_ERROR';
  RECIPIENTS_CLEAN_UP: 'RECIPIENTS_CLEAN_UP';
  RECIPIENT_DETAILED_BY_ID_START: 'RECIPIENT_DETAILED_BY_ID_START';
  RECIPIENT_DETAILED_BY_ID_SUCCESS: 'RECIPIENT_DETAILED_BY_ID_SUCCESS';
  RECIPIENT_DETAILED_BY_ID_ERROR: 'RECIPIENT_DETAILED_BY_ID_ERROR';
}

const { Types, Creators } = createActions<RecipientsTypes>({
  recipients: ['data'],
  recipientsStart: null,
  recipientsSuccess: ['data'],
  recipientsError: ['error'],
  recipientsDetailedSuccess: ['data'],
  recipientsDetailedError: ['error'],
  recipientsCleanUp: null,
  recipientDetailedByIdStart: null,
  recipientDetailedByIdSuccess: ['data'],
  recipientDetailedByIdError: ['error'],
});

export { Types };

export default Creators;
