import axios from 'axios';
import { Dispatch } from 'redux';
import SUBSCRIPTIONS_TYPES from './types';
import { ENV_CONFIG } from 'config/environment';
import API_ROUTES from 'services/apiRoutes';
import { getSiteAccessToken } from 'services/storage';
import { datadogLogs } from '@datadog/browser-logs';
import { getMessageFromAxiosError } from 'helpers/errors';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

export const getSubscriptions =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(SUBSCRIPTIONS_TYPES.subscriptionsStart());

    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.GET_SUBSCRIPTIONS}`;

    try {
      const response = await axios.get(url, {
        headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
      });
      dispatch(SUBSCRIPTIONS_TYPES.subscriptionsSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      dispatch(SUBSCRIPTIONS_TYPES.subscriptionsError(error.message));
      datadogLogs.logger.warn(
        'Error occured fetching subscriptions on profile page',
        {},
        new Error(getMessageFromAxiosError(error))
      );
      return Promise.reject(error);
    }
  };

export const cancelSubscription =
  (deGid: string) =>
  async (dispatch: Dispatch): Promise<any> => {
    dispatch(SUBSCRIPTIONS_TYPES.subscriptionsStart());
    const url = `${ENV_CONFIG().API.BASE_URL}${API_ROUTES.CANCEL_SUBSCRIPTION(deGid)}`;

    try {
      const response = await axios.patch(
        url,
        {},
        {
          headers: { ...headers, Authorization: `Bearer ${getSiteAccessToken()}` },
        }
      );
      dispatch(SUBSCRIPTIONS_TYPES.subscriptionsSuccess(response.data));
      return Promise.resolve(response);
    } catch (error: any) {
      datadogLogs.logger.warn(
        'Error occured canceling subscription on profile page',
        {},
        new Error(getMessageFromAxiosError(error))
      );
      return Promise.reject(error);
    }
  };
