import { createActions } from 'reduxsauce';
import { ProcessingRedirectOptions } from './constants';

export interface TagsState {
  data: TagsData;
  fullListData: TagsData;
  isLoading: boolean;
  error: string;
}

export type TagsData = {
  tags: ITag[];
};

export type TagPageType = 'LIST' | 'COMPACT' | 'MEDIA';

export type ITag = {
  id: number;
  donation_amounts: number[];
  value: string;
  desc: string;
  is_top_tag: boolean;
  page_misc: {
    page_background: string;
    page_color_text: string;
  };
  tag_cta: string;
  is_enabled: boolean;
  impact_score_visibility: boolean;
  query_str: string;
  page_type: TagPageType;
  processing_redirect: ProcessingRedirect;
  tag_type: string;
  tag_rating: number;
  client_id: number;
  de_page: string;
  tag_category: string;
  display_fundraising_bar: string;
  display_community_tracker: string;
  fundraising_goal: string;
  learn_more_link: string;
  raised: string;
  set_query_string: string;
  whitelabeled: boolean;
  paused_misc: object;
  status: string;
  owner: string;
};

export type ProcessingRedirect =
  | ProcessingRedirectOptions.DE_GENERAL
  | ProcessingRedirectOptions.EN_GENERAL
  | ProcessingRedirectOptions.DE_OATH;

export type getTagsProps = {
  urlPartner?: string;
};

export interface TagsTypes {
  TAGS: 'TAGS';
  TAGS_START: 'TAGS_START';
  TAGS_SUCCESS: 'TAGS_SUCCESS';
  TAGS_ERROR: 'TAGS_ERROR';
  TAGS_FULL_LIST_SUCCESS: 'TAGS_FULL_LIST_SUCCESS';
  TAGS_FULL_LIST_ERROR: 'TAGS_FULL_LIST_ERROR';
  TAGS_CLEAN_UP: 'TAGS_CLEAN_UP';
}

const { Types, Creators } = createActions<TagsTypes>({
  tags: ['data'],
  tagsStart: null,
  tagsSuccess: ['data'],
  tagsError: ['error'],
  tagsFullListSuccess: ['data'],
  tagsFullListError: ['error'],
  tagsCleanUp: null,
});

export { Types };

export default Creators;
