import { createActions } from 'reduxsauce';
import { PaymentMethod } from 'store/pay/types';

export interface DEState {
  data: DEStateData;
  isLoading: boolean;
  error: string;
}

export interface DEStateData {
  deTokensById: { [token: string]: DETokenResponseData };
}

export type DETokenResponseData = {
  ach_account_number?: string;
  ach_account_type?: string;
  ach_routing_number?: string;
  asynchronous_payment_instructions?: string;
  authcapture_request: boolean;
  authorization_completeness: string;
  authorization_deferred: boolean;
  authtest_request: boolean;
  aux_data: {
    request: {
      server_name: string;
      host: string;
      user_agent: string;
    };
  };
  avs_code?: string;
  cc_brand: string;
  cc_expiration: string;
  cc_first_six: string;
  cc_last_four: string;
  collection_of_pledge_donation_gid?: string;
  combine_line_items?: string;
  compliance_confirmation?: string;
  compliance_employer?: string;
  compliance_employer_address?: string;
  compliance_employer_address1?: string;
  compliance_employer_address2?: string;
  compliance_employer_city?: string;
  compliance_employer_country?: string;
  compliance_employer_state?: string;
  compliance_employer_zip?: string;
  compliance_occupation?: string;
  compliance_signature_confirmation?: string;
  created_at?: string;
  donor_address1: string;
  donor_address2?: string;
  donor_city: string;
  donor_country_code?: string;
  donor_email: string;
  donor_first_name: string;
  donor_full_name: string;
  donor_is_an_organization: boolean;
  donor_last_name: string;
  donor_organization_name?: string;
  donor_phone?: string;
  donor_sortable_name: string;
  donor_state: string;
  donor_zip: string;
  email_opt_in?: string;
  fee_tier?: string;
  gid: string;
  ip_address: string;
  is_organizational_contribution: boolean;
  is_pledge_collection_donation: boolean;
  is_pledge_donation: boolean;
  line_item_grouping: string;
  line_item_grouping_description: string;
  message?: string;
  payment_method: string;
  pledge_collection_donation_gids?: string;
  pledge_status: string;
  ref_code?: string;
  remote_donation_id?: string;
  remote_donor_id?: string;
  remote_donor_organization_id?: string;
  remote_subscription_first?: string;
  remote_subscription_id?: string;
  sharing_token: string;
  simulate_transactions?: string;
  simulate_transactions_as?: string;
  soft_descriptor?: string;
  source_code?: string;
  store_credit_deposit_amount?: string;
  store_credit_deposit_token?: string;
  store_credit_withdrawal_token?: string;
  subscription_create_data?: string;
  subscription_reference_date?: string;
  subscription_request?: string;
  token: string;
  token_request: boolean;
  uri: string;
  line_items: object[];
};

export interface DETokenRequestData {
  donation: {
    gid: string;
    donor_first_name: string;
    donor_last_name: string;
    donor_address1: string;
    donor_city: string;
    donor_state: string;
    donor_zip: string;
    donor_country_code: string;
    donor_email: string;
    payment_method: PaymentMethod;
    cc_number?: string;
    cc_month?: string;
    cc_year?: string;
    cc_verification_value?: string;
    ach_account_type?: string;
    ach_routing_number?: string;
    ach_account_number?: string;
    cc_first_name: string;
    cc_last_name: string;
    authtest_request: boolean;
    line_items?: object[] | null;
    token_request: boolean;
  };
}

export interface DETypes {
  CREATE_TOKEN: 'CREATE_TOKEN';
  CREATE_TOKEN_START: 'CREATE_TOKEN_START';
  CREATE_TOKEN_SUCCESS: 'CREATE_TOKEN_SUCCESS';
  CREATE_TOKEN_ERROR: 'CREATE_TOKEN_ERROR';
  CREATE_TOKEN_CLEAN_UP: 'CREATE_TOKEN_CLEAN_UP';
}

const { Types, Creators } = createActions<DETypes>({
  createToken: ['data'],
  createTokenStart: null,
  createTokenSuccess: ['data'],
  createTokenError: ['error'],
  createTokenCleanUp: null,
});

export { Types };

export default Creators;
