import { useEffect, useState } from 'react';
import { Checkbox, RetiredCheckboxRow, SelectContainer, SelectLabel } from './styled';
import Icon, { IconNames } from 'components/Icon';
import theme from 'config/theme';
import { TextField } from 'components/FinalForm/TextField';
import { useField } from 'react-final-form';
import { CountrySelectField, StateSelectField } from 'components/FinalForm/AddressSelectFields';

const INPUT_MAX_LENGTH = 100;

export const EmploymentDetails = ({ change }) => {
  const [isChecked, setIsChecked] = useState(false);
  const {
    input: { value: country },
  } = useField('employer_country', { subscription: { value: true } });

  useEffect(() => {
    if (isChecked) {
      change('employer', '');
      change('occupation', '');
    }
  }, [isChecked]);

  const onCheckboxClick = () => {
    setIsChecked(isChecked => !isChecked);
  };

  return (
    <>
      <TextField name="employer" label="Employer" maxLength={INPUT_MAX_LENGTH} />
      <TextField name="occupation" label="Occupation" maxLength={INPUT_MAX_LENGTH} />
      <RetiredCheckboxRow role="button" onClick={onCheckboxClick}>
        <Checkbox $isChecked={isChecked} $justifyContent="space-around" $alignItems="center">
          {isChecked && <Icon name={IconNames.Checkmark} color={theme.colors.white} size={10} />}
        </Checkbox>
        <div>I'm retired or currently unemployed</div>
      </RetiredCheckboxRow>
      <div></div>
      {!isChecked && (
        <>
          <TextField
            name="employer_address1"
            label="Employer Address 1"
            maxLength={INPUT_MAX_LENGTH}
          />
          <TextField
            name="employer_address2"
            label="Employer Address 2"
            maxLength={INPUT_MAX_LENGTH}
          />
          <TextField name="employer_city" label="Employer City" maxLength={INPUT_MAX_LENGTH} />
          <SelectContainer>
            <SelectLabel>Employer State</SelectLabel>
            <StateSelectField
              country={country}
              name="employer_state"
              maxLength={INPUT_MAX_LENGTH}
            />
          </SelectContainer>
          <TextField name="employer_zipcode" label="Employer Zip" maxLength={INPUT_MAX_LENGTH} />
          <SelectContainer>
            <SelectLabel>Employer Country</SelectLabel>
            <CountrySelectField name="employer_country" maxLength={INPUT_MAX_LENGTH} />
          </SelectContainer>
        </>
      )}
    </>
  );
};
